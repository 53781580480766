import React, { Component } from 'react';
import { Header, Icon, List } from 'semantic-ui-react';

class StatusComponent extends Component {
    renderStatus=()=>{
        const { status } = this.props.order;
        if(status && status.length>0){
            return status.map( (i, index)=>{
                return <List.Item key={index+i.date}>
                    <List.Icon name="edit" size='large' verticalAlign='middle' ></List.Icon>
                    <List.Content>
                        <List.Header as='a'>{i.status}</List.Header>
                        <List.Description as='a'>{i.date}</List.Description>
                    </List.Content>
                </List.Item>
            })
        }
    }
    render(){
        console.log(this.props.order.status)
        return(
            <div>
                <Header as="h1"><Icon name="tags"/> <Header.Content> Estatus <Header.Subheader>Consulte el estatus de esta Orden de pedido</Header.Subheader> </Header.Content></Header>
                <List>
                    {this.renderStatus()}
                </List>
            </div>
        )
    }
}

export default StatusComponent