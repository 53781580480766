import { getApi } from '../../utils/Constants';

const api = getApi();
const apiModule = 'prepaidMant/VinFilter/';
const token = JSON.stringify(localStorage.getItem('token'));

async function getAvailableVersion ( body ) {
    return await fetch(api+apiModule+'getAvailableVersion', {
        method:'POST',
        headers:{
            'Aceppt':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            transmission: body.transmission,
            modelLine: body.modelLine
        })
    }).then( (variables) => variables.json() )
    .then( (response)=>{
        return response;
    }).catch( (error)=>{
        return error;
    } )
}

async function validationVersion( body ){
    return await fetch(api+apiModule+'validationVersion', {
        method:'POST',
        headers:{
            'Aceppt':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            vin: body.vin,
            article: body.article
        })
    }).then( (variables) => variables.json() )
    .then( (response)=>{
        return response;
    }).catch( (error)=>{
        return error;
    } )
}

async function create( body ){
    return await fetch( api + 'vins/create', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            vin: body.vin,
            article: body.article,
            year: body.year
        })
    }).then( response => response.json() )
    .then( result => {
        return result
    }).catch( error => {
        return error
    } )
}

async function read( body ){
    return await fetch( api + 'vins/read', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            vin: body.vin,
            article: body.article,
            year: body.year
        })
    }).then( response => response.json() )
    .then( result => {
        return result
    }).catch( error => {
        return error
    } )
}

async function update( body ){
    return await fetch( api + 'vins/update', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            _id: body._id,
            vin: body.vin,
            article: body.article,
            year: body.year
        })
    }).then( response => response.json() )
    .then( result => {
        return result
    }).catch( error => {
        return error
    } )
}

async function delet( body ){
    return await fetch( api + 'vins/delet', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            _id: body._id,
            vin: body.vin,
            article: body.article,
            year: body.year
        })
    }).then( response => response.json() )
    .then( result => {
        return result
    }).catch( error => {
        return error
    } )
}

export {
    create,
    read,
    update,
    delet,
    getAvailableVersion,
    validationVersion
}