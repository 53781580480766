import React, { Component } from 'react';
import { Header, Form, Table, Button, Icon, Modal, Grid, Segment, Container } from 'semantic-ui-react';

import { getApi } from '../../../../utils/Constants';

const token = localStorage.getItem('token');

export default class VariablesScreen extends Component {
    constructor(props){
        super(props)
        this.state = { 
            variables:[], 
            openModal:false, 
            openEditModal:false, 
            confirmModalOpen:false,
            name:'', 
            description:'', 
            value:0, load:false, 
            editId:'', ditName:'', 
            editDescription:'', 
            editValue:0 }
    }

    handleChange=(e, {name, value})=>{
        this.setState({ [name]: value}, ()=>{
            console.log(this.state)
        })
    }

    componentDidMount(){
        this.getVariable()
    }

    getVariable=()=>{
        
        fetch(getApi()+'prepaidMant/variables/getVariable', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+ token
            },
            body: JSON.stringify({
                active:true
            })
        }).then( (res)=> res.json() )
        .then( (resJson)=>{
            this.setState({variables:resJson, load:true})
        } ).catch( (error)=>{
            console.log(error)
        } )
    }

    createVariable=()=>{
        const { name, value, description }= this.state;
        fetch(getApi()+'prepaidMant/variables/create', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+ token
            },
            body: JSON.stringify({
                name:name,
                description:description,
                value:value
            })
        }).then( (res)=> res.json() )
        .then( (res)=>{
            this.setState({ openModal:false })
            this.getVariable()
        } ).catch( (error)=>{
            console.log(error)
        })
    }

    updateVariable=()=>{
        const { editId, editName, editDescription, editValue } = this.state;
        fetch(getApi()+'prepaidMant/variables/updateVariable', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+ token
            },
            body: JSON.stringify({
                id:editId,
                name:editName,
                description:editDescription,
                value:editValue
            })
        }).then( (res)=> res.json() )
        .then( (res)=>{
            this.setState({ openEditModal:false })
            this.getVariable()
        } ).catch( (error)=>{
            console.log(error)
        })
    }

    deletVariable=()=>{
        const { editId } = this.state;
        fetch(getApi()+'prepaidMant/variables/deletVariable', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+ token
            },
            body: JSON.stringify({
                id:editId,
            })
        }).then( (res)=> res.json() )
        .then( (res)=>{
            this.getVariable()
        } ).catch( (error)=>{
            console.log(error)
        })
    }

    confirmModal = () => {
        const { confirmModalOpen } = this.state
        return <Modal open={confirmModalOpen}>
            <Modal.Header>
                Por favor confirme esta acción
            </Modal.Header>
            <Modal.Content>
                Realmente desea eliminar del grupo de variables?
                esto puede afectar el funcionamiento del Simulador de Mantenimientos.
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content='CANCELAR'
                    icon='times'
                    onClick={()=> this.setState({ confirmModalOpen:false})} 
                    size='tiny'
                    style={{borderRadius:25}}
                />
                <Button 
                    size='tiny'
                    style={{borderRadius:25, backgroundColor:'#bb162b', color:'#f6f6f6'}}
                    content='Eliminar'
                    icon="trash" 
                    onClick={()=> { this.deletVariable(); this.setState({ confirmModalOpen:false}) } }/>
            </Modal.Actions>
        </Modal>
    }

    renderItems=()=>{
        const { variables } = this.state;
        
        return variables.map( (i, index)=>{
            return (
                <Table.Row key={index}>
                    <Table.Cell>{i.name}</Table.Cell>
                    <Table.Cell>{i.description}</Table.Cell>
                    <Table.Cell>{i.value}</Table.Cell>
                    <Table.Cell>
                        <Button
                            size='tiny'
                            style={{borderRadius:25}}
                            content='Editar'
                            icon="edit" 
                            onClick={()=>{this.setState({ editId:i._id, editName:i.name, editDescription:i.description, editValue:i.value, openEditModal:true})}}/>
                        <Button 
                            size='tiny'
                            style={{borderRadius:25}}
                            content='Eliminar'
                            icon="trash" 
                            onClick={()=>{this.setState({ editId:i._id, confirmModalOpen:true }) }}/>
                    </Table.Cell>
                </Table.Row>
            )
        } )
    }

    renderModal=()=>{
        const { openModal } = this.state;
       
        return (
            <Modal open={openModal}>
                <Header icon="plus" content="Nueva variable"></Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} name="name" label="Variable" placeholder="Nombre de la variable"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} name="description" label="Descripción" placeholder="Descripción de la variable"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} name="value" type="number" label="(%) Valor" placeholder="Valor ejemplo: en el caso del IVA: 19"></Form.Input>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content='CANCELAR'
                        icon='times'
                        size='tiny'
                        style={{borderRadius:25}}
                        onClick={()=>{this.setState({openModal:false})}}/>
                    <Button 
                        size='tiny'
                        content='GUARDAR'
                        icon="save"
                        iconRight
                        style={{ borderRadius:25, backgroundColor:'#bb162b', color:'#f6f6f6' }}
                        onClick={()=>{this.createVariable()}} />
                </Modal.Actions>
            </Modal>
        )
    }

    rederEditModal=()=>{
        const { openEditModal, editName, editDescription, editValue } = this.state;
        return (
            <Modal open={openEditModal}>
                <Header icon="edit" content="Actualizar variable"></Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={editName} name="editName" label="Variable" placeholder="Nombre de la variable"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={editDescription} name="editDescription" label="Descripción" placeholder="Descripción de la variable"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={editValue} name="editValue" type="number" label="(%) Valor" placeholder="Valor ejemplo: en el caso del IVA: 19"></Form.Input>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        size='tiny'
                        icon="times"
                        content='CANCELAR'
                        style={{ borderRadius:25 }} 
                        onClick={()=>{this.setState({openEditModal:false})}}/>
                    <Button
                        size='tiny'
                        content='GUARDAR'
                        icon="save"
                        style={{ borderRadius:25, backgroundColor:"#bb162b", color:"#f6f6f6"}} 
                        onClick={()=>{this.updateVariable()}} />
                </Modal.Actions>
            </Modal>
        )
    }

    render(){
        return(
            <Grid style={{minHeight:'70vh', marginTop:'8vh', marginBottom:50 }}>
                <Container>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        VARIABLES
                        <span>
                            <Button 
                                content="AGREGAR"
                                icon="add"
                                onClick={()=>{this.setState({openModal:true})}} 
                                size="mini" 
                                style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                                floated='right'/>
                        </span>
                    </Header>

                    {this.renderModal()}
                    {this.rederEditModal()}
                
                    <Table size="small" selectable >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Variable</Table.HeaderCell>
                                <Table.HeaderCell>Descripción</Table.HeaderCell>
                                <Table.HeaderCell>% Porcentaje</Table.HeaderCell>
                                <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderItems()}
                        </Table.Body>
                    </Table>
                </Container>
                {this.confirmModal()}
            </Grid>
        )
    }
}