import { API } from '../constants';
const path = 'getUserByEmail';


export default async (body)=> {
    
    return await fetch(API + path, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ body.token
        },
        body: JSON.stringify({
            email:body.email
        })
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        
        return responseJSON
    } ).catch( (error)=> {
        console.log(error)
        return error
    } )
    
}