import React, { Component } from 'react';
import { Header, Modal, Button, Form, Table, Grid, Container, Checkbox } from 'semantic-ui-react';

import { create, getPrepaidTemplates, updatePrepaidTemplates } from '../../../../services/prepaidTemplate/prepaidService';

export default class PrepaidScreen extends Component {

    constructor(props){
        super(props)
        this.state = { 
            mto:0, 
            offPartsImp: 0, 
            offPartsCon: 0, 
            offPartsTotal: 0, 
            hours:0,
            openModalNew:false,
            openModalEdit:false,
            prepaidTemplates:[],

            editId:'',
            editMto:0, 
            editOffPartsImp: 0, 
            editOffPartsCon: 0, 
            editOffPartsTotal: 0, 
            editHours:0,
            editActive:false
        }
    }

    componentDidMount(){
        this.getPrepaidTemplates()
    }
    handleChange = (e, {name, value})=>{
        this.setState({[name]: value}, ()=>{
            console.log(this.state)
        })
    }

    

    renderModal=()=>{
        const { openModalNew } = this.state;
       
        return (
            <Modal open={openModalNew}>
                <Header icon="plus" content="Nueva Plantilla Prepagado"></Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} name="mto" type="number" label="Mantenimiento Rutina" placeholder="Rutina utilice numeros enteros"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} name="offPartsImp" type="number" label="Descuento Repuestos Importadora" placeholder="% de descuento repuesto Importadora"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} name="offPartsCon" type="number" label="Descuento Repuestos Concesionario" placeholder="% de descuento repuesto Concesionario"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} name="hours" type="number" label="Horas" placeholder="Duración en Horas de los trabajos"></Form.Input>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        size='tiny'
                        onClick={()=>{this.setState({openModalNew:false})}} 
                        content="CANCELAR" 
                        icon="times" 
                        style={{ borderRadius:25}} />
                    <Button 
                        size='tiny'
                        onClick={()=>{this.create(); this.setState({openModalNew:false})}} 
                        content="GUARDAR" 
                        icon="save" 
                        style={{ backgroundColor:"#BB162B", color:"#f6f6f6", borderRadius:25}} />
                </Modal.Actions>
            </Modal>
        )
    }

    renderEditModal=()=>{
        const { openModalEdit, editMto, editOffPartsImp, editOffPartsCon, editHours } = this.state;
       
        return (
            <Modal open={openModalEdit}>
                <Header icon="edit" content="Editar Plantilla"></Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={editMto} name="editMto" type="number" label="Mantenimiento Rutina" placeholder="Rutina utilice numeros enteros"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={editOffPartsImp} name="editOffPartsImp" type="number" label="Descuento Repuestos Importadora" placeholder="% de descuento repuesto Importadora"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={editOffPartsCon} name="editOffPartsCon" type="number" label="Descuento Repuestos Concesionario" placeholder="% de descuento repuesto Concesionario"></Form.Input>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={editHours} name="editHours" type="number" label="Horas" placeholder="Duración en Horas de los trabajos"></Form.Input>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        size="tiny"
                        style={{ borderRadius:25}}
                        content="CANCELAR" 
                        icon="times" 
                        onClick={()=>{this.setState({openModalEdit:false})}}/>
                    <Button 
                        size="tiny"
                        style={{ backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25}}
                        content="GUARDAR" 
                        icon="save" 
                        onClick={()=>{this.updatePrepaidTemplates();this.setState({openModalEdit:false}) }} />
                </Modal.Actions>
            </Modal>
        )
    }

    create(){
        create(this.state)
        .then( (response)=>{
            this.getPrepaidTemplates()
        } ).catch( (error)=>{
            console.log(error)
        })
    }

    getPrepaidTemplates(){
        getPrepaidTemplates()
        .then( (response)=>{
            this.setState({prepaidTemplates:response})
            console.log(response)
        } ).catch( (error)=>{
            console.log(error)
        })
    }

    updatePrepaidTemplates(){
        const { editId, editMto, editOffPartsImp, editOffPartsCon, editHours, editActive } = this.state;
        let body = {
            editId:editId,
            editMto:editMto,
            editOffPartsImp:editOffPartsImp,
            editOffPartsCon:editOffPartsCon,
            editHours: editHours,
            editActive: editActive
        }
        updatePrepaidTemplates(body)
        .then( (response)=>{
            this.getPrepaidTemplates()
        } ).catch( (error)=>{
            console.log(error)
        })
    }


    renderItems=()=>{
        const { prepaidTemplates } = this.state;
        console.log(prepaidTemplates)
        return prepaidTemplates.map( (i, index)=>{
            return <Table.Row key={index}>
                <Table.Cell>{i.mto}</Table.Cell>
                <Table.Cell>{i.offPartsImp}</Table.Cell>
                <Table.Cell>{i.offPartsCon}</Table.Cell>
                <Table.Cell>{i.offPartsTotal}</Table.Cell>
                <Table.Cell>{i.hours}</Table.Cell>
                <Table.Cell><Checkbox toggle checked={i.active} /></Table.Cell>
                <Table.Cell>
                    <Button 
                        onClick={ ()=> this.setState({editId:i._id, editMto:i.mto, editOffPartsImp:i.offPartsImp, editOffPartsCon:i.offPartsCon, editOffPartsTotal:i.offPartsTotal, editHours:i.hours, openModalEdit:true, editActive:i.active}) } 
                        content='EDITAR'
                        icon="edit"
                        size="tiny"
                        style={{ borderRadius:25 }}
                        />
                        
                    <Button 
                        onClick={ 
                            ()=> this.setState({ 
                                editId:i._id, 
                                editMto:i.mto, 
                                editOffPartsImp:i.offPartsImp, 
                                editOffPartsCon:i.offPartsCon, 
                                editOffPartsTotal:i.offPartsTotal, 
                                editHours:i.hours, 
                                editActive: !i.active
                            }, ()=> this.updatePrepaidTemplates() ) } 
                        icon="trash" 
                        style={{ borderRadius:25 }} />
                </Table.Cell>
            </Table.Row>
        } )
    }

    render(){
        
        return(
            <Grid style={{marginTop:'8vh', marginBottom:50 }}>
                <Container>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        TEMPARIO PREPAGADO
                        <Button 
                            icon="plus"
                            content="RUTINA"
                            style={{ backgroundColor:'#BB162B', color:"#f6f6f6", borderRadius:25}}
                            size="tiny"
                            floated='right' 
                            onClick={()=>{this.setState({openModalNew:true})}}/>
                    </Header>
                    
                    

                    <Table selectable size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Rutina</Table.HeaderCell>
                                <Table.HeaderCell>Descuentp Repuestos Importadora</Table.HeaderCell>
                                <Table.HeaderCell>Descuento repuestos Concesionario</Table.HeaderCell>
                                <Table.HeaderCell>Descuento Total</Table.HeaderCell>
                                <Table.HeaderCell>Tempario</Table.HeaderCell>
                                <Table.HeaderCell>Activo</Table.HeaderCell>
                                <Table.HeaderCell>Acciones</Table.HeaderCell>
                                
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {this.renderItems()}
                        </Table.Body>
                    </Table>

                    

                    {this.renderModal()}
                    {this.renderEditModal()}
                </Container>
            </Grid>
            
        )
    }
}