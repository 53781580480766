import React, { Component } from 'react';
import { Header, Button, Table, Pagination, Icon, Grid, Segment, Container, Form } from 'semantic-ui-react';

import FormReferenceComponent from '../../../../components/references/FormReferenceComponent';
import ItemReferenceComponent from '../../../../components/references/ItemReferenceComponent';

import { API } from '../../../../constants';

const token = localStorage.getItem('token')

export default class ReferenceScreen extends Component {
    constructor(props){
        super(props)
        this.state = { 
            active:true, 
            references:[{edit:false}],
            defaultActivePage:1,
            totalReferences:0,
            totalPages:10,
            itemsPerpage:50,
            skip:0,
            seeFormNewReference:false,
            referenceToEdit:{},

            model:'',
            ref:'',
            description:'',
            routine:'',
            seeFilter:false
        }
    }

    handleChange = (e, { name, value })=>{
        if(name === 'routine'){
            return this.setState({[name]: value })
         
        } else {
            this.setState({[name]: value.toUpperCase() })
        }
        
    }

    componentDidMount(){
        this.getReferences()
    }

    getReferences=()=>{
        
        const { active, itemsPerpage, skip, model, ref, description, routine } = this.state;
        fetch(API+'prepaidMant/reference/getReferences', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+token
            },
            body: JSON.stringify({
                active:active,
                itemsPerpage:itemsPerpage,
                skip:skip,
                model: model,
                ref: ref,
                description: description,
                routine: routine
            })
        }).then( (response)=> response.json())
        .then( (responseJson)=>{
            this.getCountReferences();
            this.setState({ references: responseJson})
        } ).catch( (error)=>{
            console.log(error)
        } )
    }

    getCountReferences=()=>{
        const { itemsPerpage, active, model, ref, description, routine }=this.state;
        fetch(API+'prepaidMant/reference/getCountReference', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+token
            },
            body: JSON.stringify({
                active:active,
                model: model,
                ref: ref,
                description: description,
                routine: routine
            })
        }).then( (count)=> count.json())
        .then( (count) => {
            let difference = count.count % itemsPerpage;
            let totalPages = 0;
            if(difference>0){
                totalPages = parseInt(count.count / itemsPerpage) + 1;
            } else {
                totalPages = parseInt(count.count / itemsPerpage);
            }
            this.setState({totalPages: totalPages, totalReferences:count.count})
        })
    }

    updateReference = ()=>{
        const { referenceToEdit }=this.state;
        console.log(referenceToEdit)
        fetch(API+'prepaidMant/reference/updateReference', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+token
            },
            body:JSON.stringify({
                _id:referenceToEdit._id,
                model:referenceToEdit.model,
                ref:referenceToEdit.ref,
                description:referenceToEdit.description,
                cost:referenceToEdit.cost,
                amount:referenceToEdit.amount,
                routine:referenceToEdit.routine,
                pvc:referenceToEdit.pvc,
                pvp:referenceToEdit.pvp, 
                mg_pvc:referenceToEdit.mg_pvc, 
                mg_pvp:referenceToEdit.mg_pvp,
                motor:referenceToEdit.motor, 
                aa:referenceToEdit.aa, 
                transmission:referenceToEdit.transmission, 
                displacement:referenceToEdit.displacement,
                active:referenceToEdit.active
            })
        }).then( (response)=> response.json() )
        .then( (responseJson)=>{
            console.log(responseJson)
            this.getReferences();
        } ).catch( (error)=>{
            console.log(error)
        })
    }


    renderReferences=()=>{
        const { references } = this.state;
        if(!(references && references.length>0)) return null;
        return references.map( (i, index)=>{
            return(<ItemReferenceComponent
                key={index}
                id={i._id}
                model={i.model}
                reference={i.ref}
                description={i.description}
                cost={i.cost}
                amount={i.amount}
                routine={i.routine}
                pvc={i.pvc}
                pvp={i.pvp}
                mg_pvc={i.mg_pvc}
                mg_pvp={i.mg_pvp}
                createup={i.createup}
                active={i.active}
                motor={i.motor} 
                aa={i.aa} 
                transmission={i.transmission}
                displacement={i.displacement}
                onClickTrash={ ()=> {i.active = false; this.setState({referenceToEdit:i}, ()=>{this.updateReference()}); } }
                onClickEdit={ ()=> { i.edit = !i.edit; this.setState({references:references}) }}
                edit={i.edit}
                onClickSave={()=>{ i.edit = false; this.setState({references:references})}}
                onClickCancel={ ()=>{ i.edit = !i.edit; this.setState({references:references}) }}
                updateReferences={ ()=>{ this.getReferences()} }
            ></ItemReferenceComponent>)
        } )
    }
    

    pageChange=($event)=>{
        const { itemsPerpage } = this.state; 
        this.setState({ skip: ($event.activePage-1) * itemsPerpage }, ()=>{
            this.getReferences()
            console.log("this.setState.skip")
            console.log(this.state.skip)
        });
    }

    render(){
        const { totalPages, totalReferences, seeFormNewReference, model, ref, description, routine, seeFilter } = this.state;
        return(
            <Grid style={{marginTop:"8vh", marginBottom:50, marginLeft:50, marginRight:50}}>
                <Container fluid>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        REPUESTOS
                        
                        {!seeFormNewReference?
                            <Button floated='right'
                                    onClick={ ()=> this.setState({seeFormNewReference:true})} 
                                    style={{backgroundColor:'#bb162b', color:"#f6f6f6", borderRadius:25}}
                                    size='tiny'>
                                <Icon name="plus"></Icon> AGREGAR PARTE
                            </Button>
                            :null
                        }
                        <Button onClick={()=>this.setState({seeFilter:!seeFilter})} content="FILTRAR" floated='right' size='tiny' icon="list" style={{borderRadius:25}} />
                    </Header>
                    


                    {seeFilter? 
                        <Segment>
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Input onChange={this.handleChange} name="model" placeholder="Línea (2 dígitos)" value={model} label="Línea" />
                                    <Form.Input onChange={this.handleChange} name="ref" placeholder="Referencia" value={ref} label="Referencia" />
                                    <Form.Input onChange={this.handleChange} name="description" placeholder="Descripción" value={description} label="Description" />
                                    <Form.Select 
                                        onChange={this.handleChange} 
                                        name="routine" 
                                        placeholder="Frecuencia" 
                                        value={routine} 
                                        label="Frecuencia"
                                        options={[
                                            {key:1, value:5, text:5},
                                            {key:2, value:10, text:10},
                                            {key:3, value:20, text:20},
                                            {key:4, value:40, text:40},
                                        ]} />
                                    
                                </Form.Group>
                                <Button onClick={()=>{this.getReferences(); this.getCountReferences()}} content="BUSCAR" icon="search" size="tiny" style={{borderRadius:25, backgroundColor:"#bb162b", color:"#f6f6f6"}} />
                            </Form>
                        </Segment>
                        : null
                    }
                    
                    {seeFormNewReference?<FormReferenceComponent cancel={()=> this.setState({seeFormNewReference:false})} updateReferences={ ()=>{ this.getReferences()}}></FormReferenceComponent>:null}
                    <Header as="h5">Listado referencias: {totalReferences} </Header>
                    <Table selectable size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Modelo</Table.HeaderCell>
                                <Table.HeaderCell>Referencia</Table.HeaderCell>
                                <Table.HeaderCell>Descripción</Table.HeaderCell>
                                <Table.HeaderCell>Cantidad</Table.HeaderCell>
                                <Table.HeaderCell>Rutina</Table.HeaderCell>
                                <Table.HeaderCell>Costo</Table.HeaderCell>
                                <Table.HeaderCell>PVC</Table.HeaderCell>
                                <Table.HeaderCell>PVP</Table.HeaderCell>
                                <Table.HeaderCell>MG PVC</Table.HeaderCell>
                                <Table.HeaderCell>MG PVP</Table.HeaderCell>
                                <Table.HeaderCell>Creado</Table.HeaderCell>
                                <Table.HeaderCell>Activo</Table.HeaderCell>
                                <Table.HeaderCell>Motor</Table.HeaderCell>
                                <Table.HeaderCell>AA</Table.HeaderCell>
                                <Table.HeaderCell>Caja</Table.HeaderCell>
                                <Table.HeaderCell>Cilindraje</Table.HeaderCell>
                                <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderReferences()}
                        </Table.Body>
                    </Table> 
                    
                    <Pagination onPageChange={(event, data)=>this.pageChange(data)}
                        defaultActivePage={1}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={totalPages}
                        siblingRange={1}
                        boundaryRange={1}
                    />
                    
                </Container>
                
                
            </Grid>
            
        )
    }
}