import React, { Component, Fragment } from 'react'
import { Header, Grid, Form, Container, Button, Modal, Table} from 'semantic-ui-react'
import { create, read, update, delet } from '../../../../services/tmog'
import { MessageToUser } from '../../../../actions'
import { connect } from 'react-redux'

const initialCategory = {
    min:0,
    max:0,
    value:0,
    name:'',
    _id:''
}

class Tmog extends Component {
    

    state = {
        openCategoryModal:false,
        use:'',
        confirmDeletModal:false,
        category: initialCategory,
        categorys:[]
    }

    componentDidMount(){
        this.read()
    }

    handleChange = (e, { name, value }) => {
        this.setState({
            category:{
                ...this.state.category, [name]: value
            }
        }, ()=> console.log(this.state))
    }

    create = ()=>{
        const { category } = this.state
        create( category )
        .then( result =>{
            console.log(result)
            if(result.status === 200){
                this.props.messageToUser('Operación exitosa', result.msg, true)
                this.setState({ category: initialCategory })
                this.read();
            } else {
                this.props.messageToUser('Error al crear', result.msg, true)
            }
        } )
    }

    read = () => {
        read()
        .then( result => {
            this.setState({categorys: result})
        })
    }

    update = () => {
        const { category } = this.state
        update( category )
        .then( result => {
            
            this.props.messageToUser( result.status===200?'Operación exitosa':'Error', result.msg, true)
            this.read()
        })
    }

    delet = () => {
        const { category } = this.state
        delet( category )
        .then( result => {
            this.props.messageToUser( result.status===200?'Operación exitosa':'Error', result.msg, true)
            this.setState({ confirmDeletModal:false, category:initialCategory})
            this.read()
        })
    }

    openEdit = (category) => {
        this.setState({
            category: category,
            openCategoryModal: true,
            use:'edit'
        })
    }

    confirmDelet = () => {
        const { confirmDeletModal, category } = this.state
        return <Modal open={confirmDeletModal} >
            <Modal.Header>
                ELIMINAR CATEGORIA
            </Modal.Header>
            <Modal.Content>
                Al eliminar esta categoría ya no estará disponible para calcular los temparios de mantenimiento de cada vehículo con acción comercial, Donde el concesionario
                tenga una calificación igual al nombre de esta categoría: <strong> </strong>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="CANCELAR"
                    icon="times"
                    style={{ borderRadius:25 }}
                    size="tiny"
                    onClick={()=> this.setState({ confirmDeletModal:false})}
                />
                <Button 
                    
                    content="BORRAR"
                    icon="trash"
                    style={{ borderRadius:25 }}
                    size="tiny"
                    onClick={()=> this.delet(category)}
                />
            </Modal.Actions>
        </Modal>
    }

    categoryModal = () => {
        const { openCategoryModal, use, category } = this.state;
        
        return <Modal open={openCategoryModal}>
            <Modal.Header>
                CATEGORIA
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} name='name' value={category.name} label="Nombre Categoría" placeholder="Nombre categoria" />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} name='value' value={category.value} type='number' label="Valor Mano de Obra" placeholder="Valor mano de Obra" />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} name='min' value={category.min} type='number' label="Calificación Min" placeholder="Mínima" />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} name='max' value={category.max} type='number' label="Calificación Max" placeholder="Máximo" />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="CANCELAR"
                    icon="times"
                    style={{ borderRadius:25 }}
                    size="tiny"
                    onClick={()=> this.setState({ openCategoryModal:false})}
                />
                {use==='create'?<Button
                    content="GUARDAR"
                    icon="save"
                    style={{ borderRadius:25, backgroundColor:'#bb162b', color:"#f6f6f6" }}
                    size="tiny"
                    onClick={()=> this.create() }
                />:
                <Button
                    content="ACTUALIZAR"
                    icon="save"
                    style={{ borderRadius:25, backgroundColor:'#bb162b', color:"#f6f6f6" }}
                    size="tiny"
                    onClick={()=> this.update() }
                />}
            </Modal.Actions>
        </Modal>
    }

    renderCategorys = () => {
        const { categorys } = this.state;

        if(!categorys || !(categorys.length >0)) return null

        return <Table size='small' selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell> CATEGORIA </Table.HeaderCell>
                    <Table.HeaderCell> VALOR </Table.HeaderCell>
                    <Table.HeaderCell> MIN </Table.HeaderCell>
                    <Table.HeaderCell> MAX </Table.HeaderCell>
                    <Table.HeaderCell> ACCIONES </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                { categorys.map( (i)=>{
                    return <Table.Row>
                        <Table.Cell> {i.name} </Table.Cell>
                        <Table.Cell> {i.value} </Table.Cell>
                        <Table.Cell> {i.min} </Table.Cell>
                        <Table.Cell> {i.max} </Table.Cell>
                        <Table.Cell> 
                            <Button
                                content='EDITAR'
                                icon="edit"
                                size='tiny'
                                style={{ borderRadius: 25 }} 
                                onClick={()=> this.openEdit(i)}
                            />
                            <Button
                                icon="trash"
                                size='tiny'
                                style={{ borderRadius: 25 }} 
                                onClick={()=> this.setState({ confirmDeletModal:true, category:i})}
                            />
                        </Table.Cell>
                    </Table.Row>
                } )}
            </Table.Body>
        </Table>
    }

    render(){
        return <Fragment>
        <Grid style={{marginTop:'8vh', minHeight:'68vh'}}>
            <Container>
                <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                    TMOG
                    <span>
                        <Button
                            floated='right'
                            content="CATEGORIA TMOG"
                            icon="add"
                            style={{ borderRadius:25, backgroundColor:'#bb162b', color:"#f6f6f6" }}
                            size="tiny"
                            onClick={()=> this.setState({ openCategoryModal:true, use:'create', category:initialCategory})}
                        />
                    </span>
                </Header>
                {this.renderCategorys()}
            </Container>
        </Grid>
        {this.categoryModal()}
        {this.confirmDelet()}
    </Fragment>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, msg, open)=> dispatch(MessageToUser(title, msg, open))
    }
}

export default connect(null, mapDispatchToProps)(Tmog)