const initialState = {
    message:'Puedes comunicarte con nosotros escribiendo a mtkingdespos@kia.com.co',
    title:'Bienvenido',
    open:false
}

const MessageToUserReducer = (state = initialState, action) => {
    switch(action.type){
        case 'MessageToUser':
            return {
                ...state,
                title: action.title,
                message: action.message,
                open: action.open
            }
        default:
            return state
    }
}

export default MessageToUserReducer