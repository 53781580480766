import React, { Component } from 'react';
import { Grid, Header, Icon, Image, Responsive } from 'semantic-ui-react';
import imgBackGround from '../../assets/backgroundhome.jpg';
import imgTexture from '../../assets/background/bg.jpg'
import logo from '../../assets/logo_color.png';
import { showComponent } from '../../actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class LandingScreen extends Component {
    render(){
        return (
            <Grid style={{backgroundColor:"light"}}>
                <Grid.Row width={16} style={{minHeight:"60vh", backgroundImage:`url(${imgBackGround})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>

                </Grid.Row>
                <Grid.Row reversed='mobile' centered width={16} style={{marginTop:-14}} verticalAlign="middle">

                    <Grid.Column
                        verticalAlign="middle" 
                        textAlign='center' 
                        mobile={16} 
                        computer={4} 
                        style={{ 
                            minHeight:"20vh", 
                            backgroundColor:"#999999", 
                            backgroundImage:`url(${imgTexture})`, 
                            // backgroundSize: 'cover'
                            // marginBottom:-30,
                            padding:14
                        }}>
                        <Image src={logo} size='medium' centered={true} style={{ margin:'auto'}} />
                    </Grid.Column>
                    

                    <Grid.Column verticalAlign="middle" textAlign='center' mobile={8} computer={3} style={{ minHeight:'20vh', backgroundColor:"#999999", padding:0}}>
                            <Header as={Link} to={'/quotation'} onClick={()=> this.props.showComponent('simulator')} icon style={{marginTop:60, color:"#F6F6F6", cursor:'pointer'}}>
                                <Icon.Group>
                                    <Icon name="calculator" />
                                    <Icon name="car" corner='bottom right' style={{color:"#BB162B", fontSize:18}}/>
                                </Icon.Group>
                                
                                <Header.Content>
                                    Cotizador
                                    <Header.Subheader style={{color:"#F6F6F6"}}>
                                        Cálculo de valor del paquete
                                    </Header.Subheader>
                                </Header.Content>
                            </Header>
                        
                    </Grid.Column>

                    <Grid.Column mobile={8} computer={3} style={{ minHeight:'20vh',  backgroundColor:"#666666", justifyContent:'center' }} verticalAlign="middle">
                        
                            <Header as={Link}  to={'activation'} onClick={()=> this.props.showComponent('activation')} icon style={{marginTop:60, color:"#F6F6F6", cursor:"pointer"}}>
                                <Icon name="check circle" />
                                <Header.Content>
                                    Activación
                                    <Header.Subheader style={{color:"#F6F6F6"}}>
                                        Activación de paquetes
                                    </Header.Subheader>
                                </Header.Content>
                            </Header>
                        
                    </Grid.Column>

                    <Grid.Column mobile={8} computer={3} style={{ minHeight:'20vh',  backgroundColor:"#454545", }} verticalAlign="middle">
                        <Header icon style={{marginTop:60, color:"#F6F6F6", cursor:'pointer' }}>
                            <Icon.Group>
                                <Icon name="box" />
                                <Icon corner='bottom right' style={{color:"#BB162B", fontSize:18}} name='check circle' />
                            </Icon.Group>
                            
                            <Header.Content>
                                Paquetes Activos
                                <Header.Subheader style={{color:"#F6F6F6"}}>
                                    Paquetes
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column mobile={8} computer={3} style={{ minHeight:'20vh',  backgroundColor:"#333333"}} verticalAlign="middle">

                        <Header icon style={{marginTop:60, color:"#F6F6F6", cursor:'pointer'}}>
                            <Icon name="chart line" />
                            <Header.Content>
                                Reportes
                                <Header.Subheader style={{color:"#F6F6F6"}}>
                                    Reportes e informes
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    
                </Grid.Row>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showComponent:(component)=> dispatch(showComponent(component))
    }
}

export default connect(null, mapDispatchToProps)(LandingScreen)