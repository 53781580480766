import React, { Component, Fragment } from 'react';
import NavbarPublic from './NavbarPublic';
import { Route, HashRouter, Switch } from 'react-router-dom';
import LoginScreen from '../LoginScreen';
import HomePublicScreen from './HomePublicScreen';
import FooterComponent from '../../components/FooterComponent';
import CustomerViewScreen from './CustomerView';

class PublicScreen extends Component {
    Login = ()=> {
        return <LoginScreen/>
    }
    HomePublic = ({ history })=>{
        return <HomePublicScreen history={history}/>
    }

    CustomerView = ({match})=>{
        return <CustomerViewScreen match={match} />
    }
    render(){
        return (
            <Fragment>
                <HashRouter basename="/">
                    <NavbarPublic></NavbarPublic>
                    <Switch>
                        <Route exact path="/HomePublic" component={this.HomePublic} />
                        <Route exact path="/kiausers/" component={this.Login} />
                        <Route path="/viewCustomer/:vin/:id" component={this.CustomerView} />
                        <Route component={this.HomePublic} />
                    </Switch>
                    <FooterComponent/>
                </HashRouter>
            </Fragment>
            
        )
    }
}

export default PublicScreen