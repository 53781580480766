import React, { Component, Fragment } from 'react';
import { Grid, Container, Header, Form, Button, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getApi } from '../../../../utils/Constants';

class ReportsScreem extends Component {

    state = {
        dateInit:moment().format('YYYY-MM-')+'01',
        dateEnd:moment(moment().format('YYYY-MM-')+'01', 'YYYY-MM-DD').add(1, 'month').subtract(1, 'minute').format('YYYY-MM-DD'),
        dealers:[],
        
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }

    getData = async (report)=>{
        const { dateInit, dateEnd } = this.state;
        this.setState({dealers: this.props.user.dealer}, function(){
            
            window.open(getApi()+`prepaidMant/reports/${report}?dealers=${JSON.stringify(this.state.dealers)}&dateInit=${dateInit}&dateEnd=${dateEnd}`)
        })
    }

    renderCardReport = () => {
        const reports = [
            {
                title:'Listado de Rutinas',
                description:'Genere un listado con las rutinas de Mantenimiento KPlus, en el podrá saber los valores con detalle y el estado de redención de cada Rutina.',
                key:'getRoutines'
            },
            {
                title:'Listado de Paquetes',
                description:'Genere un listado de paquetes ACTIVOS, en el que puede consultar el valor Redimido total, el valor por Redimir y la comisión',
                key:'getAllPackage'
            }
        ]

        return reports.map( (i)=>{
            return <Segment>
                <Header> {i.title} </Header>
                <p> {i.description} </p>
                <Button 
                    onClick={()=>this.getData(i.key)} 
                    content="DESCARGAR XLSX" 
                    icon="file excel" 
                    style={{ borderRadius:25, backgroundColor:'#BB162B', color:'#f6f6f6'}} 
                    size='tiny' />
            </Segment>
        } )
    }

    render(){
        
        const { dateEnd, dateInit } = this.state;
        return <Fragment>
            <Grid style={{marginTop:'8vh', minHeight:'68vh'}}>
                <Container>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        REPORTES
                    </Header>

                    <Form>
                        <Form.Group>
                            <Form.Input onChange={this.handleChange} value={dateInit} name="dateInit"  type='date' label='Desde (Mes/Día/Año)' />
                            <Form.Input onChange={this.handleChange} value={dateEnd} name="dateEnd" type='date' label='Hasta (Mes/Día/Año)' />
                        </Form.Group>
                        {this.renderCardReport()}
                    </Form>
                </Container>
            </Grid>
        </Fragment>
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data
    }
}
export default connect(mapStateToProps, null)(ReportsScreem)