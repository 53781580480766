import React, { Component, Fragment } from 'react';
import { Grid, Dimmer, Header, Icon, Form, Segment, Button, Container, Table, Label, Statistic, Menu, Image } from 'semantic-ui-react';
import { getApi } from '../../utils/Constants';
import moment from 'moment';
import backgroundImage from '../../assets/backgroundhome.jpg';
import logoKPlusImage from '../../assets/logo-blanco.png';
import backgroundTexture from '../../assets/background/bg.jpg'
import FrequenQuestionsComponent from './FrequenQuestions';
// import 
import * as jsPDF  from 'jspdf';
import  'jspdf-autotable'
import image2base64 from 'image-to-base64';
import { MessageToUser } from '../../actions'
import { connect } from 'react-redux'

const certificateTemplate = require('../../assets/template/template-head-white.jpg')
const CurrencyFormat = require('react-currency-format')


class CustomerViewScreen extends Component {
    state = {
        vin:'',
        ni:'',
        data:{},
        auth:false,
        contentActive:'home',
        templateCertificate:''
    }
    componentDidMount(){
        moment.locale('es');
        if(this.props.match.params.vin && this.props.match.params.id){
            this.setState({
                vin:this.props.match.params.vin,
                ni:this.props.match.params.id
            }, ()=>{
                this.getData()
            })
        }
        if(this.props.match.params.vin && !this.props.match.params.id){
            this.setState({auth:false})
        }
        
    }

    getData = async()=> {
        const { vin, ni } = this.state;
        return await fetch(`${getApi()}prepaidMant/customer/getOrderToCustomer?vin=${vin}&ni=${ni}`, {
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
            }
        }).then( (res)=> res.json() )
        .then( (result)=>{
            if(result.status === 200){
                // setTimeout( ()=>{
                    this.setState({data:result.data, auth:true})
                // }, 500 )
            } else if(result.status === 401){
                this.setState({auth:false})
            }
        } ).catch( (error)=>{
            console.log(error)
        } )
    }

    hanledChange=(e, { name, value})=>{
        this.setState({[name]: value})
    }
    signIn = ()=> {
        const { id } = this.state;
        this.setState({ni: id}, ()=>{
            this.getData()
        })
        
    }

    renderPlanTable =()=>{
        const { projection, plan } = this.state.data;
        console.log(projection)
        if(projection && projection.length>0){
            return projection.map( (i, index)=>{
                if( i.routine <= plan.routine){
                    
                    return <Table.Row key={i.futureFactor+index}>
                        <Table.Cell>{i.routine}</Table.Cell>
                        <Table.Cell>
                            {/* {this.renderExpirationDate(activationDate, i.redeemStatus, i.routine)} */}
                            {i.expirationDate?moment(i.expirationDate).format('DD-MM-YYYY'):null}
                        </Table.Cell>
                        <Table.Cell>{i.dateRedeem?moment(i.dateRedeem).format('DD-MM-YYYY'):'Pendiente'}</Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.valueToRedeem} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                        </Table.Cell>
                        <Table.Cell>
                            {i.kiaOnTimeLink? <a target="_blank" rel="noopener noreferrer" href={i.kiaOnTimeLink}>{i.kiaOnTimeLink}</a>:null}
                        </Table.Cell>
                        <Table.Cell>
                            {this.renderIconStatus(i.redeemStatus, i, index)}
                        </Table.Cell>
                    </Table.Row>
                } else {
                    return null
                }
            } )
        }
    }

    renderIconStatus = (status )=> {
        
        switch(status){
            case 'noRedeem':
                return (
                    
                    <Label circular color="grey">
                        <Icon name="clock outline" />
                        PENDIENTE
                    </Label>
                )
            case 'redeem':
                return (
                    <Label circular color="green">
                        <Icon name="check" />
                        REDIMIDO
                    </Label>
                )
            case 'expired':
                return (
                    <Label circular color="red">
                        <Icon name="x"/>
                        EXPIRADO
                    </Label>
                )
            case 'returned':
                return(
                    <Label circular color='blue'>
                        RETORNADO
                        <Icon name="redo"/>
                    </Label>
                )
        }
    }

    renderHeader=()=>{
        const { plan } = this.state.data;
        const { contentActive } = this.state;
        
        if(!plan) return null;
        if(contentActive !== 'home') return null
        return  <Fragment>
                    <Grid columns={16} divided style={{ padding:'0 !important'}} 
                        size='massive' 
                        style={{ 
                            padding:-5, 
                            backgroundImage:`url(${backgroundImage})`,
                            backgroundPosition:'center', 
                            backgroundSize: 'cover',
                            minHeight:'55vh'}}>
                                
                        
                    </Grid>

                    <Grid columns={16} divided style={{ padding:'0 !important'}}>
                        <Grid.Row reversed='mobile' style={{ paddingTop:0, paddingBottom:0}}>
                            <Grid.Column
                                mobile={16} 
                                computer={4}
                                textAlign='center'
                                style={{ 
                                    paddingTop:0,
                                    backgroundImage:`url(${backgroundTexture})`,
                                    backgroundPosition:'center',
                                    backgroundSize:'cover'}}>
                                <Image style={{ width:200, textAlign:'center', margin:'auto', paddingTop:20}} src={logoKPlusImage} />
                            </Grid.Column>

                            <Grid.Column
                                textAlign='center'
                                mobile={8} computer={3} 
                                style={{ 
                                    backgroundImage:`url(${backgroundTexture})`,
                                    backgroundPosition:'center',
                                    backgroundSize:'cover',}}>
                                <Statistic inverted style={{ margin:'auto',  marginTop:35, minHeight:'12vh'}}>
                                    <Statistic.Value style={{fontWeight:'bold'}}><strong>{plan.routine} K</strong></Statistic.Value>
                                    <Statistic.Label>PAQUETE ADQUIRIDO</Statistic.Label>
                                </Statistic>
                            </Grid.Column>

                            <Grid.Column 
                                as='a'
                                onClick={()=>this.generateCertificate()}
                                centered 
                                verticalAlign="middle" 
                                textAlign='center'
                                mobile={8} 
                                computer={3}  
                                style={{ backgroundColor:'#999999', borderColor:'blue', }}>
                                <Header as="a" icon style={{ minHeight:'12vh', marginTop:30, color:"#F6F6F6", cursor:'pointer'}}>
                                    <Icon.Group>
                                        <Icon name="download" />
                                        <Icon name="file" corner='bottom right' style={{color:"#BB162B", fontSize:18}}/>
                                    </Icon.Group>
                                    
                                    <Header.Content>
                                        Certificado
                                        <Header.Subheader style={{color:"#F6F6F6"}}>
                                            Certificado mantenimientos
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Grid.Column>
                            
                            <Grid.Column 
                                as='a'
                                onClick={()=>this.setState({ contentActive:'details' })}
                                centered 
                                verticalAlign="middle" 
                                textAlign='center'
                                mobile={8} 
                                computer={3}  
                                style={{ backgroundColor:'#454545', borderColor:'blue', }}>
                                <Header as="a" icon style={{ minHeight:'12vh', marginTop:30, color:"#F6F6F6", cursor:'pointer'}}>
                                    <Icon.Group>
                                        <Icon name="box" />
                                        <Icon name="info" corner='bottom right' style={{color:"#BB162B", fontSize:18}}/>
                                    </Icon.Group>
                                    
                                    <Header.Content>
                                        Paquete
                                        <Header.Subheader style={{color:"#F6F6F6"}}>
                                            Detalles del Paquete
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Grid.Column>

                            <Grid.Column 
                                as='a'
                                onClick={()=>this.setState({ contentActive:'help'})}
                                centered 
                                verticalAlign="middle" 
                                textAlign='center'
                                mobile={8} 
                                computer={3}  
                                style={{ backgroundColor:'#333333', borderColor:'blue', }}>
                                <Header as="a" icon style={{ minHeight:'12vh', marginTop:30, color:"#F6F6F6", cursor:'pointer'}}>
                                    <Icon.Group>
                                        <Icon name="file" />
                                        <Icon name="help" corner='bottom right' style={{color:"#BB162B", fontSize:18}}/>
                                    </Icon.Group>
                                    
                                    <Header.Content>
                                        Ayuda
                                        <Header.Subheader style={{color:"#F6F6F6"}}>
                                            Preguntas frecuentes
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Fragment> 
    }

    renderDetails = () => {
        const { plan, vin, form, activationDate, expirationDate, article } = this.state.data;
        const { contentActive } = this.state;

        if(!plan) return null;
        if(contentActive !== 'details') return null
        return <Container style={{ marginTop:'8vh'}}>
            <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                DETALLES
            </Header>
             <Segment>
                <Grid stackable>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <p>
                                <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>VIN</span> <br/>
                                {vin}
                            </p>
                            <p>
                                <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>PLACA</span> <br/>
                                {form.plate}
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p>
                                <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>Fecha de activación</span> <br/>
                                {moment(activationDate).format('DD / MM / YYYY')}
                            </p>
                            <p>
                                <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>Fecha de vencimiento</span> <br/>
                                {moment(expirationDate).format('DD / MM / YYYY')}
                            </p>
                        </Grid.Column>
                        <Grid.Column>
                            <p>
                                <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>Punto de venta</span> <br/>
                                {form.dealerData.d_name}
                            </p>
                            <p>
                                <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>Código Equipamiento</span> <br/>
                                {article}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Rutina</Table.HeaderCell>
                        <Table.HeaderCell>Vence</Table.HeaderCell>
                        <Table.HeaderCell>Redimida</Table.HeaderCell>
                        <Table.HeaderCell>Valor Rutina</Table.HeaderCell>
                        <Table.HeaderCell>Kia On Time Link</Table.HeaderCell>
                        <Table.HeaderCell>Estatus</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.renderPlanTable()}
                </Table.Body>
            </Table>
        </Container>
    }

    renderHelp = () => {
        const { contentActive } = this.state;

        if(!this.state.data) return null;
        if(contentActive !== 'help') return null;
        return <Fragment>
            <FrequenQuestionsComponent data={this.state.data}></FrequenQuestionsComponent>
        </Fragment>
    }

    generateCertificate = () => {
        
        
        const  { vin, plan, activationDate, form, projection } = this.state.data;
        
        let certificateContent = `Que el vehículo Kia ${form.modelCod} de VIN ${vin} cuenta con un paquete de Mantenimiento K plus de ${plan.routine} K desde el día ${moment(activationDate).format('DD MMMM YYYY')}. Dicho paquete de mantenimiento fue adquirido de manera anticipada en el concesionario ${form.dealerData.d_name||form.dealerData.name_dealer}, el cual ejecuta el programa de rutinas de mantenimientos preventivos exclusivamente en la red de concesionarios autorizados de la marca, con técnicos certificados por fábrica y suministro de repuestos genuinos.`
        let certificateFoot = `Esta certificación se emite el día ${moment().format('DD')} del mes de ${moment().format('MMMM')} de ${moment().format('YYYY')} desde el usuario activo del titular.`
        image2base64(certificateTemplate)
        .then( (data) => {
            var doc = new jsPDF({
                orientation: 'p',
                unit: 'mm',
                format: 'a4',
                putOnlyUsedFonts:true
            });

            let routineRedeem = []
    
            
            doc.addImage(data, 'JPG', 0, 0, 210, 296, 'PAGE1', 'FAST', 0);
            
            doc.setFontSize(12)
            doc.text('Metrokia S.A., representante de Kia Motors en Colombia', 30, 60, { align:'left', maxWidth:296})
            doc.setFontStyle('bold')
            doc.setFontSize(20)
            doc.setTextColor('#bb162b')
            doc.text('CERTIFICA', 30, 80)
            doc.setTextColor('#666666')
            doc.setFontSize(11)
            doc.setFontStyle('normal')
            doc.text(certificateContent, 30, 100, { maxWidth:160, align:'justify'})
            doc.text('Este vehículo registra en su historial a la fecha:', 30, 130)

            if(projection && projection.length>0){
                for(let i of projection){
                    if(i.redeemStatus === 'redeem'){
                        routineRedeem.push([
                            i.routine,
                            moment(i.dateRedeem).format('DD MM YYYY'),
                            i.kilometers,
                            i.dealerRedeem,
                            i.kiaOnTimeLink,
                            'REDIMIDO'
                        ])
                    }
                }
            }

            doc.autoTable({
                startY:140,
                margin:{left:30, right:18, bottom:40},
                columnStyles:{2:{ cellWidth:20}},
                headStyles:{ fillColor:"#bb162b"},
                styles:{ fontSize:8, cellWidth:'auto', minCellWidth:25, halign:'left', valign:'middle' },
                head: [['Rutina', 'Fecha', 'km', 'Concesionario', 'KiaOnTime', 'Estado']],
                body: routineRedeem
            });

            doc.setFontSize(8)
            doc.text('Esta información NO incluye ingresos a centros de servicio autorizado relacionados a mantenimientos correctivos, siniestros, carrocería o garantía. Únicamente aquellos relacionados al Mantenimiento Preventivo establecido por fábrica. ', 30, 255, { maxWidth:160, align:'justify'})
            doc.setFontSize(12)
            doc.text(certificateFoot, 30, 265, { maxWidth:160, align:'justify'} )

            


            doc.save(`ACUERDO-MANTPRE-${vin}`, { returnPromise:true})
            .then( resultPdf=>{
                this.setState({downloading:false})
            }, error=>{
                this.props.messageToUser('Error al descargar el PDF', 'Ocurrió un error al descargar el PDF, por favor intente cerrar sesión e intentarlo nuevamente', true)
                this.setState({downloading:false})
    
            })

            // window.open(doc.output('bloburl'), '_blank');

        }).catch( (error)=> {
            this.props.msgTouser('Error', 'Lo sentimos ocurrio un error al generar el certificado', true)
        })

        
    }

    render(){
        const { auth, contentActive } = this.state;
        
        return (
            <Fragment>
                <Dimmer.Dimmable page blurring dimmed={!auth}>

                <Dimmer active={!auth}>
                    <Segment textAlign='left' size="big">
                        <Header as='h1'>
                            ACCESO A CLIENTES
                        </Header>
                        <Form size='large'>
                            <Form.Input onChange={this.hanledChange} fluid name='id' type="password" label='Identifación' placeholder='Número de indentificación' />
                            <Button 
                                size='tiny'
                                onClick={()=>this.signIn()} 
                                icon="sign-in" 
                                content="ACCEDER" 
                                style={{ backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25}} />
                        </Form>
                    </Segment>
                </Dimmer>
                
                    <div style={{ minHeight:'70vh', marginBottom:15, marginTop:90, padding:-10 }}>
                    <Menu fixed='top' style={{ height:'49px', backgroundColor:'#bb162b', color:'#ffffff'}}>
                        <Container>
                            <Menu.Item>
                                <img style={{width:100, height:'auto', }}  src={require('../../assets/icon-kplus.png')} />
                                {/* <Image centered style={{widht:100, height:'auto', margin:'auto'}} src={ require('../../assets/icon-kplus.png')}  /> */}
                            </Menu.Item>
                            <Menu.Item 
                                onClick={()=>this.setState({contentActive:'home'})}
                                active={contentActive==='home'} 
                                style={{ color:'#FFFFFF'}}
                                name='PRINCIPAL'
                            />
                            <Menu.Item 
                                onClick={()=>this.setState({contentActive:'details'})}
                                active={contentActive==='details'}
                                style={{ color:'#FFFFFF'}}
                                name='DETALLES'
                            />
                            <Menu.Item 
                                onClick={()=>this.setState({contentActive:'help'})}
                                style={{ color:'#FFFFFF'}}
                                active={contentActive==='help'} 
                                name='AYUDA'
                            />
                        </Container>
                    </Menu>
                    <Container fluid style={{ paddingTop:49}}>
                        {this.renderHeader()}
                        {this.renderDetails()}
                        {this.renderHelp()}
                    </Container>
                       
                    </div>
                    
                </Dimmer.Dimmable>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        msgTouser: (title, msg, open) => dispatch( MessageToUser( title, msg, open ))
    }
}
export default connect( null, mapDispatchToProps)(CustomerViewScreen)