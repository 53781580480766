import React, { Component, Fragment } from 'react';
import { Header, List, Segment, Button, Icon, Form, Container, Item, Message } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import StepComponent from '../../../../components/StepComponent';
import { upDateOrder } from '../../../../services/orders/orderService';

import { searchOrderByVin } from '../../../../services/orders/orderService';
import { connect } from 'react-redux';
import { showComponent, MessageToUser } from '../../../../actions';
import GeneratePDFComponent from './GeneratePDFComponent';
import AttachAgreement from './AttachAgreement';
import CurrencyFormat from 'react-currency-format';

const wayToPayOptions = [
    { key: 'C', text: 'CONTADO', value: 'C' },
    { key: 'F', text: 'FINANCIACIÓN INLCUIDA VEH', value: 'F' },
    { key: 'I', text: 'FINANC INDEPENDIENTE', value: 'I' },
]

class DeliveryScreen extends Component {
    constructor(props){
        super(props)
        this.state = { 
            order:{},
            step1:{ active:true, complete:false},
            step2:{ active:false, complete:false},
            step3:{ active:false, complete:false},
            step4:{ active:false, complete:false},

            // form
            errorPlate:{ active:false, message:''},
            plate:'',
            today:new Date(Date.now()),
            expDate:'',
            kilometersLimit:0,
            signature:'',
            signaturePosition:'GERENTE SERVICIO/POSVENTA',
            donwnloadingPDF:false,

            orderReady:false,
            searchLoading:false,
            vinSearched:'',
            showMenssage:false,
            message:'',

            carNumber1:'',
            carNumber2:'',

         }
    }

    componentDidMount(){
        if(!this.props.order){
            
        } else {
            this.setState({
                order:this.props.order,
                wayToPay:this.props.order.wayToPay,
                plate:this.props.order.form.plate,
                carNumber1:this.props.order.carNumber1?this.props.order.carNumber1:'',
                carNumber2:this.props.order.carNumber2?this.props.order.carNumber2:'',
                loading:false,
                orderReady:true}, ()=>{
                    this.computedExpirationDate()
                })
        }
    }

    handleChange = (e) => {

        if(e.target.value && e.target.value.length>0){
            this.setState({vinSearched:e.target.value.toUpperCase()}, ()=>{
                console.log(this.state.vinSearched)
            })
        } else {
            this.setState({vinSearched:e.target.value }, ()=>{
                console.log(this.state.vinSearched)
            })
        }
        
    }

    handleChangeDate = (date)=>{
        this.setState({expDate:date});
    }
    handleChangeDateII = (date)=>{
        this.setState({today:date}, ()=>{ this.computedExpirationDate() });
    }
    handleChangeFormInput=(e, {name, value})=>{

        if(name==="plate"){
            const letters = 'abcdefghyjklmnopqrstuvwxyzABCDEFGHYJKLMNOPQRSTUVWXYZ';
            const numbers = '1234567890';
            let first = false;
            let second = false;
            let thirth = false;
            let four = false;
            let five = false;
            let six = false;

            for(let i of letters){
                if(value.charAt(0) === i){
                    first = true;
                }
                if(value.charAt(1) === i){
                    second = true;
                }
                if(value.charAt(2) === i){
                    thirth = true;
                }
            }

            for(let i of numbers){
                if(value.charAt(3) === i){four = true};
                if(value.charAt(4) === i){five = true};
                if(value.charAt(5) === i){six = true};
            }

            if(!first || !second || !thirth || !four || !five || !six ){
                this.setState({errorPlate:{ active:true, message:'PLACA NO VALIDA'}})
            } else {
                value = value.toUpperCase();
                this.setState({errorPlate:{ active:false, message:''}})
            }
            if(value.length !== 6){
                this.setState({errorPlate:{ active:true, message:'DEBE TENER 6 CARACTERES'}})
            }
            this.setState({[name]: value}, ()=>{
                console.log(this.state[name])
            })
        } else {
            this.setState({[name]: value}, ()=>{
                console.log(this.state[name])
            })
        }

    }

    SearchOrderComponent = ()=>{
        const { vinSearched } = this.state;
        return(
            <Segment basic style={{minHeight:'63vh'}}>
                <Container textAlign='center' style={{paddingTop:'20vh'}}>
                    <Header>Vin del vehículo</Header>
                    <Form >
                        <Form.Field>
                            <input onChange={this.handleChange} value={vinSearched} style={{borderRadius:25, maxWidth:300, textAlign:'center'}} placeholder='VIN'></input>
                            {vinSearched?<span style={{fontSize:10}}><br/> {vinSearched.length} / 17 caracteres </span>:null}
                        </Form.Field>
                    </Form> <br/>
                    <Button onClick={()=>this.props.showComponent('home')} style={{borderRadius:25}}>
                        <Icon name="chevron circle left"/> ATRAS 
                    </Button>
                    <Button onClick={()=> this.getOrderByVin()} style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}>
                        BUSCAR <Icon name="search"/>
                    </Button>
                    {this.state.showMenssage?<Segment placeholder>
                        <Header icon>
                        <Icon name='file alternate outline' />
                            {this.state.message}
                        </Header>
                        <Button style={{borderRadius:25, backgroundColor:'#7E8083', color:'#F6F6F6'}} onClick={ ()=> this.setState({ showMenssage:false }) } primary> OK </Button>
                    </Segment>:null}
                </Container>
            </Segment>
        )
    }

    getOrderByVin = () => {
        const { vinSearched } = this.state;
        searchOrderByVin({vin:vinSearched})
        .then( data =>{
            if(!data.noFind){
                this.setState({ 
                    order:data, 
                    carNumber1:data.carNumber1?data.carNumber1:'',
                    carNumber2:data.carNumber2?data.carNumber2:'',
                    wayToPay:data.wayToPay,
                    plate:data.form.plate, 
                    orderReady:true}, ()=>{
                    this.computedExpirationDate();
                })
            } else {
                this.setState({showMenssage:true, message:data.message})
            }
            
        })
    }



    computedExpirationDate=()=>{
        const { routine } = this.state.order.plan;
        const { today } = this.state;
        
        let months = (((routine / 5)-2)*6) + 24;
        
        this.setState({ expDate: new Date( moment(today).add(months, 'months').format() ) })
        
    }

    instructionsComponent = () =>{
        const { order } = this.state;
        
        if(order){
            if(!order.form.name || !order.form.lastName) return <Message negative>
                                                                    <Message.Header>Complete la información básica del Cliente</Message.Header>
                                                                    <Message.Content>
                                                                        El nombre y/o el apellido del Cliente no está completo, puedo completarlo en la sección > Formulario > Editar
                                                                    </Message.Content>
                                                                </Message>
            if(!order.form.typeId || !order.form.ni) return <Message negative>
                                                                <Message.Header>Complete la información básica del Cliente</Message.Header>
                                                                <Message.Content>
                                                                    Complete el número y el tipo de identificación del cliente
                                                                </Message.Content>
                                                            </Message>
            if( 
                !order.form.address || 
                !order.form.city || 
                !order.form.country  || 
                !order.form.email || 
                !order.form.telephone
            ) return <Message negative>
                        <Message.Header>Complete la información básica del Cliente</Message.Header>
                        <Message.Content>
                            Complete los datos de contacto del cliente, recuerde: Dirección, Ciudad, País, Email y Teléfono
                        </Message.Content>
                    </Message>
        }
        return (
            <Segment basic>
                <Header style={{fontFamily:'KiaBold', fontSize:18, textTransform:'uppercase'}}>
                    Activación del Paquete
                </Header>
                
                
                <Header as='h3'>INSTRUCCIONES</Header>
                <List ordered>
                    <List.Item>Diligencie la placa del vehículo</List.Item>
                    <List.Item>Diligencie la forma de pago</List.Item>
                    <List.Item>Normalmente la fecha de activación del Paquete de mantenimiento es el día de hoy, pero puede editarla si es necesario</List.Item>
                    <List.Item>Revise que la fecha de expiración esté acorde a la del acuerdo</List.Item>
                    <List.Item>Genere el acuerdo</List.Item>
                    <List.Item>Recoja las firmas por parte del cliente</List.Item>
                    <List.Item>Digitalice el documento y cárguelo en la sección cargar documento</List.Item>
                </List>
                <Button onClick={()=> this.setState({step1:{ ...this.state.step1, active:false }, step2:{ ...this.state.step2, active:true }})} style={{borderRadius:25, backgroundColor:'#BB162B', color:'#FFFFFF'}}>
                    SIGUIENTE <Icon  name='chevron circle right' style={{color:'#FFFFFF'}}/>
                </Button>
            </Segment>
        )
    }

    formActivationComponent = () => {
        const { plate, today, wayToPay, errorPlate, expDate, carNumber1 } = this.state;
        return (
            <Segment basic>
                <p style={{textAlign:'justify'}}>
                    <strong>Placa: </strong> La placa debe contener 6 caracteres, los primeros tres deben ser letras, la "Ñ" no está permitido y 
                    los últimos 3 deben ser números.
                </p>
                <Form>
                    <Form.Group widths="equal" style={{marginBottom:30}}>
                        <Form.Input 
                            error={errorPlate.active? {content:errorPlate.message, pointing:'below'}:false}
                            onChange={this.handleChangeFormInput} 
                            name="plate" label="Placa" 
                            value={plate} />
                        <Form.Select onChange={this.handleChangeFormInput} name="wayToPay" options={wayToPayOptions} value={wayToPay} label="Forma de pago"></Form.Select>
                        <Form.Field>
                            <label>Activación Paquete</label>
                            <DatePicker
                                selected={today}
                                onChange={this.handleChangeDateII}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Vencimiento Paquete</label>
                            <DatePicker
                                selected={expDate}
                                onChange={this.handleChangeDate}>
                            </DatePicker>
                        </Form.Field>
                    </Form.Group>
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Item.Header>
                                    <CurrencyFormat onValueChange={(values)=>{ this.setState({carNumber1:values.value}) }} value={carNumber1}  format='#####  ####' mask='-' />
                                </Item.Header>
                                <Item.Meta>Serial tarjeta</Item.Meta>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                    

                    <Button style={{borderRadius:25}} onClick={()=>this.setState({step1:{ ...this.state.step1, active:true }, step2:{ ...this.state.step2, active:false }})}><Icon name="chevron circle left"/> ATRAS </Button>
                    <Button style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}} onClick={()=>this.updateOrder()}> GUARDAR CAMBIOS <Icon name="save"/></Button>
                </Form>
            </Segment>
        )
    }

    updateOrder=()=>{
        const { plate, wayToPay, today, expDate, order, errorPlate, carNumber1, carNumber2  } = this.state;

        if(errorPlate.active || !plate || plate.length!=6){
            return this.props.messageToUser('Placa Inválida', errorPlate.message?errorPlate.message:'Por favor diligencia la placa', true)
        }

        let temporalOrder = order;
        temporalOrder.form.plate = plate;
        temporalOrder.wayToPay = wayToPay;
        temporalOrder.activationDate = today;
        temporalOrder.expirationDate = expDate;
        temporalOrder.carNumber1 = carNumber1;
        temporalOrder.carNumber2 = carNumber2;

        upDateOrder(temporalOrder)
        .then( response=>{
            console.log(response)
           this.setState({step2:{ ...this.state.step2, active:false }, step3:{ ...this.state.step3, active:true }})
        } ).catch( error =>{
            console.log(error)
        } )

        
    }

    renderWorkArea = () => {
        const { step1, step2, step3, step4, order } = this.state;
        console.log(step2)
        if(step1.active){
            return this.instructionsComponent()
        } else if(step2.active){
            return this.formActivationComponent()
        } else if(step3.active){
            return (<GeneratePDFComponent 
                        order={order} 
                        back={()=>this.setState({step2:{ ...this.state.step2, active:true }, step3:{ ...this.state.step3, active:false }})}
                        next={()=>this.setState({step4:{ ...this.state.step4, active:true }, step3:{ ...this.state.step3, active:false }})}
                        />)
        } else if(step4.active){
            return <AttachAgreement order={order}/>
        }
    }

    render(){
        const { step1, step2, step3, step4, orderReady, order } = this.state;
        console.log(order)
        if(!orderReady){
            return <Fragment>
                        <Container style={{marginTop:40, marginBottom:30, minHeight:'63vh'}}>
                            <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                                ENTREGA
                            </Header>
                            {this.SearchOrderComponent()}
                        </Container>
                    </Fragment>
        }
        return (
            <Fragment>
                <Container style={{marginTop:40, marginBottom:30, minHeight:'70vh'}}>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        ENTREGA
                        <Header.Subheader>
                            VIN: {this.state.order.form.vin}
                        </Header.Subheader>
                    </Header>
                    <StepComponent a={step1.active} b={step2.active} c={step3.active} d={step4.active}/>
                    {this.renderWorkArea()}
                </Container>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showComponent: (component)=>dispatch(showComponent(component)),
        messageToUser: (title, message, open)=> dispatch(MessageToUser(title, message, open))
    }
}

export default connect(null, mapDispatchToProps)(DeliveryScreen)