import React, { Component } from 'react';
import { Table, Label, Icon, Button } from 'semantic-ui-react';
import Format from 'react-currency-format';
export default class ItemModelTemplateComponent extends Component {
    render(){
        const {
            cl, 
            cod, 
            name, 
            vin, 
            supply, 
            mo, 
            mto5, 
            mto10, 
            mto15, 
            mto20, 
            mto25, 
            mto30, 
            mto35, 
            mto40, 
            mto45, 
            mto50,
            onClickEdit,
            onDelet
        } = this.props;
        
        
        return(
            <Table.Row>
                <Table.Cell>{cod}</Table.Cell>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{cl}</Table.Cell>
                <Table.Cell>{vin.map( (i, index)=>{
                    return (<Label key={index} size="mini">
                        {i.vin}
                        <Icon name='delete' />
                    </Label>)
                } )}</Table.Cell>
                <Table.Cell><Format thousandSeparator={true} displayType="text" value={supply}/></Table.Cell>
                <Table.Cell><Format thousandSeparator={true} displayType="text" value={mo} /></Table.Cell>
                <Table.Cell>{mto5}</Table.Cell>
                <Table.Cell>{mto10}</Table.Cell>
                <Table.Cell>{mto15}</Table.Cell>
                <Table.Cell>{mto20}</Table.Cell>
                <Table.Cell>{mto25}</Table.Cell>
                <Table.Cell>{mto30}</Table.Cell>
                <Table.Cell>{mto35}</Table.Cell>
                <Table.Cell>{mto40}</Table.Cell>
                <Table.Cell>{mto45}</Table.Cell>
                <Table.Cell>{mto50}</Table.Cell>
                <Table.Cell>
                    <Button.Group>
                        <Button onClick={onClickEdit} icon="edit" size='tiny' />
                        <Button onClick={onDelet} icon='trash' size='tiny'  />
                    </Button.Group>
                    
                </Table.Cell>
            </Table.Row>
        )
    }
}