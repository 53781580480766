import React, { Component } from 'react';
import { connect } from 'react-redux';
import imgBackGround from '../assets/background_login.jpg'; 

import { Grid, Form, Segment, Message, Button, Icon, Header } from 'semantic-ui-react';

import { FetchDatalogin } from '../actions';

class LoginScreen extends Component {

    onTextChange = (event, {name, value}) =>{
        this.setState({[name]: value})
    }
    

    render(){
        console.log(this.props.token)
        return (
            <Grid centered style={{minHeight:"85vh",  backgroundImage:`url(${imgBackGround})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} verticalAlign="middle">
                <Grid.Column style={{maxWidth:450}}>
                    <Form size='large'>
                        <Segment stacked >
                            <Header as="h1">ACCESO USUARIOS</Header>
                            <Form.Input label="Email" onChange={this.onTextChange} name="email" fluid icon="user" iconPosition="left" placeholder="E-mail address"/>
                            <Form.Input label="Contraseña" onChange={this.onTextChange} name="password" fluid icon="lock" iconPosition="left" placeholder="Password" type="password"/>
                        
                            <Button 
                                icon="sign-in"
                                content="INICIAR SESIÓN" 
                                size='small' 
                                style={{borderRadius:20, backgroundColor:'#BB162B', color:'#F6F6F6'}} 
                                onClick={()=>this.props.login(this.state)}/>
                        </Segment>
                        {this.props.token.data.message?<Message icon negative={!this.props.token.data.token} size="tiny">
                            <Icon name='key'/>
                            <Message.Content>
                                <Message.Header>Lo sentimos</Message.Header>
                                {this.props.token.data.message}
                            </Message.Content>
                        </Message>:null}
                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (state) => dispatch(FetchDatalogin(state))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)