import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import LoginScreen from './LoginScreen';
import HomeScreen from './LoggedIn/HomeScreen';

import { MessageToUser } from '../actions';
import { Modal, Button, Icon, ModalDescription } from 'semantic-ui-react';
import { SemanticToastContainer } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import PublicScreen from './Public/publicScreen';

class MainScreen extends Component {
    
    render(){

        if(this.props.token){
            return <Fragment>
                        <HomeScreen></HomeScreen>
                        <SemanticToastContainer position="bottom-right"/>
                        <Modal open={this.props.message.open}>
                            <Modal.Header>
                                {this.props.message.title}
                            </Modal.Header>
                            <Modal.Content>
                                {this.props.message.message}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={()=> this.props.messageToUser('', '', false)}><Icon name="times"/> Cerrar </Button>
                            </Modal.Actions>
                        </Modal>
                    </Fragment>
        } else {
            return (
                <PublicScreen></PublicScreen>
            )
        }
    }
}

const getToken = () => {
    return localStorage.getItem('token') 
}

const mapStateToProps = state => {
    return {
        token: state.token.data.token? state.token.data.token:getToken(),
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, message, open)=> dispatch(MessageToUser(title, message, open))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MainScreen)