import React, { Component, Fragment } from 'react';
import { Grid, Container, Header, Tab, Icon, Segment, Button, Modal } from 'semantic-ui-react';
import { getOrderById } from '../../../services/orders/orderService';
import { connect } from 'react-redux';
import { MessageToUser } from '../../../actions';

import AttachComponent from './order/attachComponent';
import StatusComponent from './order/StatusComponent';
import FormDataCustomer from '../../../components/Forms/FormDataCustomer';
import PackageScreen from './order/PackageScreen';
// import GeneratePDF from './order/GeneratePDFComponent';
import DeliveryScreen from './order/DeliveryScreen';

class WorkOnOrderScreen extends Component {
    constructor(props){
        super(props)
        this.state= { 
            order:{},
            commercialAction: false
        }
    }
    
    componentDidMount(){
        if(this.props.match.params.id){
            this.getDataOrder(this.props.match.params.id.toString())
        }
    }

    getDataOrder=(id)=>{
        let body={id:id}
        getOrderById(body)
        .then( (order)=>{
            if(order && order.adviser === 'IMPORTADOR'){ this.setState({ commercialAction: true })}
            this.setState({ order:order})
        }).catch( error=>{
            this.props.messageToUser('Lo sentimos', error.message, true)
        })
    }

    renderTab = ()=> {
        const { order } = this.state
        return [
            {
                
                menuItem:{ key:'forms', icon:'wordpress forms', content:'Formulario' },
                render: ()=><Tab.Pane attached={true}> 
                    <Fragment>
                        <FormDataCustomer form={order.form} order={order}/>
                        <br></br>
                        <PackageScreen order={order}/>
                    </Fragment>
                </Tab.Pane>
            },
            {
                menuItem:{ key:'package', icon:'box', content:'Rutinas' },
                render: ()=><Tab.Pane attached={true}> <PackageScreen order={order}/> </Tab.Pane>
            },
            {
                menuItem:{ key:'attach', icon:'attach', content:'Soportes' },
                render: ()=><Tab.Pane attached={true}> <AttachComponent order={order} /> </Tab.Pane>
            },
            {
                menuItem:{ key:'activation', icon:'play', content:'Activación' },
                render: ()=> <Tab.Pane attached={true}>
                                {order.status[2].active && !order.status[5].active ?
                                    <DeliveryScreen order={order}/>:
                                    <Segment placeholder>
                                        <Header icon>
                                        <Icon name='file alternate outline' />
                                            {order.status[5].active? "Ya está activo":"La activación no está disponible"}
                                            <Header.Subheader>
                                                {order.status[5].active?'No es necesaria ninguna acción':'Para activar por favor cargue los documentos requeridos, en la pestaña de soportes.'}
                                            </Header.Subheader>
                                        </Header>
                                        <Button style={{borderRadius:25, backgroundColor:'#7E8083', color:'#F6F6F6'}} onClick={ ()=> this.setState({ showMenssage:false }) } primary> OK </Button>
                                    </Segment>
                                }
                                
                                
                             </Tab.Pane>
            },
            {
                menuItem:{ key:'status', icon:'heartbeat', content:'Estatus' },
                render: ()=><Tab.Pane attached={true}> <StatusComponent order={order}/></Tab.Pane>
            }
        ]
    }

    notifyModal = () => {
        const { commercialAction } = this.state
        return <Modal open={commercialAction}>
            <Modal.Header>
                IMPORTANTE
            </Modal.Header>
            <Modal.Content>
                Este plan hace parte de una acción comercial.
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="ENTENDIDO"
                    icon="check"
                    onClick={()=> this.setState({ commercialAction: false})}
                    size='tiny'
                    style={{ borderRadius:25, backgroundColor:'#bb162b', color:"#f6f6f6" }} 
                />
            </Modal.Actions>
        </Modal>
    }

    render(){
        
        const { number } = this.state.order;
        return(
            <Grid style={{marginTop:'10vh', marginBottom:30, minHeight:"70vh"}}>
                <Container>
                        <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                            {/* <Icon name='file' /> */}
                            {number}
                            <Button floated='right' size='tiny' onClick={()=>this.getDataOrder(this.props.match.params.id.toString())} style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}>
                                <Icon name="refresh"/> ACTUALIZAR
                            </Button>
                        </Header>
                        
                    {/* </Segment> */}
                    <Tab panes={this.renderTab()}/>
                </Container>
                {this.notifyModal()}
            </Grid>
        )
    }
}

const mapStateToPorps = state => {
    return {
        orderId: state.orderSelectId.id
    }
}

const mapDispatchToProps = dispatch => {
    return{
        messageToUser: (title, message, open)=> dispatch(MessageToUser(title, message, open))
    }
}

export default connect(mapStateToPorps, mapDispatchToProps)(WorkOnOrderScreen)