import React, { Component } from 'react';
import { Form, Header, Grid, Button, Icon, Segment, Item, Modal, Placeholder } from 'semantic-ui-react';
import { upDateOrder } from '../../services/orders/orderService';
import PreloaderComponent from '../PreloaderComponent';
import { toast } from 'react-semantic-toasts';
import { Link } from 'react-router-dom';

const typeOptions = [
    { value:'CC', text:'Cédula de Ciudadania', key:1},
    { value:'CE', text:'Cédula de Extranjería', key:2},
    { value:'PASS', text:'Pasaporte', key:3},
]

export default class FormDataCustomer extends Component {
    constructor(props){
        super(props)
        this.state = { 
            loading:true, 
            order:{}, 
            openModalEdition:false,
            eName:'',
            eLastname:'',
            eTypeId:'',
            eNi:'',
            eAddress:'', 
            eCity:'', 
            eCountry:'',
            eEmail:'',
            eTelephone:''
        }
    }

    handleChange=(e, {name, value})=>{
        this.setState({[name]:value})
    }

    renderModalEdition=()=>{
        
        const { openModalEdition, eName, eLastname, eTypeId, eNi, eAddress, eCity, eCountry, eEmail,eTelephone } = this.state;
        // const { name, lastName, typeId, ni, address, city, country, email, telephone } = this.state.order.form;
        if(this.state.order.form){

            return (<Modal open={openModalEdition}>
                <Modal.Header><Icon color="red" name="edit"/> Editar información del Cliente</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={eName} name="eName" label='Nombre' />
                            <Form.Input onChange={this.handleChange} value={eLastname} name="eLastname" label='Apellido' />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Select options={typeOptions} onChange={this.handleChange} value={eTypeId} name="eTypeId" label='Tipo de Documento' />
                            <Form.Input onChange={this.handleChange} value={eNi} name="eNi" label='Numero de Identificación' />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={eAddress} name="eAddress" label='Dirección' />
                            <Form.Input onChange={this.handleChange} value={eCity} name="eCity" label='Ciudad' />
                            <Form.Input onChange={this.handleChange} value={eCountry} name="eCountry" label='País' />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Input onChange={this.handleChange} value={eEmail} name="eEmail" label='Email' />
                            <Form.Input onChange={this.handleChange} value={eTelephone} name="eTelephone" label='Teléfono' />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={()=>this.setState({openModalEdition:false})} />
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALZAR" 
                        icon="save" 
                        onClick={()=>this.upDateOrder()} 
                        />
                </Modal.Actions>
            </Modal>)
        } else {
            return (<Modal open={openModalEdition}>
                <Modal.Header><Icon name="edit"/> Editar información del Cliente</Modal.Header>
                <Modal.Content>
                    <Placeholder></Placeholder>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={()=>this.setState({openModalEdition:false})}><Icon name="times"/> Cancelar </Button>
                </Modal.Actions>
            </Modal>)
        }
        
                
    }

    upDateOrder = ()=>{
        const { eName, eLastname, eTypeId, eNi, eAddress, eCity, eCountry, eEmail, eTelephone } = this.state
        const order = this.props.order;
        let tempOrder = order;
        tempOrder.form.name = eName;
        tempOrder.form.lastName = eLastname;
        tempOrder.form.typeId = eTypeId;
        tempOrder.form.ni = eNi;
        tempOrder.form.address = eAddress;
        tempOrder.form.city = eCity;
        tempOrder.form.country = eCountry;
        tempOrder.form.email = eEmail;
        tempOrder.form.telephone = eTelephone;
        
        upDateOrder(tempOrder)
        .then( data =>{
            this.setState({openModalEdition:false})
            this.MessageToast('info', 'Operación exitosa', 'Los cambios se han guardado', 'database')
        }).catch( error =>{
            this.MessageToast('danger', 'Lo sentimos', 'Ocurrió un error al guardar los cambios', 'ban')
            console.log(error)
        })
    }

    MessageToast = (type, title, message, icon) =>{
        toast({
            type: type,
            icon: icon,
            title: title,
            description: message,
            animation: 'bounce',
            time: 5000,
        })
    }
    
    render(){
        
        if(!this.props.form){
            return <PreloaderComponent/>
        }
        
        const { name, lastName, typeId, ni, address, city, cityDealer, countryDealer, country, email, telephone, modelCod, modelColor, modelVersion, modelYear, dealerName, vin, plate } = this.props.form;
        
        return(
            <div>
                <Segment color="red">
                    <Header>
                        <Header.Subheader>Información del Plan</Header.Subheader>
                    </Header>
                    <Grid columns={2} stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Item.Group>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{dealerName}</Item.Header>
                                            <Item.Meta>Distribuidor de venta</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{modelVersion}</Item.Header>
                                            <Item.Meta>Versión</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{modelColor}</Item.Header>
                                            <Item.Meta>Color</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Column>
                            <Grid.Column>
                                <Item.Group>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{cityDealer}, {countryDealer}</Item.Header>
                                            <Item.Meta>Ciudad de venta</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{modelCod} - {vin} - {plate}</Item.Header>
                                            <Item.Meta>Código Modelo - VIN - Placa</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{modelYear}</Item.Header>
                                            <Item.Meta>Año modelo</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment color="red">
                    <Header>
                        <Header.Subheader>Información del Cliente</Header.Subheader>
                    </Header>
                    <Grid columns={2} stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Item.Group>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{name} {lastName}</Item.Header>
                                            <Item.Meta>Nombre y apellido</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{address}</Item.Header>
                                            <Item.Meta>Dirección</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{email}</Item.Header>
                                            <Item.Meta>Email</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                                <Button 
                                    size="tiny"
                                    content="EDITAR"
                                    icon="edit"
                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25 }}
                                    onClick={()=>this.setState({ 
                                        order:this.props.order, 
                                        eName:name, 
                                        eAddress:address, 
                                        eCity:city, 
                                        eCountry:country, 
                                        eLastname:lastName, 
                                        eEmail:email, 
                                        eTelephone:telephone, 
                                        eTypeId:typeId, eNi:ni, 
                                        openModalEdition:true})}/>
                                {/* <Button 
                                    to={'../https://google.com'}
                                    as={Link} 
                                    size="tiny"
                                    icon="file outline"
                                    style={{ borderRadius:25 }}
                                    content="VER FORMULARIO" /> */}
                            </Grid.Column>
                            <Grid.Column>
                            <Item.Group>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{typeId} {ni}</Item.Header>
                                            <Item.Meta>Tipo e Identificación</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{city} - {country}</Item.Header>
                                            <Item.Meta>Ciudad y País</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{telephone}</Item.Header>
                                            <Item.Meta>Teléfono</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {this.renderModalEdition()}
            </div>
            
        )
    }
}