import { getApi } from '../../utils/Constants';

const api = getApi();
const apiModule = 'prepaidMant/prepaidTemplate/';
const token = JSON.stringify(localStorage.getItem('token'));

async function create(body){
    return await fetch(api+apiModule+'create', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            mto: body.mto, 
            offPartsImp: body.offPartsImp, 
            offPartsCon: body.offPartsCon, 
            offPartsTotal: body.offPartsTotal, 
            hours: body.hours
        })
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function getPrepaidTemplates(){
    return await fetch(api+apiModule+'getPrepaidTemplates', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        }
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function updatePrepaidTemplates(body){
    return await fetch(api+apiModule+'updatePrepaidTemplates',{
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            id: body.editId,
            mto: body.editMto,
            offPartsImp: body.editOffPartsImp, 
            offPartsCon: body.editOffPartsCon, 
            offPartsTotal: parseFloat(body.editOffPartsCon) + parseFloat(body.editOffPartsImp), 
            hours: body.editHours,
            active: body.editActive
        })
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function getTemplateForSimulator(){
    return await fetch(api+apiModule+'getTemplateForSimulator', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        }
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

export {
    create,
    getPrepaidTemplates,
    updatePrepaidTemplates,
    getTemplateForSimulator
}