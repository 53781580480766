import React, { Component } from 'react';
import { Label, Icon } from 'semantic-ui-react';

class IconStatusRedeem extends Component {
    render(){

        const { status } = this.props
        switch(status){
            case 'noRedeem':
                return (
                    
                    <Label style={{borderRadius:25, backgroundColor:'#666666', color:'#F6F6F6'}}> <Icon name="box" /> Pendiente</Label>
                )
            case 'redeem':
                return (
                    
                    <Label style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}}> <Icon name="check circle" /> Redimido</Label>
                )
            case 'expired':
                return (
                    
                    <Label style={{borderRadius:25, backgroundColor:'#FFA500', color:'#F6F6F6'}}> <Icon name="ban" /> Expirado</Label>

                )
            case 'returned':
                return(
                    <Label style={{borderRadius:25, backgroundColor:'#F6F6F6', color:'#454545'}}> <Icon name="alarm" /> Retornado</Label>
                    
                )
            default: 
                return (<Label style={{borderRadius:25, backgroundColor:'#F6F6F6', color:'#454545'}}> <Icon name="minus" /> Sin estado</Label>)
        }
    }
}

export default IconStatusRedeem