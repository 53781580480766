import { getApi } from '../../utils/Constants';
const api = getApi();
const path = '/prepaidMant/commisionController/'

const token = localStorage.getItem('token');

async function create ( body ){
    return await fetch( api + path + 'create', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            routine: body.routine,
            value: body.value,
            bono: body.bono
        })
    }).then( response => response.json() )
    .then( success => {
        return success
    } ).catch( error => {
        return error
    })
}

async function read ( body ){
    return await fetch( api + path + 'read', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer ' + token
        }
    }).then( response => response.json() )
    .then( success => {
        return success
    } ).catch( error => {
        return error
    })
}

async function update ( body ){
    return await fetch( api + path + 'update', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            routine: body.routine,
            value: body.value,
            bono: body.bono,
            _id: body._id
        })
    }).then( response => response.json() )
    .then( success => {
        return success
    } ).catch( error => {
        return error
    })
}

export {
    create,
    read,
    update
}