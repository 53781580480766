import React, { Component, Fragment } from 'react';
import { Grid, Container, Header, Message, Button, Icon, Modal, Form, Table, Label } from 'semantic-ui-react';
import { MessageToUser} from '../../../../actions'
import { connect } from 'react-redux'
import { getDealers, newDealers, editDealer } from '../../../../services/dealers/dealersService';
import PreloaderComponent from '../../../../components/PreloaderComponent';

const intialDealer = {
    _id:'',
    dealer_cod:'',
    name_dealer:'',
    subname_dealer:'',
    address:'',
    city:'',
    group_dealer:'',
    active: true,
    type_dealer:'S3'
}
class Dealers extends Component {
    state = {
        openModal: false,
        actionModal:'',
        dealers:[],
        loadingDealers:false,
        dealer:intialDealer
    }

    getAllDealers = () => {
        this.setState({ loadingDealers: true })
        getDealers()
        .then( result => {
            console.log(result)
            this.setState({
                dealers: result,
                loadingDealers:false
            })
        } ).catch( error  => {
            this.setState({
                dealers: [],
                loadingDealers:false
            })
        })
    }

    createDealer = ( )=> {
        const { dealer } = this.state;
        newDealers( dealer )
        .then( result => {

            if(result.message) return this.props.messageToUser('Error', result.message, true)
            this.setState({openModal:false, actionModal:'' })
            this.getAllDealers() 
        } )
    }

    editDealerId = () => {
        const { dealer } = this.state;
        dealer.type_dealer = 'S3'
        editDealer( dealer )
        .then( result => {
            if(result.message) return this.props.messageToUser('Error', result.message, true)
            
            this.setState({openModal:false, actionModal:'' })
            this.getAllDealers() 
        } )
    }

    componentDidMount(){
        this.getAllDealers()
    }

    renderDealers = () => {
        const { dealers, loadingDealers } = this.state;
        if(loadingDealers) return <PreloaderComponent/>
        if(!dealers.dealers || dealers.dealers.length === 0 ) return <p>No hay distribuidores</p>
        return <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>  CL </Table.HeaderCell>
                    <Table.HeaderCell>  NOMBRE </Table.HeaderCell>
                    <Table.HeaderCell>  ABREVIATURA </Table.HeaderCell>
                    <Table.HeaderCell>  DIRECCION </Table.HeaderCell>
                    <Table.HeaderCell>  CIUDAD </Table.HeaderCell>
                    <Table.HeaderCell>  GRUPO </Table.HeaderCell>
                    <Table.HeaderCell>  ACTIVO </Table.HeaderCell>
                    <Table.HeaderCell>   </Table.HeaderCell>
                </Table.Row>
                
            </Table.Header>
            <Table.Body>
                {dealers.dealers.map( (i)=>(
                    <Table.Row key={i._id}>
                        <Table.Cell>{i.dealer_cod}</Table.Cell>
                        <Table.Cell>{i.name_dealer}</Table.Cell>
                        <Table.Cell>{i.subname_dealer}</Table.Cell>
                        <Table.Cell>{i.address}</Table.Cell>
                        <Table.Cell>{i.city}</Table.Cell>
                        <Table.Cell>{i.group_dealer}</Table.Cell>
                        <Table.Cell>
                            <Label size='tiny' as='a' color={i.active?'green':'grey'} tag>
                                {i.active? 'ACTIVO':'SUSPENDIDO'}
                            </Label>
                        </Table.Cell>
                        <Table.Cell>
                            <Button 
                                onClick={()=> this.setState({ actionModal:'edit', openModal:true, dealer:i })} 
                                content='EDITAR' 
                                icon='edit' 
                                size='tiny' 
                                style={{borderRadius:25}}  />
                        </Table.Cell>
                    </Table.Row>
                ) )}
            </Table.Body>
        </Table>
    }

    handleChange = ( e , { name, value} ) => {
        const { dealer } = this.state
        this.setState({
            dealer:{
                ...dealer,
                [name]: value
            }
        })
    }

    renderModalDealer = () => {
        const { openModal, actionModal, dealer } = this.state;

        return <Modal open={openModal}>
            <Modal.Header>
                {actionModal === 'edit'? 'Editar Distribuidor':'Crear Nuevo distribuidor'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} value={dealer.dealer_cod} name='dealer_cod' label='CL' />
                        <Form.Input onChange={this.handleChange} value={dealer.name_dealer} name='name_dealer' label='NOMBRE' />
                        <Form.Input onChange={this.handleChange} value={dealer.subname_dealer} name='subname_dealer' label='ABREVIATURA' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} value={dealer.address} name='address' label='DIRECCION' />
                        <Form.Input onChange={this.handleChange} value={dealer.city} name='city' label='CIUDAD' />
                        <Form.Input onChange={this.handleChange} value={dealer.group_dealer} name='group_dealer' label='GRUPO' />
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={()=> this.setState({ dealer:{ ...dealer, active: !dealer.active } })} content={dealer.active?'SUSPENDER':'ACTIVAR'}/>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    content='CANCELAR'
                    icon='times'
                    onClick={()=> this.setState({openModal:false, actionModal:'' }) } 
                    size="mini" 
                    style={{ borderRadius:25}} 
                    />
                {actionModal === 'edit'
                ?<Button 
                    content='GUARDAR CAMBIOS'
                    icon='save'
                    onClick={()=> this.editDealerId()  } 
                    size="mini" 
                    style={{ borderRadius:25}} 
                    />
                :<Button 
                    content='GUARDAR'
                    icon='save'
                    onClick={()=> this.createDealer() } 
                    size="mini" 
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                    />}
            </Modal.Actions>
        </Modal>
    }

    render(){
        return (<Fragment>
            <Grid style={{ minHeight:'80vh', marginTop:'7vh', marginBottom:50, marginLeft:50, marginRight:50 }}>
                <Container fluid>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        DISTRIBUIDORES
                        <span>
                            <Button 
                                onClick={()=> this.setState({openModal:true, actionModal:'crear', dealer:intialDealer }) } 
                                size="mini" 
                                style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                                floated='right'><Icon name="add"/> DISTRIBUIDOR </Button>
                        </span>
                    </Header>
                    {this.renderDealers()}
                    
                </Container>
            </Grid>
            {this.renderModalDealer()}
            
        </Fragment>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, msg, open) => dispatch(MessageToUser(title, msg, open))
    }
}

export default connect(null, mapDispatchToProps)(Dealers)