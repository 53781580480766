import React, { Component } from 'react';
import { Grid, Container, Segment, Header, Image, Card, Button, Icon, Label, Form, Modal, List, Search, Popup } from 'semantic-ui-react';
import { getVariables } from '../../../services/variables/variblesService';
import { getAvailableVersion } from '../../../services/vins/vinServices';
import { getProjection } from '../../../services/simulator/simulatorService';
import { connect } from 'react-redux';
import PreloaderComponent from '../../../components/PreloaderComponent';
import { getTransmissionOption, entryCar, getUrlFrontend } from '../../../utils/Constants';
import { addPackageToOrder } from '../../../actions';
import _ from 'lodash';
import r10 from '../../../assets/routines/10.jpg'
import r20 from '../../../assets/routines/20.jpg'
import r30 from '../../../assets/routines/30.jpg'
import r40 from '../../../assets/routines/40.jpg'
import r50 from '../../../assets/routines/50.jpg'
const CurrencyFormat = require('react-currency-format')

class SimulatorScreen extends Component {
    constructor(props){
        super(props)
        this.state = { 
            model:{}, 
            transmission:'', 
            urlImageSelect:'', 
            url1ImageSelect:'',
            url2ImageSelect:'',
            url3ImageSelect:'',
            modelSelect:false, 
            transmissionSelect:false, 
            transmissions:[], 
            models:[], 
            loading:true, 
            versions:[],
            saveVersions:[],
            loadingVersion:false,
            dealerSelect:'',
            dealerOptions:[],
            versionSelect:'',
            projection:[],
            isVersionSelect:false,
            loadingProjection:false,
            routineSelect:'',
            openModalInfo:false,
            value:'',
            isLoading:false,
            results: []
         }
    }
    componentDidMount(){
        this.setState({loading:true, transmissions:getTransmissionOption()})
        let temporalModels = []
        getVariables()
        .then( (data)=>{
            data.map((i)=>{
                 if(i.name.length === 2){
                    temporalModels.push({
                        id:i._id,
                        modelLine:i.name,
                        transmission:'',
                        description:i.description,
                        url:`${getUrlFrontend()}models/${i.name}.jpg`,
                        url1:`${getUrlFrontend()}models/1/${i.name}.jpg`,
                        url2:`${getUrlFrontend()}models/2/${i.name}.jpg`,
                        url3:`${getUrlFrontend()}models/3/${i.name}.jpg`
                    })
                } else {
                    return null
                }
            })
            this.setState({models:temporalModels, loading:false});
            return true;
        } ).catch(error=>{
            // console.log(error)
        })
        this.renderSelectDealer()
    }

    renderModels = ()=> {
        const { models } = this.state;
        
        if(models && models.length>0){
            return <Grid columns={3} stackable >
                {models.map( (i, index)=>{
                    return (
                        <Grid.Column key={i.id} style={{position:'relative'}}>
                            <Image size="huge"  src={i.url} as='a' alt={i.url} />
                            <Button
                                size='tiny' 
                                onClick={()=>{ 
                                    this.setState({ 
                                        model:i, 
                                        urlImageSelect:i.url, 
                                        url1ImageSelect:i.url1, 
                                        url2ImageSelect:i.url2, 
                                        url3ImageSelect:i.url3, 
                                        modelSelect:true }, ()=>{ 
                                            this.renderEntryValues()
                                        }); }} 
                                inverted 
                                style={{ 
                                    color:"#F6F6F6",  
                                    position:'absolute', 
                                    bottom:30, 
                                    left:30, 
                                    borderRadius:25, 
                                    backgroundColor:"transparent", 
                                    borderColor:"#F6F6F6 !important"}}
                                    content='SELECCIONAR'
                                    icon="check circle outline"
                                     />
                        </Grid.Column>
                    )
                })}
            </Grid>
        }
    }

    renderTransmissions = () => {
        const { transmissions, url1ImageSelect, projection } = this.state;
        return <Grid columns={16} stackable>
        <Grid.Row  style={{height:"70vh", backgroundImage:`url(${url1ImageSelect})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
            <Segment basic textAlign='left'>
                <h1 style={{color:'#F6F6F6', fontWeight:'bold', fontSize:'4rem', textShadow:'4px 1px 4px rgba(2,2,2,0.99)'}}> <span style={{color:"#bb162b"}}>K</span>  Plus</h1>
                {projection && projection.length>0?<Header size='largue' as="h1" style={{color:'#F6F6F6', fontWeight:'bold', fontFamily:'KiaBold', textShadow:'4px 1px 4px rgba(2,2,2,0.99)'}}>
                    <Header.Content>
                        Desde <CurrencyFormat value={projection[1].salesPrice} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> COP <br/>
                        <Header.Subheader style={{color:'#F6F6F6', textShadow:'4px 1px 4px rgba(2,2,2,0.99)', fontFamily:'KiaMedium'}}>
                            Elige el tipo de transmisión
                        </Header.Subheader>
                    </Header.Content>
                </Header>:null}     
                {transmissions.map( (i)=>{
                    if(i.value!=null){
                        return <Popup content={i.value==='M'?'Mecánico':'Automático'} key={i.value} trigger={
                                <Icon 
                                    link 
                                    toltip
                                    onClick={()=>this.setState({ transmission:i.value,  transmissionSelect:true }, ()=>{ this.getAvailableVersion() })} 
                                    circular name={i.value==='M'?'settings':'setting'} 
                                    size='large' 
                                    style={{backgroundColor:'#666666', color:'#F6F6F6', marginRight:15}} />
                            }></Popup> 
                    } else {
                        return null
                    }
                } )}                     
            </Segment>
        </Grid.Row>
        <Grid.Row centered style={{backgroundColor:"#F6F6F6"}}>
            {transmissions.map( (i, index)=>{
                if(i.value!==null){
                    return <Grid.Column width={3} key={i.key} textAlign="center" style={{ minHeight:"15vh", backgroundColor:"#F6F6F6", }} verticalAlign="middle">
                        <Header as="a" onClick={()=>this.setState({ transmission:i.value,  transmissionSelect:true }, ()=>{ this.getAvailableVersion() })} icon style={{marginTop:40, color:"#F6F6F6", cursor:'pointer' }} >
                            <Icon.Group>
                                {i.value!==null? 
                                    <Icon name={i.value==='M'?'settings':'setting'} style={{color:"#58595B"}} />:
                                    <Icon name='car' style={{color:"#58595B"}} />}
                                <Icon corner='bottom right' style={{color:"#BB162B", fontSize:18}} name='car' />
                            </Icon.Group>
                            
                            <Header.Content style={{color:"#58595B"}}>
                                <Header.Subheader style={{color:"#58595B"}}>
                                    {i.text}
                                </Header.Subheader>
                                Transmisión. {i.value}
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                }
                
            } )}
        </Grid.Row>
    </Grid>
    }

    // when model is selected
    renderEntryValues=()=>{
        const { model } = this.state;
        entryCar.map( (i)=>{
             if(i.model === model.modelLine){
                 this.setState({versionSelect:i.version, dealerSelect:'CL001'}, ()=>{
                    this.getProjection()
                 })
                 
             } else {
                 return null
             }
        })
    }

    getAvailableVersion=()=>{
        const { model, transmission} = this.state;
        this.setState({loadingVersion:true})
        getAvailableVersion({modelLine:model.modelLine, transmission:transmission})
        .then( (data)=>{
            this.setState({versions:data, saveVersions:data, loadingVersion:false})
        } ).catch( error=> {
            this.setState({loadingVersion:false})
            // console.log(error)
        })
    }

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.title })
        const { versions, value, saveVersions } = this.state;
        let tempVersion = versions;
        let results = []
        if(value){
            results = tempVersion.filter( (i)=>{
                if(i.article === result.title){
                    return i
                } else {
                    return null
                }
            } )
            this.setState({versions:results})
        } else {
            this.setState({versions:saveVersions})
        }

        // console.log('resultados')
        // console.log(results)

        
    }

    handleSearchChange = (e, { value }) => {
        const { versions } = this.state;
        let searchObject = []
        versions.map( (i)=>{
            return searchObject.push({
                title:i.article,
                description:i.information.description.aa+' '+i.information.description.displacement+' '+i.information.description.motor+' '+i.information.description.package
            })
        })
        this.setState({ isLoading: true, value })

        setTimeout(() => {
        if (this.state.value.length < 1) return this.setState({value:'', versions:this.state.saveVersions, isLoading:false, results: []})

        const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
        const isMatch = (result) => re.test(result.title)

        this.setState({
            isLoading: false,
            results: _.filter(searchObject, isMatch),
        })
        }, 300)
    }

    renderVersions = () => {
        const { versions, url2ImageSelect,isLoading, results, value } = this.state;
        
        if(versions && versions.length>0){
            return <Grid stackable  columns={16} style={{marginTop:20}}>
                <Grid.Row verticalAlign='bottom'  style={{height:"70vh", width:'100v', backgroundImage:`url(${url2ImageSelect})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                    
                <Segment basic textAlign='left'>
                    <h1 style={{color:'#F6F6F6', fontWeight:'bold', fontSize:'4rem', textShadow:'4px 1px 4px rgba(2,2,2,0.99)'}}> <span style={{color:"#bb162b"}}>K</span>  Plus</h1>
                    <Header size='largue' as="h1" style={{color:'#F6F6F6', fontWeight:'bold', fontFamily:'KiaBold', textShadow:'4px 1px 4px rgba(2,2,2,0.99)'}}>
                        <Header.Content>
                            {/* Desde <CurrencyFormat value={projection[1].salesPrice?projection[1].salesPrice:0} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> COP <br/> */}
                            <Header.Subheader style={{color:'#F6F6F6', textShadow:'4px 1px 4px rgba(2,2,2,0.99)', fontFamily:'KiaMedium'}}>
                                Elige la versión del modelo
                            </Header.Subheader>
                        </Header.Content>
                    </Header>              
                </Segment>



                </Grid.Row>
                <Grid.Row centered={true} verticalAlign='middle' style={{minHeight:"10vh", paddingTop:"3vh"}}>
                    <Search
                        loading={isLoading}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true, })}
                        results={results}
                        value={value}
                        {...this.props}
                    />
                </Grid.Row>
                <Grid.Row divided centered style={{backgroundColor:"#F6F6F6"}}>
                    {versions.map( (i, index)=> {
                        return <Grid.Column width={4} key={i.article+index} textAlign="center" style={{  backgroundColor:"#F6F6F6", padding:30}} verticalAlign="middle">
                            <Header>
                                {i.article}
                                <Header.Content>
                                    <Header.Subheader>
                                        <List divided horizontal size="mini">
                                            <List.Item>{i.information.description.motor}</List.Item>
                                            <List.Item>{i.information.description.displacement}</List.Item>
                                            <List.Item>{i.information.description.aa}</List.Item>
                                            <List.Item>{i.information.description.carBody}</List.Item>
                                            <List.Item>{i.information.description.transmission}</List.Item>
                                            <List.Item>{i.information.description.package}</List.Item>
                                        </List>
                                    </Header.Subheader>
                                </Header.Content>
                            </Header>
                            <Button onClick={()=>this.setState({versionSelect:i.article, isVersionSelect:true}, ()=>{this.getProjection()})} size='mini' style={{borderRadius:25}}>
                                SELECCIONAR
                            </Button>
                        </Grid.Column>
                    } )}
                </Grid.Row>
            </Grid>
        } else {
            return <Segment placeholder style={{marginTop:20}} raised>
                        <Header icon>
                            <Icon name='car' />
                            No hay versiones disponibles con estas características para este vehículo
                            <Header.Subheader>
                                Puede buscar nuevamente haciendo click sobre el boton "Volver a buscar"
                            </Header.Subheader>
                        </Header>
                        <Button onClick={()=>{ this.setState({model:{}, 
                            transmission:'', 
                            urlImageSelect:'', 
                            modelSelect:false, 
                            transmissionSelect:false}) }}><Icon name="arrow left"/> Volver a buscar </Button>
                    </Segment>
        }
    }

    getProjection = ()=>{
        this.setState({loadingProjection:true})
        const { dealerSelect, versionSelect } = this.state;
        getProjection({version:versionSelect, cl:dealerSelect})
        .then( (data)=>{
            
            this.setState({projection:data, loadingProjection:false})
            console.log(data)
        } ).catch(error=>{
            this.setState({loadingProjection:false})
        })
    }

    renderSelectDealer = ()=> {
        const { dealer } = this.props.user;
        let selectOptions = []
        if(dealer && dealer.length>0){
            dealer.sort();
            dealer.map((i, index)=>{
                return selectOptions.push({
                    key:index,
                    text:i,
                    value:i
                })
            })
            this.setState({dealerOptions:selectOptions, dealerSelect:selectOptions[0].value});
        }
    }

    changeForm = (e, {name, value})=>{
        this.setState({[name]:value})
    }

    renderCardOffert = () => {
        const { projection, url3ImageSelect, versionSelect } = this.state;
        
        if(projection && projection.length>0){
            
            return (
                    <Grid stackable columns={16} style={{marginTop:20}}>
                        <Grid.Column width={16} textAlign='right'>
                            *Versión seleccionada: <strong>{versionSelect}</strong>
                        </Grid.Column>
                        <Grid.Column width={16} style={{minHeight:"73.8vh", maxHeight:"100vh", backgroundImage:`url(${url3ImageSelect})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center',}}>
                            <Segment basic textAlign='left'>
                                <h1 style={{color:'#F6F6F6', fontWeight:'bold', fontSize:'4rem', textShadow:'4px 1px 4px rgba(2,2,2,0.99)'}}><span style={{color:"#bb162b"}}>K</span> Plus </h1>
                                <Header size='largue' as="h1" style={{color:'#F6F6F6', fontWeight:'bold', fontFamily:'KiaBold', textShadow:'4px 1px 4px rgba(2,2,2,0.99)'}}>
                                    <Header.Content>
                                        {projection[9].routine} K
                                        <Header.Subheader style={{color:'#F6F6F6', textShadow:'4px 1px 4px rgba(2,2,2,0.99)'}}>
                                            INVERSIÓN K PLUS: <CurrencyFormat value={projection[9].salesPrice} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> <br/>
                                            INVERSIÓN NORMAL: <CurrencyFormat value={projection[9].accumulated} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> <br/>
                                            AHORRO: <CurrencyFormat value={100-((projection[9].salesPrice/projection[9].accumulated)*100)} thousandSeparator={true} suffix={'%'} displayType={'text'} decimalScale={0}></CurrencyFormat>
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                                <Button onClick={()=>this.setState({routineSelect:projection[9].routine, openModalInfo:true })} size='mini' style={{borderRadius:25}}>INFO</Button>
                                <Button onClick={()=>this.props.addpackagetoorder(projection[9], projection, versionSelect)} size='mini' style={{borderRadius:25}}>
                                    SELECCIONAR
                                </Button>                                
                            </Segment>
                        </Grid.Column>

                        
                            {/* {projection.map( (i, index)=>{
                                if(i.routine === 5) return null
                                return <Grid.Column computer={4} mobile={16} verticalAlign="middle" textAlign='center' style={{backgroundColor:"#F6F6F6"}}>
                                            <Card fluid>
                                                <Card.Content>
                                                <Card.Header style={{fontWeight:'bold', color:"#bb162b"}}>
                                                    <h1>{i.routine} K</h1>
                                                </Card.Header>
                                                
                                                <Card.Description style={{fontFamily:'KiaBold'}}>
                                                    K Plus <CurrencyFormat value={i.salesPrice} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> <br/>
                                                    Normal <CurrencyFormat value={i.accumulated} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> <br/>
                                                    Ahorro <CurrencyFormat value={100-((i.salesPrice/i.accumulated)*100)} thousandSeparator={true} suffix={'%'} displayType={'text'} decimalScale={0}></CurrencyFormat>
                                                

                                                </Card.Description>
                                                </Card.Content>
                                                <Card.Content extra>
                                                    <Button onClick={()=>this.setState({routineSelect:i.routine, openModalInfo:true })} content="INFO" size='mini'  style={{borderRadius:25}} />
                                                    <Button onClick={()=>this.props.addpackagetoorder(i, projection, versionSelect)} content="SELECCIONAR" size='mini'  style={{borderRadius:25, backgroundColor:"#bb162b", color:"#f6f6f6"}} />
                                                </Card.Content>
                                            </Card>
                                        </Grid.Column>
                            } )} */}
                        <Grid.Row divided centered style={{backgroundColor:"#F6F6F6"}}>
                            {projection.map( (i, index)=> {
                                return <Grid.Column width={4} key={i.routine+index} textAlign="center" style={{  backgroundColor:"#F6F6F6", padding:30}} verticalAlign="middle">
                                    <Header>
                                        <h1 style={{fontWeight:'bold', color:"#bb162b"}}>{i.routine} K</h1>
                                        <Header.Content>
                                            <Header.Subheader>
                                                K Plus <CurrencyFormat value={i.salesPrice} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> <br/>
                                                Normal <CurrencyFormat value={i.accumulated} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> <br/>
                                                Ahorro <CurrencyFormat value={100-((i.salesPrice/i.accumulated)*100)} thousandSeparator={true} suffix={'%'} displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    <Button onClick={()=>this.setState({routineSelect:i.routine, openModalInfo:true })} content="INFO" size='mini'  style={{borderRadius:25}} />
                                    <Button onClick={()=>this.props.addpackagetoorder(i, projection, versionSelect)} content="SELECCIONAR" size='mini'  style={{borderRadius:25, backgroundColor:"#bb162b", color:"#f6f6f6"}} />
                                </Grid.Column>
                            } )}
                        </Grid.Row>
                    </Grid>
                    )
        } else {
            return <Segment placeholder style={{marginTop:20}} raised>
                        <Header icon>
                            <Icon name='car' />
                            Algunos de los elementos necesarios para realizar la proyección aún no están disponibles
                            <Header.Subheader>
                                Por favor revise si los elementos para la realizar la proyección se han creado ya, para ello puede contactar a un administrador
                            </Header.Subheader>
                        </Header>
                        <Button onClick={()=>{ this.setState({model:{}, 
                            transmission:'', 
                            urlImageSelect:'', 
                            modelSelect:false, 
                            transmissionSelect:false}) }}><Icon name="arrow left"/> Volver a buscar </Button>
                    </Segment>
        }
        
    }

    topMulti = (value)=>{
        let mod = value % 10000;
        if(mod > 0){
            return (parseInt((value / 10000)) + 1) * 10000
        } else {
            return value;
        }
    }

    modalInfo = ()=>{
        const { projection, routineSelect, openModalInfo } = this.state;
        let routineImage = ''
        switch(routineSelect){
            case 10: routineImage = r10; break;
            case 20: routineImage = r20; break;
            case 30: routineImage = r30; break;
            case 40: routineImage = r40; break;
            case 50: routineImage = r50; break;
            default: routineImage = r10;
        }
        if(projection && projection.length>0){
            return <Modal open={openModalInfo} size='large'>
                        <Modal.Header style={{fontFamily:'KiaBold', color:'#333333'}}>
                            <span className="con_tit">PAQUETE {routineSelect} K</span>
                        </Modal.Header>
                        <Modal.Content>
                            <Image fluid src={routineImage}></Image>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button 
                                icon="download"
                                content="DESCARGAR"
                                style={{borderRadius:25}} 
                                size="tiny"
                                as='a' href={routineImage} 
                                target='_blank' />
                            <Button 
                                icon="times"
                                content="CERRAR"
                                style={{borderRadius:25}} 
                                size="tiny"
                                onClick={()=>this.setState({openModalInfo:false})} />
                        </Modal.Actions>
                    </Modal>
        } else {
            // console.log('nothing')
        }
        
    }


    render(){
        
        const { loading, loadingProjection, modelSelect, transmissionSelect, loadingVersion, dealerOptions,  dealerSelect, isVersionSelect} = this.state;
        
        if(loading || loadingVersion || loadingProjection){
            return <PreloaderComponent></PreloaderComponent>
        }
        return (
            <Grid style={{marginTop:"8vh", marginBottom:50 }}>
                <Container>
                    
                        <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                            COTIZADOR <Label><Icon name="building outline"/>{dealerSelect}</Label>
                            <span>
                                {modelSelect?<Button size="mini" style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} floated='right' onClick={()=>{ this.setState({model:{}, 
                                    transmission:'', 
                                    urlImageSelect:'', 
                                    modelSelect:false, 
                                    transmissionSelect:false,
                                    projection:[],
                                    versionSelect:'',
                                    isVersionSelect:false}) }}><Icon name="arrow left"/> Volver </Button>:null}
                            </span>
                        </Header>
                        
                        {this.props.user.role ==='manager'?<Form>
                            <Form.Group>
                                <Form.Select label="Distribuidor" value={dealerSelect} onChange={this.changeForm} name="dealerSelect" options={dealerOptions}></Form.Select>
                            </Form.Group>
                        </Form>:null}
                        
                        <br></br>

                        {!modelSelect?this.renderModels():null}

                        {!transmissionSelect && modelSelect? this.renderTransmissions() : null}

                        {transmissionSelect && modelSelect && !isVersionSelect? this.renderVersions():null}

                        {transmissionSelect && modelSelect && isVersionSelect? this.renderCardOffert():null}

                    
                    
                    {this.modalInfo()}
                </Container>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data,
        order: state.order
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addpackagetoorder: (plan, projection, version) => dispatch(addPackageToOrder(plan, projection, version))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SimulatorScreen)