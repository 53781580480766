import React, { Component } from 'react';
import { Grid, Container, Header, Segment, Form, Button, Icon,  } from 'semantic-ui-react';
import { getToRedeem } from '../../../services/rendetion';
import Preloader from '../../../components/PreloaderComponent';
import { connect } from 'react-redux';
import { MessageToUser } from '../../../actions';
import { getUrlFrontend } from '../../../utils/Constants';

class RedentionScreen extends Component {
    
    state = { vinSearched:'', loading:false }
    
    componentDidMount(){
        console.log("this.props")
        console.log(this.props)
    }

    handleChange = (e) => {
        if(e.target.value && e.target.value.length>0){
            this.setState({vinSearched:e.target.value.toUpperCase()}, ()=>{
                console.log(this.state.vinSearched)
            })
        } else {
            this.setState({vinSearched:e.target.value }, ()=>{
                console.log(this.state.vinSearched)
            })
        }
    }

    searchToActive=()=>{
        this.setState({loading:true})
        const { vinSearched } = this.state;
        
        getToRedeem(vinSearched)
        .then( (data)=>{
            console.log(data);
            setTimeout( ()=>{
                this.setState({loading:false})
                if(data.find){
                    window.open(`${getUrlFrontend()}#/redention/plan/${data.data}`)
                }else{
                    this.props.messageToUser('No se encontró información', data.message, true)
                }
                
            },2000 )
        }, error =>{
            this.props.messageToUser('Ocurrió un error', error.message, true)
        } )
        
    }

    render(){
        const { vinSearched, loading } = this.state;
        return(
            <Grid style={{minHeight:'80vh', marginTop:'8vh'}}>
                <Container style={{marginTop:40, marginBottom:30, minHeight:'63vh'}}>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        REDENCIÓN MANTENIMIENTOS
                    </Header>
                    <Segment basic style={{minHeight:'60vh'}}>
                        <Container textAlign='center' style={{paddingTop:'20vh'}}>
                            <Header>Vin o Placa del vehículo</Header>
                            <Form >
                                <Form.Field>
                                    <input onChange={this.handleChange} maxLength="17" minLength="6" value={vinSearched} style={{borderRadius:25, maxWidth:300, textAlign:'center'}} placeholder='VIN'></input>
                                    {vinSearched?<span style={{fontSize:10}}><br/> {vinSearched.length} CARACTERES </span>:null}
                                </Form.Field>
                            </Form> <br/>
                            <Button onClick={()=>this.setState({vinSearched:''})} style={{borderRadius:25}}>
                                <Icon name="chevron circle left"/> LIMPIAR 
                            </Button>
                            <Button onClick={()=> this.searchToActive()} style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}>
                                BUSCAR <Icon name="search"/>
                            </Button>
                            {this.state.showMenssage?<Segment placeholder>
                                <Header icon>
                                <Icon name='file alternate outline' />
                                    {this.state.message}
                                </Header>
                                <Button style={{borderRadius:25, backgroundColor:'#7E8083', color:'#F6F6F6'}} onClick={ ()=> this.setState({ showMenssage:false }) } primary> OK </Button>
                            </Segment>:null}
                        </Container>
                    </Segment>
                </Container>
                {loading?<Preloader/>:null}
            </Grid>
        )
    }
}
const mapDispacthToProps = dispatch =>{
    return {
        messageToUser: (title, message, open)=>dispatch(MessageToUser(title, message, open))
    }
}
export default connect(null, mapDispacthToProps)(RedentionScreen)