import { getApi } from '../../utils/Constants';

const api = getApi();
const apiModule = '/prepaidMant/customer/';
const token = JSON.stringify(localStorage.getItem('token'));

async function redeemNotification(body){
    return await fetch(api+apiModule+'redeemNotification', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body:JSON.stringify({
            order: body.order,
            routine: body.routine
        })
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

export {
    redeemNotification
}