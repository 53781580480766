import React, { Component } from 'react';
import { Header, Form, Button, Segment, Icon, Modal } from 'semantic-ui-react';

import { getApi, getMotorOption, getAireOption, getTransmissionOption } from '../../utils/Constants';


const motorOption = getMotorOption();
const aireOption = getAireOption();
const transmissionOption = getTransmissionOption();

const token = localStorage.getItem('token')
export default class FormReferenceComponent extends Component {

    constructor(props){
        super(props)
        this.state = { 
            model:'', 
            ref:'',
            description:'', 
            cost:'', 
            amount:'',
            routine:'',
            pvc:'',
            pvp:'',
            mg_pvc:'',
            mg_pvp:'',
            active:'',
            motor:'',
            aa:'',
            transmission:'', 
            displacement:'',
            saving:false,
            openModal:false,
            errorMessage:''
        }
    }

    create=()=>{
        this.setState({saving:true})
        const { 
            model, 
            ref,
            description, 
            amount,
            routine,
            cost, 
            pvc, 
            pvp, 
            mg_pvc, 
            mg_pvp, 
            createup, 
            active, 
            motor, 
            aa, 
            transmission, 
            displacement   } = this.state;

        fetch(getApi()+'prepaidMant/reference/create', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+token
            },
            body: JSON.stringify({
                model:model,
                ref:ref,
                description: description,
                amount: amount,
                routine: routine, 
                cost: cost, 
                pvc: pvc, 
                pvp: pvp, 
                mg_pvc: mg_pvc, 
                mg_pvp: mg_pvp, 
                createup: createup, 
                active: active, 
                motor: motor, 
                aa: aa, 
                transmission: transmission, 
                displacement: displacement
            })
        }).then( (response)=> response.json() )
        .then( (responseJson)=>{
            
            this.setState({saving:false})
            if(responseJson.errors && responseJson.message){
                return this.setState({openModal:true, errorMessage:responseJson.message})
            }
            this.props.updateReferences();
        }).catch( (error)=>{
            this.setState({openModal:true, errorMessage:error.message})
        })
    }

    handleChange = (e, { name, value })=>{
        if(value === null){
            this.setState({[name]: value})
        } else {
            this.setState({[name]: value.toUpperCase()})
        }
    }

    renderModal=(open, message)=>{
        return(
            <Modal open={open} size='small' closeOnDimmerClick={true} closeOnEscape={true} onClose={ ()=>{this.setState({openModal:false})}}>
                <Header icon='database' content='Algo salió mal' />
                <Modal.Content>
                <p>
                    {message}
                </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        style={{ borderRadius:25 }}
                        onClick={()=> {this.setState({openModal:false}); this.create()}} 
                        content="INTENTAR NUEVAMENTE" icon="redo"
                        size='tiny' />
                    <Button 
                        style={{ borderRadius:25 }}
                        onClick={()=> {this.setState({openModal:false})}} 
                        content="CANCELAR" 
                        icon="remove"
                        size='tiny' />
                </Modal.Actions>
            </Modal>
        )
    }


    render(){
        const { model, 
            ref, 
            description, 
            cost,
            amount,
            routine,
            pvc, 
            pvp, 
            mg_pvc, 
            mg_pvp,
            saving, openModal, errorMessage,
            } = this.state;
        const { cancel }=this.props;
        return (
            <div>
                <Header as="h5">Crear referencia</Header>
                <Segment>
                    <Form>
                        <Form.Group widths="four">
                            <Form.Input onChange={this.handleChange} label="Modelo" value={model} name="model" placeholder="Cód modelo"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="Referencia" value={ref} name="ref" placeholder="Referencia parte"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="Descripción" value={description} name="description" placeholder="Descripción"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="Costo" value={cost} name="cost" type="number" placeholder="Costo"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="PVC" value={pvc} name="pvc" type="number" placeholder="PVC"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="PVP" value={pvp} name="pvp" type="number" placeholder="PVP"></Form.Input>
                            {/* <Form.Input onChange={this.handleChange} label="Costo" name="cost" placeholder="Costo"></Form.Input> */}
                        </Form.Group>

                        <Form.Group>
                            <Form.Input type="number" onChange={this.handleChange} label="Cantidad" value={amount} name="amount" placeholder="Cantidad"></Form.Input>
                            <Form.Input type="number" onChange={this.handleChange} label="Rutina" value={routine} name="routine" placeholder="Rutina de MTO"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="MG PVC" value={mg_pvc} name="mg_pvc" placeholder="MG PVC"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="MG PVP" value={mg_pvp} name="mg_pvp" placeholder="MG PVP"></Form.Input>
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Select onChange={this.handleChange} label="Motor" name="motor" options={motorOption} placeholder="Motor"></Form.Select>
                            <Form.Select onChange={this.handleChange} label="Aire Acondicionado" name="aa" options={aireOption} placeholder="Aire Acondicionado"></Form.Select>
                            <Form.Select onChange={this.handleChange} label="Caja" name="transmission" options={transmissionOption} placeholder="Tipo de caja"></Form.Select>
                            <Form.Input onChange={this.handleChange} type="number" label="Cilindrada (L)" name="displacement" placeholder="Cilindraje"></Form.Input>
                        </Form.Group>
                    </Form>
                    <Button 
                        onClick={cancel} 
                        icon="times"
                        style={{borderRadius:25}}
                        size='tiny'
                        content='CANCELAR'></Button>
                    <Button 
                        loading={saving} 
                        onClick={ ()=> this.create() } 
                        content="GUARDAR" icon="save" 
                        size="tiny"
                        style={{backgroundColor:"#BB132B", color:"#f6f6f6", borderRadius:25}} />
                </Segment>
                {this.renderModal(openModal, errorMessage)}
            </div>
        )
    }
}