import { FETCHING_DATA, FETCHING_DATA_SUCCESS, FETCHING_DATA_FAILURE} from '../constants';
import login from '../api/login';
import getUserByEmail from '../api/getUserByEmail';
import getModelTemplate from '../api/getModelTemplate';
import getGroupModels from '../api/models/getGroupModels';
import createModelTemplate from '../api/modelTemplate/create';
import getCountModelTemplate from '../api/modelTemplate/getCountModelTemplate';


export const MessageToUser = (title, message, open) => {
    return {
        type:'MessageToUser',
        title:title,
        message:message,
        open:open
    }
}
export const getData = (PATH) =>{
    return {
        type:FETCHING_DATA+PATH
    }
}

export const getDataSuccess = (data, PATH) => {
    return {
        type:FETCHING_DATA_SUCCESS+PATH,
        data
    }
}

export const getDataFailure = (PATH) => {
    return {
        type:FETCHING_DATA_FAILURE+PATH
    }
}

export const logOutApp = () => {
    return {
        type:'LOGOUT',
        data:[]
    }
}

export const FetchDatalogin = (body) => {
    return (dispatch) => {
        dispatch(getData('LOGIN'))
        login(body)
        .then((response) =>{ 
            dispatch(getDataSuccess(response, 'LOGIN'))
        })
        .catch( (error)=>{
            dispatch(getDataFailure('LOGIN'))
        } )
    }
}

export const GetUserByEmail = (body) => {

    return (dispatch) => {
        dispatch(getData('USERBYEMAIL'))
        getUserByEmail(body)
        .then( (response) =>{
            dispatch(getDataSuccess(response, 'USERBYEMAIL'))
            dispatch(GetGroupModels())
        } ).catch( (error)=> {
            dispatch(getDataFailure('USERBYEMAIL'))
        } )
    }
}

// MODEL TEMPLATE
export const GetModelTemplate = (body) => {

    return (dispatch) => {
        dispatch(getData('MODELTEMPLATE'))
        getModelTemplate(body)
        .then( (response) =>{
            dispatch(getDataSuccess(response, 'MODELTEMPLATE'))
        } ).catch( (error)=> {
            dispatch(getDataFailure('MODELTEMPLATE'))
        } )
    }
}

export const craeateModelTemplate = (body) => {
    return (dispatch) => {
        createModelTemplate(body)
        .then( (response)=>{
            console.log(response)
            dispatch(MessageToUser('Genial', 'Se ha creado un nuevo tempario'))
            dispatch(GetModelTemplate(body))
        } ).catch( (error)=>{
            console.log(error)
        } )
    }
}

export const GetCountModelTemplate = (body) => {
    return (dispatch) => {
        dispatch(getData('COUNTMODELTEMPLATE'))
        getCountModelTemplate(body)
        .then( (response) =>{
            dispatch(getDataSuccess(response, 'COUNTMODELTEMPLATE'))
        } ).catch( (error)=> {
            dispatch(getDataFailure('COUNTMODELTEMPLATE'))
        } )
    }
}

// END MODEL TEMPLATE

export const GetGroupModels = () => {

    return (dispatch) => {
        dispatch(getData('GETGROUPMODELS'))
        getGroupModels()
        .then( (response) =>{
            dispatch(getDataSuccess(response, 'GETGROUPMODELS'))
        } ).catch( (error)=> {
            dispatch(getDataFailure('GETGROUPMODELS'))
        } )
    }
}

// ORDER ACTIONS
export const addPackageToOrder = (plan, projection, article) => {
    return {
        type:'SelectPackage',
        plan:plan,
        projection:projection,
        article:article
    }
}

export const SetOrderForm = ( form )=>{
    return {
        type:'SetForm',
        form:form
    }
}

export const SelectOrder = ( orderId )=>{
    return{
        type:'SelectOrder',
        id:orderId
    }
}
// END ORDER ACTIONS



// Manejador de las vistas
export const showComponent = (component) => {
    return {
        type:component
    }
}