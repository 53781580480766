import React, { Component } from 'react'
import { Grid, Container, Header, Button, Modal, Table, Form, Loader, Dimmer, Progress, Icon, Segment } from 'semantic-ui-react'
import { InputFile } from 'semantic-ui-react-input-file';
import { connect } from 'react-redux'
import XLSX from 'xlsx';
import { create, read, update, delet } from '../../../../services/vins/vinServices'
import { MessageToUser } from '../../../../actions'
import moment from 'moment'

const initialVin = {
    vin:'',
    article:'',
    year: parseInt(moment().format('YYYY')),
    _id:''
}
class VinsScreen extends Component {
    
    state = {
        modalAdd: false,
        procesigData: false,
        loadingRead: false,
        modalUpload: false,
        fileName:'',
        fileSelect:null,
        total:0,
        data:[],
        log:[],
        vin: initialVin,
        filter: initialVin,
        vins:[],
        cancelProcess:false
    }

    componentDidMount(){
        this.read()
    }

    create = () => {
        const { vin } = this.state
        create( vin )
        .then( result => {
            this.props.msgToUser( 
                result.status===200?'Operación exitosa':'Error', 
                result.msg, 
                true
            )
            this.read()
        })
    }

    read = () => {
        this.setState({ loadingRead: true })
        const { filter } = this.state
        read(filter)
        .then( response => {
            if(response.status == 500) return this.props.msgToUser('Error', response.msg, true)
            this.setState({
                vins: response
            }, ()=> {
                this.setState({ loadingRead: false})
            })
        } )
    }


    delet = () => {
        const { vin } = this.state
        delet( vin )
        .then( response => {
            this.props.msgToUser( 
                response.status===200?'Operación Exitosa':'Error',
                response.msg,
                true
            )
            this.read()
        })
    }


    handleChange = ( e, { name, value }) => {
        this.setState({
            vin:{
                ...this.state.vin,
                [name]: value
            }
        })
    }

    handleChangeFilter = (e, { name, value }) => {
        this.setState({
            filter:{
                ...this.state.filter,
                [name]: value
            }
        })
    }

    handleUpload = (e)=>{
        if(e.target.files && e.target.files[0]){
            this.setState({ fileSelect:e.target.files[0], fileName:e.target.files[0].name})
            this.readerXlsx(e)
        }
    }

    readerXlsx = (e) => {
        this.setState({ procesigData: true})
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        const scope = this
        reader.onload = function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
            
            /* DO SOMETHING WITH workbook HERE */

            var wsNames = workbook.SheetNames[0]; //Indexar una hoja
            var ws = workbook.Sheets[wsNames]; // Obtener toda la data de la hoja por celda

            let data_json = XLSX.utils.sheet_to_json(ws, {header:0}) //Utilidad de XLSX para convertir ws en un JSON
            
            scope.setState({data:data_json, total: data_json.length, procesigData:false})
            
        };
        reader.readAsArrayBuffer(f);
        
    }

    uploadReport = () => {
        const { total, data, cancelProcess } = this.state
        if(cancelProcess) return true
        if( total > 0 ){
            
            create( data[ total - 1])
            .then( response => {
                
                this.setState({
                    total: total - 1,
                    log:[
                        ...this.state.log, {
                            vin: response.vin,
                            msg: response.msg
                        }
                    ]
                }, () => {
                    this.uploadReport()
                })
            } )
        } else {
            this.props.msgToUser('Información del Proceso', 'Se ha procesado la totalidad de los registros', true)
        }
    }

    renderProgress = () => {
        const { total, data } = this.state;
        
        if(data && data.length>0) return <Progress 
            active={false} 
            size='tiny' 
            percent={ ( (data.length - total) / data.length)*100} 
            indicating 
            label={Math.round(((data.length - total) / data.length)*100)+'%'} />
        return null;
    }

    renderModal = () => {
        const { modalAdd, vin } = this.state
        return <Modal open={modalAdd}>
            <Modal.Header>
                VIN
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} name='vin' value={ vin.vin } label='VIN' placeholder='17 caracteres' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} name='article' value={ vin.article } label='VERSION' placeholder='Versión o código de equipamiento' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} name='year' type='number' value={ vin.number } label='AÑO MODELO' placeholder='año modelo ' />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    size='tiny'
                    content='CANCELAR'
                    icon='times'
                    style={{ borderRadius:25}} 
                    onClick={()=>{this.setState({modalAdd: false})}} 
                />
                <Button
                    size='tiny'
                    content='GUARDAR'
                    icon='save'
                    style={{ borderRadius:25, backgroundColor:'#bb162b', color:'#f6f6f6'}} 
                    onClick={()=> this.create() } 
                />
            </Modal.Actions>
        </Modal>
    }

    uploadFileModal = () => {
        const { modalUpload, data, fileName, total } = this.state
        return <Modal open={modalUpload} >
            <Modal.Header>
                <Icon name='file excel outline' />    CARGAR VIN DESDE ARCHIVO
            </Modal.Header>
            <Modal.Content>
                <InputFile
                    button={{label:fileName? fileName:'Elige un archivo', size:'tiny' }}
                    input={{
                        id:'input-upload-id',
                        onChange:this.handleUpload
                    }}
                />
                {this.previewData()}
                {this.renderProgress()}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    floated='left'
                    size='tiny'
                    content='CERRAR'
                    icon='times'
                    style={{ borderRadius:25}} 
                    onClick={()=>{this.setState({modalUpload: false})}} 
                />
                <Button
                    size='tiny'
                    content={`GUARDAR (${total})`} 
                    icon='save'
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                    onClick={ ()=> this.setState({ cancelProcess: false}, () => this.uploadReport())  }
                />
                {total>0?<Button
                    size='tiny'
                    icon='times'
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                    onClick={()=> this.setState({ cancelProcess: true })} 
                />:null}
            </Modal.Actions>
            
        </Modal>
    }

    previewData = () => {
        const { data, procesigData } = this.state
        if(procesigData) return <Dimmer active inverted>
                                    <Loader inverted>
                                        ...Estamos trabajando
                                    </Loader>
                                </Dimmer>
        if(!(data || data.length>0)) return null
        return <Table size='small' selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>VIN</Table.HeaderCell>
                    <Table.HeaderCell>ARTICULO</Table.HeaderCell>
                    <Table.HeaderCell>VERSION</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map( (i, index)=> {
                    if(index < 10) return <Table.Row>
                                                <Table.Cell>{i.vin}</Table.Cell>
                                                <Table.Cell>{i.article}</Table.Cell>
                                                <Table.Cell>{i.year}</Table.Cell>
                                            </Table.Row>
                    
                } )}
            </Table.Body>
        </Table> 
    }

    renderVins = () => {
        const { vins, loadingRead } = this.state
        if(loadingRead) return <Dimmer active inverted>
                                    <Loader inverted>
                                        ...Estamos trabajando
                                    </Loader>
                                </Dimmer>
        if( !(vins && vins.length>0) ) return null
        return <Table size='small' selectable >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>VIN</Table.HeaderCell>
                    <Table.HeaderCell>AÑO</Table.HeaderCell>
                    <Table.HeaderCell>VERSION</Table.HeaderCell>
                    <Table.HeaderCell>ACCIONES</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                { vins.map( (i) => {
                    return <Table.Row>
                        <Table.Cell>{i.vin}</Table.Cell>
                        <Table.Cell>{i.year}</Table.Cell>
                        <Table.Cell>{i.article}</Table.Cell>
                        <Table.Cell>
                            {/* <Button
                                icon='trash'
                                size='tiny'
                                style={{ backgroundColor: '#bb162b', color:'#f6f6f6', borderRadius: 25}} 
                                onClick={() => this.setState({ vin: i}, ()=> this.delet() )}
                            /> */}
                            <Button 
                                size='tiny'
                                animated 
                                onClick={() => this.setState({ vin: i}, ()=> this.delet() )}
                                style={{ backgroundColor: '#bb162b', color:'#f6f6f6', borderRadius: 25}} >
                                <Button.Content visible>
                                    <Icon name='trash'/> Eliminar
                                </Button.Content>
                                <Button.Content hidden>
                                    Está seguro?
                                </Button.Content>
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                } )}
            </Table.Body>
        </Table>
    }
    render(){
        const { filter } = this.state
        return <Grid style={{minHeight:'70vh', marginTop:'8vh', marginBottom:50 }}>
                    <Container>
                        <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                            VINS
                            <span>
                                <Button 
                                    content="CARGAR DE ARCHIVO"
                                    icon="upload"
                                    onClick={()=>{this.setState({modalUpload:true, cancelProcess:false})}} 
                                    size="mini" 
                                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                                    floated='right'/>
                                <Button 
                                    content="AGREGAR"
                                    icon="add"
                                    onClick={()=>{this.setState({modalAdd:true})}} 
                                    size="mini" 
                                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                                    floated='right'/>
                            </span>
                        </Header>
                        <Segment>
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Input onChange={this.handleChangeFilter} name='vin' value={filter.vin} label='VIN' />
                                    <Form.Input onChange={this.handleChangeFilter} name='article' value={filter.article} label='ARTICULO' />
                                    <Form.Input onChange={this.handleChangeFilter} name='year' value={filter.year} label='Año modelo' type='number' />
                                    <Form.Field>
                                        <label>Buscar</label>
                                        <Button
                                            content='BUSCAR'
                                            icon='search'
                                            onClick={()=> this.read()} 
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Segment>
                        
                        {this.renderVins()}
                        
                    </Container>
                    {this.renderModal()}
                    {this.uploadFileModal()}
                    {/* {this.confirmModal()} */}
                </Grid>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        msgToUser: (title, msg, open) => dispatch(MessageToUser(title, msg, open))
    }
}
export default connect(null, mapDispatchToProps)(VinsScreen)