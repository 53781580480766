import React, { Component } from 'react';
import { Grid, Segment, Table, Header, Item, Label, Button, Icon, List } from 'semantic-ui-react';
import PreloaderComponent from '../../../../components/PreloaderComponent';
import { getVariables } from '../../../../services/variables/variblesService';
import IconStatusRedeem from '../../../../components/Order/IconStatusRedeem';
const CurrencyFormat = require('react-currency-format')

class PackageScreen extends Component {
    constructor(props){
        super(props)
        this.state = { iva:null }
    }
    componentDidMount(){
        this.variables();
    }
    variables = () => {
        getVariables().then( data =>{
            console.log(data)
            if(data && data.length>0){
                for(let i of data){
                    if(i.name === 'IVA'){
                        this.setState({iva:i.value/100})
                    }
                }
            }
        })
    }
    renderTable = ()=> {
        if(this.props.order.projection && this.state.iva){
            return this.props.order.projection.map( (i, index)=>{
                if( i.routine <= this.props.order.plan.routine){
                    return <Table.Row key={i.futureFactor+index}>
                        <Table.Cell>{i.routine}</Table.Cell>
                        <Table.Cell>
                            {/* valor a redimir */}
                            <CurrencyFormat value={i.valueToRedeem} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                        </Table.Cell>
                        <Table.Cell>

                            <CurrencyFormat value={i.pParts * i.futureFactor} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> 
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.pMo * i.futureFactor} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> 
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.pSupply * i.futureFactor} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> 
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.pFutureValue} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.pFutureValue*this.state.iva} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                        </Table.Cell>
                        <Table.Cell> <IconStatusRedeem status={i.redeemStatus} /> </Table.Cell>
                    </Table.Row>
                }
                
            } )
        }
    }
    render(){
        console.log(this.props.order.projection)
        if(!this.props.order.plan){
            return <PreloaderComponent/>
        }
        const { routine, salesPrice, fmeTotal, admonCost } = this.props.order.plan;
        return(
            <div>
                <Segment color="red">
                    <Header>
                        <Header.Subheader>Plan Adquirido</Header.Subheader>
                    </Header>

                    <Grid columns={4} stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Item.Group>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>{routine} <Label color="red"> K </Label> </Item.Header>
                                            <Item.Meta>Paquete adquirido</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Column>
                            <Grid.Column>
                                <Item.Group>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>
                                             <CurrencyFormat value={salesPrice} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Item.Header>
                                            <Item.Meta>Valor pagado</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Column>
                            <Grid.Column>
                                <Item.Group>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>
                                            <CurrencyFormat value={admonCost} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Item.Header>
                                            <Item.Meta>Administración</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Column>
                            <Grid.Column>
                                <Item.Group>
                                    <Item>
                                        <Item.Content>
                                            <Item.Header as='a'>
                                                {routine/5}
                                            </Item.Header>
                                            <Item.Meta>Total Rutinas</Item.Meta>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                   <Header>
                        <Header.Subheader>Detalle de Mantenimientos Prepagados</Header.Subheader>
                    </Header>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Rutina</Table.HeaderCell>
                                <Table.HeaderCell>Valor de cada Rutina</Table.HeaderCell>
                                <Table.HeaderCell colSpan={1}>Repuestos</Table.HeaderCell>
                                <Table.HeaderCell>Mano de Obra</Table.HeaderCell>
                                <Table.HeaderCell>Insumos</Table.HeaderCell>
                                <Table.HeaderCell>SubTotal</Table.HeaderCell>
                                <Table.HeaderCell>IVA</Table.HeaderCell>
                                <Table.HeaderCell>Redimido</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderTable()}
                        </Table.Body>
                    </Table>
                </Segment>
            </div>
        )
    }
}

export default PackageScreen