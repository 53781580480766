import React, { Component} from 'react';
import {Loader, Dimmer, Image} from 'semantic-ui-react';

export default class PreloaderComponent extends Component {
    render(){
        return(
            <Dimmer page={true} active={true}  inverted={true}>
                <Image style={{maxWidth:300}} src={require('../assets/kia_blanco.png')}></Image>
                <Loader style={{ top:'60%'}} inverted>
                </Loader>   
            </Dimmer>
                     
        )
    }
}