import { getApi } from '../../utils/Constants';

const api = getApi();

const token = localStorage.getItem('token');

async function getUsers( body ){
    return await fetch(api+'users', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify({
            name: body.name,
            last_name: body.last_name,
            role: body.role,
            email:body.email,
            group: body.group
        })
    }).then( (variables) => variables.json() )
    .then( (response)=>{
        return response;
    }).catch( (error)=>{
        return error;
    } )
}

async function signUp( body ){
    return await fetch(api+'signUp', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify({
            name: body.name,
            last_name: body.last_name,
            email: body.email,
            role: body.role,
            group: body.group,
            dealer: body.dealer,
            password: body.password,
            servicePackAccess: true
        })
    }).then( (variables) => variables.json() )
    .then( (response)=>{
        return response;
    }).catch( (error)=>{
        return error;
    } )
}

async function upDateUser( body ){
    return await fetch(api+'/users/'+body._id, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            name: body.name,
            last_name: body.last_name,
            role: body.role,
            group: body.group,
            dealer: body.dealer,
            password: body.newPassword,
            newPassword: body.newPassword,
            newPasswordc: body.newPasswordc
        })
    }).then( (result)=> result.json() )
    .then( (result)=>{
        return result;
    } ).catch( (error)=>{
        return error;
    } )
}

export {
    getUsers,
    signUp,
    upDateUser
}


// let name = new RegExp(req.body.name, 'i');
// let last_name = new RegExp(req.body.last_name, 'i');
// let role = new RegExp(req.body.role, 'i');
// let email = new RegExp(req.body.email, 'i') 
// let group = new RegExp(req.body.group, 'i') 