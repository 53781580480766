import React, { Component, Fragment } from 'react';
import { Grid, Container, Header, Label, Icon, Button, Table, Modal, Form, Checkbox, List, Segment, Message } from 'semantic-ui-react';
import { getUsers, signUp, upDateUser } from '../../../../services/users/userService';
import { dealerByGroup } from '../../../../services/dealers/dealersService';
import { connect } from 'react-redux';
import { rolOptions } from '../../../../utils/Constants';
import { MessageToUser } from '../../../../actions';

class UsersScreen extends Component {
    state = {
        name: '',
        last_name: '',
        role: '',
        email:'',
        group:'',
        data:[],
        openModalAdd:false,
        actionModal:'',
        dealers:[],
        clOptions:[],
        
        // New User
        _id:'',
        newName:'',
        newLast_name: '',
        newRole: 'Elige un Rol',
        newEmail:'',
        newGroup: 'All',
        newPassword:'', 
        newCPassword:'',
        FormError:'',

        // edit pass
        password:'',
        changePassword:'',
        changePasswordc:''
    }

    componentDidMount(){
        let group = ''
        if(this.props.user.group !== 'All'){
            group = this.props.user.group
        }
        this.setState({
            name: '',
            last_name: '',
            role: '',
            email:'',
            group: group
        }, ()=>{ this.getUsers() })

        this.getDealers()
    }

    getUsers= () => {
        getUsers(this.state)
        .then( data =>{
            this.setState({data:data})
        } ).catch( error =>{
            console.log(error)
        })
    }

    getDealers = () => {
        dealerByGroup()
        .then( (data)=>{
            console.log(data)
            let temporalObject = []
            if(data && data.length>0){
                temporalObject.push({
                    value:'All',
                    text:'Red Kia',
                    key:0,
                    cl:[]
                })
                for(let i of data){
                    temporalObject.push({
                        value:i.group,
                        text: i.group,
                        key:i.group,
                        cl:i.cl
                    })
                }
            }
            this.setState({dealers:temporalObject})
        } )
    }

    handleChange = (e, {name, value})=>{
        console.log([name])
        this.setState({[name]:value}, ()=>{
            if(name === 'newGroup'){
                
                this.renderClOptions();
            }

            if([name] === 'newPassword'){
                if(value && value.length<6){
                    return this.setState({FormError:'La contraseña debe tener al menos 6 caracteres'})
                } else {
                    return this.setState({FormError:''})
                }
            }
            if([name] === 'newCPassword'){
                if(value  !== this.state.newPassword){
                    return this.setState({FormError:'Las contraseñas no coinciden'})
                } else {
                    return this.setState({FormError:''})
                }
            }
            if([name] === 'newEmail'){
                console.log(value.split('@'))
                if(value && !(value.split('@').length === 2)){
                    return this.setState({FormError:'Escriba un mail válido'})
                } else {
                    return this.setState({FormError:''})
                }
            }
        })
        
    }

    renderClOptions = () =>{
        console.log('CL Options')
        const { newGroup, dealers } = this.state;
        if(newGroup !== 'All' && (dealers && dealers.length>0)){
            console.log('paso la validación')
            for(let i of dealers ){
                if(i.value === newGroup){
                    console.log(i.cl)
                    if(i.cl && i.cl.length>0){
                        this.setState({clOptions:i.cl})
                    }
                }
            }
        }
    }


    toggle = (index)=>{
        const { clOptions } = this.state;
        let temporalObject = clOptions;
        temporalObject[index].select = !temporalObject[index].select;
        this.setState({clOptions: temporalObject })
    }

    renderCl = ()=> {
        const { clOptions } = this.state;
        if(clOptions && clOptions.length>0){
            return clOptions.map( (i, index)=>{
                return (
                    <List.Item key={i.cl}>
                        <List.Icon name='building' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header as='h4'>{i.ad} {i.name}</List.Header>
                            <List.Description as='a'>
                                {i.cl} 
                            </List.Description>
                        </List.Content>
                        <List.Content floated='right'>
                            <Checkbox checked={i.select} toggle onChange={()=>this.toggle(index)} />
                        </List.Content>
                    </List.Item>
                )
            } )
        } else {
            return null
        }
    
    }

    modalAdd = () =>{
        const { openModalAdd, newName, newLast_name, newEmail, newRole, newGroup, newPassword, newCPassword, dealers, FormError, actionModal,changePassword, changePasswordc } = this.state;
        
        return <Modal open={openModalAdd}>
            <Modal.Header>
                {
                    actionModal==='edit'?    <Header as="h3" icon='edit' content='Editar usuario' />
                    : <Header as="h3" icon='users' content='Agregar usuario' />
                }
            </Modal.Header>
            <Modal.Content>
                <Form error={FormError? true: false}>
                    <Form.Group widths="equal">
                        <Form.Input fluid onChange={this.handleChange} label="Nombre" name="newName" value={newName} />
                        <Form.Input fluid onChange={this.handleChange} label="Apellido" name="newLast_name" value={newLast_name} />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select fluid onChange={this.handleChange} label="Rol" options={rolOptions} name="newRole" value={newRole} />
                        <Form.Input name="newEmail" value={newEmail} label="Email" type="email" onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select onChange={this.handleChange} options={dealers} label="Concesión" name="newGroup" value={newGroup} />
                        
                    </Form.Group>
                    <Form.Group widths="equal">
                        
                        {
                            actionModal==='edit'?
                            <Fragment>
                                <Form.Input fluid onChange={this.handleChange} label="Contraseña" name="changePassword" value={changePassword} type="password" />
                                <Form.Input fluid onChange={this.handleChange} label="Confirme la Contraseña" name="changePasswordc" value={changePasswordc} type="password" />
                            </Fragment>
                            : 
                            <Fragment>
                                <Form.Input fluid onChange={this.handleChange} label="Contraseña" name="newPassword" value={newPassword} type="password" />
                                <Form.Input fluid onChange={this.handleChange} label="Confirme la Contraseña" name="newCPassword" value={newCPassword} type="password" />
                            </Fragment>
                        }
                        
                    </Form.Group>
                    {FormError?<Message
                        error
                        header='Datos no válidos'
                        content={FormError}
                        />:null}
                </Form>
                <Segment basic>
                    <List animated>
                        {this.renderCl()}
                    </List>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={()=>this.setState({openModalAdd:false, })} size="small" content="CANCELAR" icon="times" style={{ borderRadius:25}}/>
                <Button onClick={()=>this.userValidation()} size="small" content={this.state.actionModal==='crear'?'CREAR USUARIO':'ACTUALIZAR USUARIO'} icon="save" style={{backgroundColor:'#BB162B', color:'#F6F6F6',borderRadius:25}}/>
            </Modal.Actions>
        </Modal>
    }

    editUser = ( user ) => {
        
        
        this.setState({
            openModalAdd:true,
            _id:user._id,
            newName:user.name,
            newLast_name: user.last_name,
            newRole: user.role,
            newEmail: user.email,
            newGroup: user.group,
            password:user.password,
            newPassword:'', 
            newCPassword:'',
            FormError:'',
            actionModal:'edit'
        }, ()=>{
            const { newGroup, dealers } = this.state;
            if(newGroup !== 'All' && (dealers && dealers.length>0)){
                
                for(let i of dealers){
                    for(let j of i.cl){
                        j.select = false;
                    }
                }
    
                for(let i of dealers ){
                    if(i.value === newGroup){
                        
                        for(let j of user.dealer){
                            for(let k of i.cl){
                                if(j === k.cl){
                                    k.select = true;
                                }
                            }
                        }
                        if(i.cl && i.cl.length>0){
                            this.setState({
                                clOptions:i.cl
                            })
                        }
                    }
                }
            }
        })
        
    }

    renderTable = () => {
        const { data } = this.state;
        const { role } = this.props.user;
        if(data && data.length>0){
            return data.map( (i, index)=>{
                return <Table.Row key={i._id}>
                    <Table.Cell style={{textTransform:'capitalize'}}>{i.name}</Table.Cell>
                    <Table.Cell style={{textTransform:'capitalize'}}>{i.last_name}</Table.Cell>
                    <Table.Cell>{i.email}</Table.Cell>
                    <Table.Cell>
                        <Label>{i.group}</Label>
                    </Table.Cell>
                    <Table.Cell><Label style={{textTransform:'uppercase'}}>{i.role}</Label></Table.Cell>
                    <Table.Cell>{i.dealer.length}</Table.Cell>
                    <Table.Cell>
                        {role==='manager' || role === 'dealers_manager'?<Icon onClick={()=> this.setState({clOptions:[]}, ()=> this.editUser(i) ) } name="edit" style={{color:'#7E8083', cursor:"pointer" }} />:null}
                    </Table.Cell>
                </Table.Row>
            } )
        }
    }

    userValidation = () => {
        const { newName, newLast_name, newEmail, newRole, newGroup, newPassword, clOptions, _id, changePassword, changePasswordc, password } = this.state;
        console.log(this.state.actionModal)
        const { role, group } = this.props.user;
        let dealersToUser = [];

        if(!newName || !newLast_name){
            return this.setState({FormError:'Complete el nombre y apellido'})
        } else { this.setState({FormError:''}) }
        if(!newEmail){
            return this.setState({FormError:'Escriba un email válido'})
        } else { this.setState({FormError:''}) }
        
        if( role === "dealers_manager"){
            if(group !== newGroup){
                return this.setState({FormError:'No tiene permisos para crear usuarios en otra concesión'})
            } else { this.setState({FormError:''}) }

            if(newRole === 'manager' || newRole === 'dealers_manager'){
                return this.setState({FormError:'No tiene permisos para crear Administradores'})
            } else { this.setState({FormError:''}) }

            for(let i of clOptions){
                if(i.select){
                    dealersToUser.push(i.cl)
                }
            }
            if(dealersToUser && !(dealersToUser.length>0)){
                return this.setState({FormError:'No se ha seleccionado ningun distribuidor'})
            } else { this.setState({FormError:''}) }
            
            if(this.state.actionModal === 'crear'){
                this.signUp( {
                    name: newName,
                    last_name: newLast_name,
                    email: newEmail,
                    password: newPassword,
                    dealer: dealersToUser,
                    role: newRole,
                    group: newGroup,
                    active:true
                } )
            } else if(this.state.actionModal === 'edit'){
                this.upDateUser({
                    _id:_id,
                    name: newName,
                    last_name: newLast_name,
                    email: newEmail,
                    password: password,
                    newPassword: changePassword,
                    newPasswordc: changePasswordc,
                    dealer: dealersToUser,
                    role: newRole,
                    group: newGroup
                })
            }
            
        } else if(role === 'manager') {
            // Validaciones para el Manager
            if(newRole === 'manager'){
                for(let i of this.state.dealers){
                    for(let j of i.cl){
                        dealersToUser.push(j.cl)
                    }
                }
            } else if(newRole === 'dealers_manager') {
                console.log('entro a la opcion de dealers_manager')
                console.log(this.state.dealers)
                for(let i of this.state.dealers){
                    if(newGroup === i.value){
                        for(let j of i.cl){
                            dealersToUser.push(j.cl)
                        }
                    }
                }
            } else {
                for(let i of clOptions){
                    if(i.select){
                        dealersToUser.push(i.cl)
                    }
                }
            }
            console.log({
                name: newName,
                last_name: newLast_name,
                email: newEmail,
                password: newPassword,
                dealer: dealersToUser,
                group: newGroup,
                role: newRole,
                active:true
            })
            if(this.state.actionModal === 'crear'){
                this.signUp( {
                    name: newName,
                    last_name: newLast_name,
                    email: newEmail,
                    password: newPassword,
                    dealer: dealersToUser,
                    group: newGroup,
                    role: newRole,
                    active:true
                } )
            } else if(this.state.actionModal === 'edit'){
                this.upDateUser({
                    _id:_id,
                    name: newName,
                    last_name: newLast_name,
                    email: newEmail,
                    newPassword: changePassword,
                    newPasswordc: changePasswordc,
                    dealer: dealersToUser,
                    role: newRole,
                })
            }
        }
    }

    signUp=(user)=>{
        signUp(user)
        .then( (result)=>{
            console.log(result)
            this.props.messageToUser('Mensaje', result.message, true);
            this.getUsers()
        } )
    }

    upDateUser=(user)=>{
        upDateUser(user)
        .then( (result)=>{
            this.props.messageToUser('Mensaje', result.msg, true);
            this.getUsers()
        } ).catch( (error)=>{
            console.log(error)
        } )
    }

    modalFilter= ()=>{
        const { openModalFilter, name, last_name, role, email, group  } = this.state;
        return <Modal open={openModalFilter} >
            <Modal.Header>
                <Header as="h3" icon="search" content="Buscar"></Header>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input fluid value={name} name="name" label="Nombre" onChange={this.handleChange} />
                        <Form.Input fluid value={last_name} name="name" label="Nombre" onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input fluid value={email} name='email' label='Email' onChange={this.handleChange} />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={()=>this.setState({openModalFilter:false, })} size="small" content="CANCELAR" icon="times" style={{ borderRadius:25}}/>
                <Button onClick={()=>this.setState({openModalFilter:false}, ()=>this.getUsers())} size="small" content='BUSCAR' icon="search" style={{backgroundColor:'#BB162B', color:'#F6F6F6',borderRadius:25}}/>

            </Modal.Actions>
        </Modal>
    }

    render(){

        return (
            <Fragment>
                <Grid style={{marginTop:30, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
                    <Container fluid>
                        
                        <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                            USUARIOS
                            <span>
                                <Button onClick={()=>this.setState({openModalFilter:true })} size="mini" style={{borderRadius:25}} floated='right' icon="search" content="BUSCAR" />
                                <Button onClick={()=> this.setState({openModalAdd:true, actionModal:'crear', clOptions:[]}) } size="mini" style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} floated='right'><Icon name="add"/> USUARIO </Button>
                            </span>
                        </Header>
                        
                        <Table selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                                    <Table.HeaderCell>Apellido</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Grupo</Table.HeaderCell>
                                    <Table.HeaderCell>Rol</Table.HeaderCell>
                                    <Table.HeaderCell>Talleres</Table.HeaderCell>
                                    <Table.HeaderCell>Acciones</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderTable()}
                            </Table.Body>
                        </Table>
                    </Container>
                </Grid>
                {this.modalAdd()}
                {this.modalFilter()}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, message, open)=> dispatch( MessageToUser(title, message, open) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersScreen)