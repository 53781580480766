import { getApi } from '../../utils/Constants'

const api = getApi();
const path = 'prepaidMant/tmogController/'
const token = localStorage.getItem('token');

async function create ( body ){
    return await fetch( api + path + 'create', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer '+ token
        },
        body: JSON.stringify({
            name: body.name,
            value: body.value,
            min: body.min,
            max: body.max
        })
    } ).then( response => response.json())
    .then( success =>{
        return success
    } ).catch( error =>{
        return error
    })
}

async function read ( ){
    return await fetch( api + path + 'read', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer '+ token
        }
    } ).then( response => response.json())
    .then( success =>{
        return success
    } ).catch( error =>{
        return error
    })
}

async function update ( body ){
    return await fetch( api + path + 'update', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer '+ token
        },
        body: JSON.stringify( body )
    } ).then( response => response.json())
    .then( success =>{
        return success
    } ).catch( error =>{
        return error
    })
}

async function delet ( body ){
    return await fetch( api + path + 'delet', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer '+ token
        },
        body: JSON.stringify( body )
    } ).then( response => response.json())
    .then( success =>{
        return success
    } ).catch( error =>{
        return error
    })
}

export {
    create,
    delet,
    read,
    update
}