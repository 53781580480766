const moment = require('moment');

const initialState = {
    projection:[],
    plan:{},
    form:{},
    version:'Normal',
    status:{},
    article:'',
    codePackage:''
}

function genPackageCod(article, plan){
    let data = article.slice(0,2);
    data += plan.routine +'K';
    data += moment().format('YY');
    data += 'V01';

    return data
}

const OrderReducer = ( state = initialState, action ) => {
    switch(action.type){
        case 'SelectPackage':
            return {
                ...state,
                article: action.article,
                projection:action.projection,
                plan:action.plan,
                codePackage: genPackageCod(action.article, action.plan)
            }
        case 'SetForm':
            return {
                ...state,
                form:action.form
            }
        default: 
            return state
    }
}



export default OrderReducer;



// CODIFICACIÓN:  
// El sistema de codificación se manejará con 3 tipos de códigos: De Paquete genérico, de Paquete asociado al vehículo, y el de cada rutina incluida en el paquete. El sistema (socase) generará los códigos según corresponda. El código se compone de caracteres alfanuméricos, referenciando de la siguiente manera: 
// Código Genérico Paquete: 

// (Código modelo Vehiculo - Precedido de 0´s) + 
// (Vigencia en Kilometraje Número Abreviado en miles) + 
// (Letra K Denotación de Km) +  
// (Año venta paquete AA) + 
// (Letra V Versión) + 
// (Consecutivo de dos dígitos Versión)

// Código de Paquete vendido: Código genérico Paquete + (últimos 6 caracteres del  VIN)
// Códigos de Rutinas Incluidas a redimir: Código de Paquete vendido + (Rutina incluida Número Abreviado en miles) + (Letra K Denotación de Km)+ (Mes de vencimiento) MMM + Año Vencimiento AA)
