import React, { Component, Fragment } from 'react';
import { Grid, Table, Container, Header, Segment, Icon, Statistic, Button, Modal, Image, Tab, Form, Input, Message } from 'semantic-ui-react';
import { getOrderById, upDateOrder } from '../../../../services/orders/orderService';
import { redeemNotification } from '../../../../services/customer/customerService'
import { connect } from 'react-redux';
import { MessageToUser } from '../../../../actions';
import { getVariables } from '../../../../services/variables/variblesService';
import { getUrlFrontend, typeOptions } from '../../../../utils/Constants';
import IconStatusRedeem from '../../../../components/Order/IconStatusRedeem';
import AttachSupport from './AttachSupport';
import moment from 'moment';
const CurrencyFormat = require('react-currency-format')


class OverviewPlanScreen extends Component {
    constructor(props){
        super(props)
        this.state= { 
            order:{}, 
            iva:null, 
            openModal:false, 
            openModalFirstStep:false,
            routineSelect:{},
            kilometers:'',
            email:'',
            name:'',
            lastName:'',
            type:'',
            id:'',
            comments:'',
            dealer:'', //CL
            userName:'',
            userLastName:'',
            userEmail:'',
            index:''
        }
    }
    componentDidMount(){
        this.variables();
        if(this.props.match.params.id){
            this.getDataOrder(this.props.match.params.id.toString())
        }
        
    }

    variables = () => {
        getVariables().then( data =>{
            if(data && data.length>0){
                for(let i of data){
                    if(i.name === 'IVA'){
                        this.setState({iva:i.value/100})
                    }
                }
            }
        })
    }

    getDataOrder=(id)=>{
        let body={id:id}
        getOrderById(body)
        .then( (order)=>{
            // let temporalOrder = order;
            if(order && order.projection){
                for(let i of order.projection){
                    if(i.expirationDate < moment().subtract(24, 'hours').format()){
                        i.redeemStatus = 'expired'
                    }
                }
            }
            this.setState({ order:order})

            document.title = `Redención Plan ${order.plan.routine}K | ${order.form.plate}`
        }).catch( error=>{
            this.props.messageToUser('Lo sentimos', error.message, true)
        })
    }

    renderPlanTable =()=>{
        const { projection, plan } = this.state.order;
        
        if(projection && projection.length>0){
            return projection.map( (i, index)=>{
                if( i.routine <= plan.routine){
                    
                    return <Table.Row key={i.futureFactor+index}>
                        <Table.Cell>{i.routine}</Table.Cell>
                        <Table.Cell>
                            {/* {this.renderExpirationDate(activationDate, i.redeemStatus, i.routine)} */}
                            {i.expirationDate?moment(i.expirationDate).format('DD-MM-YYYY'):null}
                        </Table.Cell>
                        <Table.Cell>{i.dateRedeem?moment(i.dateRedeem).format('DD-MM-YYYY'):'Pendiente'}</Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.valueToRedeem} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.pParts * i.futureFactor} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> 
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={(i.pMo * i.futureFactor)} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> 
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.pSupply * i.futureFactor} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> 
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={(i.pParts+i.pMo+i.supply)* i.futureFactor + (i.valueToRedeem/(this.state.iva+1) - ((i.pParts+i.pMo+i.supply)* i.futureFactor))} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                        </Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={((i.pParts+i.pMo+i.supply)* i.futureFactor + (i.valueToRedeem/(this.state.iva+1) - ((i.pParts+i.pMo+i.supply)* i.futureFactor)))*this.state.iva} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                        </Table.Cell>
                        <Table.Cell>
                            <IconStatusRedeem status={i.redeemStatus}></IconStatusRedeem>
                        </Table.Cell>
                    </Table.Row>
                } else {
                    return null
                }
            } )
        }
    }

    // renderIconStatus = (status, item, index)=> {
        
    //     switch(status){
    //         case 'noRedeem':
    //             return (
                    
    //                 <Label style={{borderRadius:25, backgroundColor:'#666666', color:'#F6F6F6'}}> <Icon name="box" /> Pendiente</Label>
    //             )
    //         case 'redeem':
    //             return (
                    
    //                 <Label style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}}> <Icon name="check circle" /> Redimido</Label>
    //             )
    //         case 'expired':
    //             return (
                    
    //                 <Label style={{borderRadius:25, backgroundColor:'#FFA500', color:'#F6F6F6'}}> <Icon name="ban" /> Expirado</Label>

    //             )
    //         case 'returned':
    //             return(
    //                 <Label style={{borderRadius:25, backgroundColor:'#F6F6F6', color:'#454545'}}> <Icon name="alarm" /> Retornado</Label>
                    
    //             )
    //     }
    // }

    renderHeader=()=>{
        const { plan, vin, form, activationDate, expirationDate, article } = this.state.order;
        if(plan){
            return (
                <Fragment>
                    <Segment basic>
                        <Grid stackable columns={4} divided>
                            <Grid.Row>
                                <Grid.Column>
                                    <Statistic>
                                        <Statistic.Value style={{fontWeight:'bold'}}><strong>{plan.routine} K</strong></Statistic.Value>
                                        <Statistic.Label>PAQUETE ADQUIRIDO</Statistic.Label>
                                    </Statistic>
                                </Grid.Column>
                                <Grid.Column>
                                    <p>
                                        <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>VIN</span> <br/>
                                        {vin}
                                    </p>
                                    <p>
                                        <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>PLACA</span> <br/>
                                        {form.plate}
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p>
                                        <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>Fecha de activación</span> <br/>
                                        {moment(activationDate).format('DD / MM / YYYY')}
                                    </p>
                                    <p>
                                        <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>Fecha de vencimiento</span> <br/>
                                        {moment(expirationDate).format('DD / MM / YYYY')}
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p>
                                        <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>Punto de venta</span> <br/>
                                        {form.dealerData.d_name}
                                    </p>
                                    <p>
                                        <span style={{fontSize:"0.8rem", fontWeight:'bold'}}>Código Equipamiento</span> <br/>
                                        {article}
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        
                    </Segment>
                </Fragment>
            )
        }
    }

    renderModal=()=>{
        const { openModal, routineSelect, order }=this.state;
        const { form } = this.state.order
        if(form){
            let panes = [
                {
                    menuItem:{ key:'who', icon:'user', content:'Quien Redime'},
                    render:()=> this.renderFormRedeem()
                },
                { 
                    menuItem:{key:'Detalles', icon:'info', content:'Detalles'}, 
                    render: ()=> <Segment basic>
                    
                        <Table size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>ITEM</Table.HeaderCell>
                                    <Table.HeaderCell>VALOR</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Repuestos
                                    </Table.Cell>
                                    <Table.Cell>
                                        <CurrencyFormat value={routineSelect.pParts * routineSelect.futureFactor} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> 
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Mano de Obra
                                    </Table.Cell>
                                    <Table.Cell>
                                        <CurrencyFormat 
                                            value={(routineSelect.pMo * routineSelect.futureFactor) + (routineSelect.valueToRedeem/(this.state.iva+1) - ((routineSelect.pParts+routineSelect.pMo+routineSelect.supply)* routineSelect.futureFactor))} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Insumos</Table.Cell>
                                    <Table.Cell>
                                        <CurrencyFormat 
                                            value={routineSelect.supply * routineSelect.futureFactor} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat> 
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                            <Table.Body>
                                
                                <Table.Row>
                                    <Table.Cell><strong>Sub Total</strong></Table.Cell>
                                    <Table.Cell>
                                        <CurrencyFormat 
                                        value={(routineSelect.pParts+routineSelect.pMo+routineSelect.supply)* routineSelect.futureFactor + (routineSelect.valueToRedeem/(this.state.iva+1) - ((routineSelect.pParts+routineSelect.pMo+routineSelect.supply)* routineSelect.futureFactor))} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>IVA ({this.state.iva?this.state.iva*100:0}%)</Table.Cell>
                                    <Table.Cell>
                                        <CurrencyFormat 
                                        value={((routineSelect.pParts+routineSelect.pMo+routineSelect.supply)* routineSelect.futureFactor + (routineSelect.valueToRedeem/(this.state.iva+1) - ((routineSelect.pParts+routineSelect.pMo+routineSelect.supply)* routineSelect.futureFactor)))*this.state.iva} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell><strong>Total</strong></Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <CurrencyFormat value={routineSelect.valueToRedeem} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                        
                    </Segment>
                },
                {
                    menuItem:{key:'Repuestos', icon:"box", content:"Repuestos"},
                    render: ()=> <Segment basic>
                    <Table size='small' compact="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Referencia</Table.HeaderCell>
                                <Table.HeaderCell>Descripción</Table.HeaderCell>
                                <Table.HeaderCell>Cantidad</Table.HeaderCell>
                                <Table.HeaderCell>Valor</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderReferences()}
                        </Table.Body>
                    </Table>
                </Segment>
                },
                {
                    menuItem:{ key:'Soportes', icon:'attach', content:'Soportes' },
                    render:()=> <Segment basic>
                        <AttachSupport getDataOrder={()=>this.getDataOrder(this.props.match.params.id.toString())} order={order} routineSelect={routineSelect}/>
                    </Segment>
                }
            ]
            return (<Modal open={ openModal } size="small">
                <Modal.Header>
                    Mantenimiento Prepagado
                </Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Grid columns={2} stackable textAlign='center'>
                            <Grid.Row verticalAlign='middle'>
                                <Grid.Column>
                                    <Statistic>
                                        <Statistic.Value style={{fontWeight:'bold'}}><strong>{routineSelect.routine}K</strong></Statistic.Value>
                                        <Statistic.Label>RUTINA</Statistic.Label>
                                    </Statistic>
                                </Grid.Column>

                                <Grid.Column>
                                    <Image floated="right" size='large' src={`${getUrlFrontend()}models/${form.modelCod}.jpg`} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Modal.Content>
                <Modal.Content>
                    <Tab menu={{ secondary: true, pointing: true }} panes={panes}/>
                </Modal.Content>
                
                <Modal.Content>
                    <Segment basic textAlign='right'>
                        <Header >
                            <CurrencyFormat style={{textAlign:'right'}} value={routineSelect.valueToRedeem} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                            <Header.Subheader>VALOR A REDIMIR</Header.Subheader>
                        </Header>
                    </Segment>
                </Modal.Content>
                
                <Modal.Actions>
                    <Button onClick={()=>this.setState({openModal:false})} icon="times" content="Cerrar" style={{borderRadius:25}} />
                    <Button onClick={()=>this.redeemRoutine()} style={{borderRadius:25, backgroundColor:"#BB162B", color:"#f6f6f6"}} icon="save" content="Redimir"></Button>
                </Modal.Actions>
            </Modal>)
        }
        
    }

    renderReferences=()=>{
        const { routineSelect } = this.state;
        if(routineSelect && routineSelect.partsDetails){
            return routineSelect.partsDetails.map( (i)=>{
                return i.map( (j)=>{
                    return(
                        <Table.Row key={j.ref}>
                            <Table.Cell>{j.ref}</Table.Cell>
                            <Table.Cell>{j.description}</Table.Cell>
                            
                            <Table.Cell>{j.amount}</Table.Cell>
                            <Table.Cell>{Math.round(j.pvp * j.amount * routineSelect.futureFactor)}</Table.Cell>
                        </Table.Row>
                    )
                } )
            } )
        }
    }

    handledChange = ( e, {name, value}) => {
        this.setState({[name]:value})
    }
    renderFormRedeem =()=>{
        const { name, lastName, id, type, comments, kilometers, email, message } = this.state;
        return (<Segment basic>
            <Header as='h4'>
                Diligencie los siguientes datos:
            </Header>
            <Form>
                <Form.Group widths="equal">
                    <Form.Input error={message} value={kilometers} type='number' label="Kilometraje" name="kilometers"/>
                    <Form.Input onChange={this.handledChange} value={email} placeholder='dirección correo electrónico' type="email" label='Email' name="email"/>
                </Form.Group>
            </Form>
            <Header as='h4'>
                Información de quien redime
            </Header>
            <Form fluid>
                <Form.Group widths="equal">
                    <Form.Input onChange={this.handledChange} value={name} name='name' placeholder="Nombre" label="Nombre"/>
                    <Form.Input onChange={this.handledChange} value={lastName} name='lastName' placeholder="Apellido" label="Apellido"/>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Select onChange={this.handledChange} value={type} name='type' options={typeOptions} placeholder="Tipo" label="Tipo documento"/>
                    <Form.Input onChange={this.handledChange} value={id} name='id' placeholder="Identificación" label="Número de Identificación"/>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.TextArea onChange={this.handledChange} value={comments} name="comments" rows={6} placeholder="Comentarios / observaciones"></Form.TextArea>
                </Form.Group>
            </Form>
        </Segment>)
    }

    redeemRoutine = ()=> {
        const { routineSelect } = this.state;
        if(!(routineSelect.kiaOnTimeLink && routineSelect.kiaOnTimeLink.length>8)){
            return this.props.messageToUser('Información incompleta', 'Aún no se ha cargado el Link de Kia On Time, para cargarlo: dentro de la ventana emergente, en la pestaña soportes', true)
        }
        if(!(routineSelect.attach && routineSelect.attach.length>0)){
            return this.props.messageToUser('Información incompleta', 'Por favor carga la factura generada, Puedes cargar la factura dentro de la ventana emergente, en la pestaña soportes', true)
        }
        this.upDateOrder();
    }

    upDateOrder =()=>{
        const { order, routineSelect, name, lastName, id, type, comments, kilometers, index, email } = this.state;
        let temporalOrder = order;
        let k = 0;

        if(!kilometers){
            return this.props.messageToUser('Diligencie los siguientes datos', 'Complete el Kilometraje', true)
         } else {
             k = parseInt(kilometers)
             this.setState({kilometers:parseInt(kilometers)})
         }
 
         if(k > (routineSelect.routine*1000)+2500 ){
             return this.props.messageToUser('Kilometraje Excedido', 'Este vehículo ha excedido el límite de Kilometraje para esta rutina de mantenimiento, puede realizar la siguiente rutina', true)
         }

        if(!name){
            return this.props.messageToUser('Diligencie los siguientes datos', 'Complete el nombre', true)
        }
        if(!email){
            return this.props.messageToUser('Diligencie los siguientes datos', 'Complete el correo electrónico', true)
        }
        if(!lastName){
            return this.props.messageToUser('Diligencie los siguientes datos', 'Complete el apellido', true)
        }
        if(!type){
            return this.props.messageToUser('Diligencie los siguientes datos', 'Elija un tipo de documento de identidad', true)
        }
        if(!id){
            return this.props.messageToUser('Diligencie los siguientes datos', 'Complete el número de identificación', true)
        }
        
        temporalOrder.projection[index]['customerName'] = name;
        temporalOrder.projection[index]['customerLastName'] = lastName;
        temporalOrder.projection[index]['customerId'] = id;
        temporalOrder.projection[index]['customerTypeId'] = type;
        temporalOrder.projection[index]['customerEmail'] = email;
        temporalOrder.projection[index]['comments'] = comments;
        temporalOrder.projection[index]['kilometers'] = kilometers;
        temporalOrder.projection[index]['dealer'] = this.props.user.dealer[0];
        temporalOrder.projection[index]['userName'] = this.props.user.name;
        temporalOrder.projection[index]['userLastName'] = this.props.user.last_name;
        temporalOrder.projection[index]['userEmail'] = this.props.user.email;
        
        
        // Redeem Control
        temporalOrder.projection[index]['dateRedeem'] = new Date( Date.now() );
        temporalOrder.projection[index]['adviserRedeem'] = this.props.user.email;
        temporalOrder.projection[index]['redeemStatus'] = 'redeem';
        temporalOrder.projection[index]['dealerRedeem'] = this.props.user.dealer[0];

        this.setState({order:temporalOrder}, ()=>{
            upDateOrder(this.state.order).then( (data)=>{
                this.setState({openModal:false})
                redeemNotification({order:this.state.order, routine:this.state.order.projection[index] })
                .then( result =>{
                    console.log(result)
                } )
                this.props.messageToUser(`Redención exitosa`, `Se ha redimido exitosamente la rutina de ${routineSelect.routine*1000}`, true)
            } )
        })

    }


    renderModalFirstStep = () =>{
        const {openModalFirstStep, kilometers} = this.state;

        return <Modal open={openModalFirstStep}>
            <Modal.Header> Validación </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Segment placeholder>
                        <Header as="h1" style={{textAlign:'justify'}}>
                            <strong style={{color:'#bb162b'}}>IMPORTANTE: </strong> <br></br> 
                            <Header.Subheader style={{color:'#454545', textAlign:'justify'}}>
                                
                                El mantenimiento debe ser ralizado bajo los lineamientos establecidos en el <strong>Manual de Garantías Kia: </strong> 
                                +/- 500 km. de acuerdo a la rutina a realizar. Ejemplo: el mantenimiento obligatorio de 10.000 km, se debe hacer entre
                                9.500 y 10.500 km.
                                
                            </Header.Subheader>
                        </Header>
                    </Segment>
                    <Segment>
                        <Header as="h5">
                            {kilometers? <CurrencyFormat value={kilometers} thousandSeparator={true} displayType={'text'} decimalScale={0}></CurrencyFormat>:`Diligencie el kilometraje`}
                            {kilometers? ' km':null}
                        </Header>
                        <Segment.Inline>
                            <Input onChange={this.handledChange} type="number" name="kilometers" placeholder="0" label="Kilometraje" />
                        </Segment.Inline>
                    </Segment>
                    
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={()=> this.setState({openModalFirstStep:false})} icon="times" content="CANCELAR" style={{borderRadius:25}} />
                <Button onClick={()=>this.validationRedeem()} icon="check circle" content="SIGUIENTE" style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}}/>
            </Modal.Actions>
        </Modal>
    }

    validationRedeem = () => {
        const { kilometers } = this.state;
        const { projection, plan } = this.state.order;


        for(let i of projection){

            if(kilometers > (plan.routine*1000)+2500){
                return this.props.messageToUser(
                    'Fuera de cobertura', 
                    `Este vehículo ha sobre pasado el límite de kilometraje: ${plan.routine*1000+2500} km, para redimir su plan de mantenimientos ${kilometers} km`, 
                    true)
            }
            
            if((i.routine*1000)-2500 < kilometers && (i.routine*1000)+2500 >= kilometers){
                
                let index = projection.indexOf(i)

                if(i.redeemStatus === 'noRedeem'){
                    this.setState({routineSelect:i, openModal:true, index:index })
                } else if(i.redeemStatus === 'expired') {
                    this.props.messageToUser('Esta rutina ha expirado', `No es posible redimir esta rutina: La Rutina de mantenimiento ${i.routine*1000} km ha expirado`, true)
                } else if(i.redeemStatus === 'redeem') {
                    this.props.messageToUser('Esta rutina ya ha sido redimida', `No es posible redimir esta rutina: La Rutina de mantenimiento ${i.routine*1000} km ha sido redimida`, true)
                }
            } 
        }

    }
    


    render(){
        const { adviser } = this.state.order
        return (
            <Fragment>
                <Grid style={{minHeight:"80vh", marginTop:'8vh'}}>
                <Container style={{marginTop:40, marginBottom:30, minHeight:'63vh'}}>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        REDENCIÓN MANTENIMIENTOS
                    </Header>
                    {this.renderHeader()}
                    {adviser==='IMPORTADOR'?<Segment basic>
                        <Message error>
                            <Message.Header> IMPORTANTE</Message.Header>
                            <Message.Content>
                                Este Plan de Mantenimiento Prepagado fue otorgado a través de una acción comercial.
                            </Message.Content>
                        </Message>
                    </Segment>:null}
                    <Segment basic>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Rutina</Table.HeaderCell>
                                    <Table.HeaderCell>Vence</Table.HeaderCell>
                                    <Table.HeaderCell>Redimida</Table.HeaderCell>
                                    <Table.HeaderCell>Valor Rutina</Table.HeaderCell>
                                    <Table.HeaderCell colSpan={1}>Repuestos</Table.HeaderCell>
                                    <Table.HeaderCell>Mano de Obra</Table.HeaderCell>
                                    <Table.HeaderCell>Insumos</Table.HeaderCell>
                                    <Table.HeaderCell>SubTotal</Table.HeaderCell>
                                    <Table.HeaderCell>IVA</Table.HeaderCell>
                                    <Table.HeaderCell>Estatus</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderPlanTable()}
                            </Table.Body>
                        </Table>

                        <Button onClick={()=>this.setState({openModalFirstStep:true})} style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}}>
                            <Icon.Group size='medium'>
                                <Icon name='box' />
                                <Icon corner='bottom right' name='add' color="red" />
                            </Icon.Group>
                            REDIMIR
                        </Button>
                    </Segment>
                </Container>
                </Grid>
                {this.renderModal()}
                {this.renderModalFirstStep()}
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        messageToUser: (title, message, open)=>dispatch(MessageToUser(title, message, open))
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(OverviewPlanScreen)