import React, { Component } from 'react';
import { Step } from 'semantic-ui-react';



class StepComponent extends Component {
    render(){
        const { a, b, c, d } = this.props;
        return (
            <Step.Group widths={4}>
                <Step active={a} title='Instrucciones' description='Tenga en cuenta' />
                <Step active={b} title='Paso 1' description='Complete'/>
                <Step active={c} title='Paso 2' description='Descargue' />
                <Step active={d} title='Paso 3' description='Cargue' />
            </Step.Group>
        )
    }
}

export default StepComponent