import { API } from '../constants';
const path = 'signin';

export default async (body)=>{
    return await fetch(API+path, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        },
        body: JSON.stringify({
            email: body.email,
            password:body.password
        })
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        if(responseJSON.message === 'Te has logueado correctamente'){
            localStorage.setItem('token', responseJSON.token)
            localStorage.setItem('userEmail', responseJSON.userEmail)
        }
        return responseJSON
    } ).catch( (error)=> {
        
        return error
    } )
}