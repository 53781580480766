import React, { Component } from 'react';
import { Grid, Container, Button, Icon, Header } from 'semantic-ui-react';


import * as jsPDF  from 'jspdf';
import  'jspdf-autotable'
import image2base64 from 'image-to-base64';
import PreloaderComponent from '../../../../components/PreloaderComponent';
import moment from 'moment';
import { connect } from 'react-redux';
import { MessageToUser } from '../../../../actions';
import { toast } from 'react-semantic-toasts';
import "react-datepicker/dist/react-datepicker.css";
import { read } from '../../../../services/complementary/complementaryServices';

const CurrencyFormat = require('react-currency-format')

const pageOne = require('../../../../assets/agreement/1.jpg')
const pageTwo = require('../../../../assets/agreement/2.jpg')
const pageThree = require('../../../../assets/agreement/3.jpg')
const pageFour = require('../../../../assets/agreement/4.jpg')
const pageFive = require('../../../../assets/agreement/5.jpg')
const pageSix = require('../../../../assets/agreement/6.jpg')
const pageSeven = require('../../../../assets/agreement/7.jpg')
const pageEight = require('../../../../assets/agreement/8.jpg')
const pageNine = require('../../../../assets/agreement/9.jpg')
const pageTen = require('../../../../assets/agreement/10.jpg')
const pageEleven = require('../../../../assets/agreement/11.jpg')
const pageTwelve = require('../../../../assets/agreement/12.jpg')
const pageThirteen = require('../../../../assets/agreement/13.jpg')




class DeliveryScreen extends Component {
    constructor(props){
        super(props)
            this.state = { 
                loading:true,
                order:{},
                pageOne:'',
                pageTwo:'',
                pageThree:'',
                pageFour:'',
                pageFive:'',
                pageSix:'',
                pageSeven:'',
                pageEight:'',
                pageNine:'',
                pageTen:'',
                pageEleven:'',
                pageTwelve:'',
                pageThirteen:'',
                // pageFourteen:'',
                downloading:false,
                signaturePosition:'GERENCIA POSVENTA',
                complementary:[]
            }
    }

    componentDidMount(){

        image2base64(pageOne).then( (response) => { this.setState({pageOne:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageTwo).then( (response) => { this.setState({pageTwo:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageThree).then( (response) => { this.setState({pageThree:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageFour).then( (response) => { this.setState({pageFour:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageFive).then( (response) => { this.setState({pageFive:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageSix).then( (response) => { this.setState({pageSix:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageSeven).then( (response) => { this.setState({pageSeven:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageEight).then( (response) => { this.setState({pageEight:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageNine).then( (response) => { this.setState({pageNine:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageTen).then( (response) => { this.setState({pageTen:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageEleven).then( (response) => { this.setState({pageEleven:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(pageTwelve).then( (response) => { this.setState({pageTwelve:response}) } ).catch( (error) => { console.log(error);  } )
        image2base64(`http://localhost:3000/signature/CL001.png`).then( (response) => { this.setState({signature:response}) } ).catch( (error) => { console.log(error);  } )

        image2base64(pageThirteen).then( (response) => { 
            this.setState({
                pageThirteen:response,
                order:this.props.order,
                wayToPay:this.props.order.wayToPay,
                plate:this.props.order.form.plate,
                loading:false}, () => { this.getComplementaryServices() } ) } )
         .catch( (error) => { console.log(error);  } )
    }

    getComplementaryServices = () => {
        const {dealerData } = this.state.order.form
        read({group: dealerData.group_dealer})
        .then( data => {
            console.log(data)
            this.setState({ complementary:data })
        })
    }
    
    generateDoc = () =>{
        this.MessageToast('info', 'Generando Acuerdo', 'Por favor espero unos segundos', 'download')
        moment.locale('es')
        const { 
            pageOne, 
            pageTwo,
            pageFour,
            pageFive,
            pageSix,
            pageSeven,
            pageEight,
            pageNine,
            pageTen,
            pageEleven,
            pageTwelve,
            pageThirteen,
            today,
            signaturePosition,
            complementary } = this.state;
        const { name, lastName, dealerName, cityDealer, plate, vin, packageValue, ni, dealerData } = this.state.order.form;
        const { wayToPay, projection, plan } = this.state.order;
        const { routine, admonCost } = this.state.order.plan;
        let kilometersLimit = (plan.routine+2.5)*1000

        var doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true
        });
        doc.addImage(pageOne, 'PNG', 0, 0, 209, 296, 'PAGE1', 'FAST', 0);
        
        doc.addPage();
        doc.addImage(pageTwo, 'PNG', 0, 0, 209, 296, 'PAGE2', 'FAST',0);
        
        doc.text(`${name} ${lastName}`, 50, 60);
        doc.text(`${dealerName}`, 50, 75);
        doc.text(`${dealerData.group_dealer}`, 110, 68);
        doc.text(`${cityDealer}`, 135, 75);
        doc.text(`${vin}`, 50, 82);
        doc.text(`${plate}`, 135, 82);
        
        //WaytoPay
        switch(wayToPay){
            case 'C':
                doc.text("x", 49.5, 88);
                break;
            case 'F':
                doc.text("x", 49.5, 94);
                break;
            case 'I':
                doc.text("x", 49.5, 99);
                break;
            default: doc.text("x", 49.5, 88);
        }
        // Package
        doc.text("x", 71 + (((routine/5)-2)*13.3), 115)
        

        
        doc.setFontSize(14)
        doc.text(`${packageValue.toLocaleString()}`, 135, 98)
        doc.setFontSize(11)
        doc.text(`${moment(today).format('DD/MM/YYYY')}`, 25, 204.5)
        // doc.text(`${moment(expirationDate).format('DD/MM/YYYY')}`, 63, 209.5)
        doc.text(`${kilometersLimit.toLocaleString()}`, 153, 209.5)
        doc.text(`${ni}`, 53, 260.5)
        doc.setFontSize(8)
        doc.text(`${signaturePosition}`, 136, 260.5)
        doc.text(`${moment(today).format('DD/MM/YYYY')}`, 136, 266.5);
        
        // // doc.addImage(signature, 'PNG', 125, 240, 43.2, 16.8, 'SIGNATURE', 'FAST', 0)
        

        doc.addPage();
        doc.addImage(pageFour, 'PNG', 0, 0, 209, 296, 'PAGE4', 'FAST',0);
        doc.setFontSize(10)
        

        doc.addPage();
        doc.addImage(pageFive, 'PNG', 0, 0, 209, 296, 'PAGE5', 'FAST',0);
        doc.text(`${dealerData.group_dealer}`, 54.5, 49)

        doc.addPage();
        doc.addImage(pageSix, 'PNG', 0, 0, 209, 296, 'PAGE6', 'FAST',0);
        

        doc.addPage();
        doc.addImage(pageSeven, 'PNG', 0, 0, 209, 296, 'PAGE7', 'FAST',0);
        

        doc.addPage();
        doc.addImage(pageEight, 'PNG', 0, 0, 209, 296, 'PAGE8', 'FAST',0);
        doc.text(`$ ${Math.round(admonCost).toLocaleString()}`, 50, 155)
        projection.map((i, index)=>{
            if( i.routine <= routine){
                return doc.text(`$ ${Math.round(i.valueToRedeem).toLocaleString()}`, 135, 177 +(index*10) )
            } else {
                return null
            }
        })

        doc.addPage();
        doc.addImage(pageNine, 'PNG', 0, 0, 209, 296, 'PAGE9', 'FAST',0);

        

        doc.addPage();
        doc.addImage(pageTen, 'PNG', 0, 0, 209, 296, 'PAGE10', 'FAST',0);
        

        doc.addPage();
        doc.addImage(pageEleven, 'PNG', 0, 0, 209, 296, 'PAGE11', 'FAST',0);

        doc.addPage();
        doc.addImage(pageTwelve, 'PNG', 0, 0, 209, 296, 'PAGE12', 'FAST',0);
        

        doc.addPage();
        doc.addImage(pageThirteen, 'PNG', 0, 0, 209, 296, 'PAGE13', 'FAST',0);
        doc.text(`${dealerData.group_dealer}`, 130, 49)
        let textComplementary = [];
        if(complementary && complementary.length){
            
            for(let i of complementary){
                if(i.active){
                    textComplementary.push([
                        i.service,
                        i.frequency,
                        i.amount.toString(),
                        i.terms.toString()
                    ])
                }
                
            }
        }
        

        doc.autoTable({
            startY:90,
            margin:{left:20, right:18, bottom:40},
            columnStyles:{2:{ cellWidth:20}},
            headStyles:{ fillColor:"#bb162b"},
            styles:{ fontSize:8, cellWidth:'auto', minCellWidth:20, halign:'left', valign:'middle' },
            head: [['Servicio o beneficios', 'Frecuencia de uso', 'Cantidad de eventos incluidos', 'Términos y condiciones']],
            body: textComplementary
        });
       

                

        doc.save(`ACUERDO-MANTPRE-${vin}`, { returnPromise:true})
        .then( resultPdf=>{
            this.setState({downloading:false})
        }, error=>{
            this.props.messageToUser('Error al descargar el PDF', 'Ocurrió un error al descargar el PDF, por favor intente cerrar sesión e intentarlo nuevamente', true)
            this.setState({downloading:false})

        })
        // window.open(doc.output('bloburl'), '_blank');
        
        
    }

    MessageToast = (type, title, message, icon) =>{
        toast({
            type: type,
            icon: icon,
            title: title,
            description: message,
            animation: 'bounce',
            time: 5000,
        })
    }


    render(){
        const { loading, order, downloading,  } = this.state;
        const { back, next } = this.props;
        console.log(this.state.order)
        if(loading){
            return <PreloaderComponent/>
        }
        return(
            <Grid style={{marginTop:30, marginBottom:50 }}>
                <Container fluid>
                    <Header as='h1'>
                        {order.form.vin}
                        <Header.Subheader>
                            PAQUETE ADQUIRIDO {order.plan.routine} <span style={{color:'#bb162b'}}>K</span>
                        </Header.Subheader>
                    </Header>
                    <Header>
                        <Header.Subheader>
                            Cliente {order.form.name+' '+order.form.lastName}  <br/>
                            Tipo Id. {order.form.typeId}  <br/>
                            Identificación. {order.form.ni}  <br/>
                        </Header.Subheader>
                    </Header>
                    <Header>
                        <Header.Subheader>
                            Total rutinas {order.plan.routine/5} <br/>
                            Valor de Plan <CurrencyFormat value={order.plan.pAccumulated} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat><br/>
                            Fecha de entrega {moment(order.activationDate).format('DD/MM/YYYY')} <br/>
                            Fecha de expiración {moment(order.expirationDate).format('DD/MM/YYYY')} <br/>
                        </Header.Subheader>
                    </Header>
                    <Button style={{borderRadius:25}} onClick={back}><Icon name="chevron circle left"/> ATRAS </Button>
                    <Button 
                        style={{backgroundColor:'#BB162B', color:''}}
                        loading={downloading} 
                        icon="file pdf" 
                        content='Generar acuerdo'
                        onClick={()=>{this.setState({downloading:true}, ()=> setTimeout( ()=>{this.generateDoc()}, 1500 ) )}}
                        style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}} />
                    <Button style={{borderRadius:25}} onClick={next}> SIGUIENTE <Icon name="chevron circle right"/></Button>
                </Container>
            </Grid>
                
        )
    }
}

const mapStateToProps = state => {
    return {
        message: state.message
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, message, open)=> dispatch(MessageToUser(title, message, open))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryScreen)