import React, { Component, Fragment } from 'react';
import { Grid, Image, Form, Segment, Button, Message } from 'semantic-ui-react';

import imgBackGround from '../../assets/background/bg.jpg';
import { getApi } from '../../utils/Constants';
import logo from '../../assets/logo_color.png';

class HomePublicScreen extends Component {
    state = {
        in:'',
        id:'',
        plate:'',
        vin:'',
        message:'',
        error:false
    }

    handledChange = (e, { name, value })=>{
        if(name === 'in'){
            if(value && value.length === 6){
                this.setState({plate: value})
            } else if(value && value.length === 17 ){
                this.setState({vin:value})
            }
        } else {
            this.setState({[name]: value})
        }
    }

    signIn= async ()=>{
        const { plate, vin, id } = this.state;
        
        return await fetch(`${getApi()}prepaidMant/customer/customerInfo?vin=${vin}&plate=${plate}&id=${id}`, {
            method:'GET',
            headers:{
                'Accept':'application/json',
                'Conent-Type':'application/json'
            }
        }).then( (result)=> result.json() )
        .then( resultJson => {
            console.log(resultJson)
            if(resultJson.status === 200){
                this.props.history.push(`/viewCustomer/${resultJson.data.vin}/${resultJson.data.form.ni}`)
            } else {
                this.setState({message:resultJson.msg, error:true})
            }
            
        } ).catch( error => {
            console.log(error)
        } )
    }

    render(){
        const { message, error } = this.state;
        console.log(this.state)
        return(
            <Fragment>
                <Grid  textAlign='left' centered verticalAlign="middle" style={{backgroundColor:"light"}}>
                    <Grid.Row width={16} style={{minHeight:"82vh", backgroundImage:`url(${imgBackGround})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>

                        <Grid.Column style={{maxWidth:450}}>
                            <Form size='large' error={error}>
                                <Segment size="large" style={{backgroundColor:'transparent'}}>
                                        
                                        <Image size="huge" src={logo} />
                                        <Form.Input inverted onChange={this.handledChange} fluid iconPosition="left" icon="car" name="in" placeholder="Vin o Placa"  />

                                        <Form.Input onChange={this.handledChange} fluid iconPosition="left" icon="address card outline" name="id" placeholder="Número de identidad" />
                                        {error?<Message
                                            size='small'
                                            onDismiss={()=>this.setState({error:false, message:''})}
                                            error={error}
                                            header='Lo sentimos'
                                            content={message}
                                            />:null}
                                    <Button onClick={()=> this.signIn()} icon="sign-in" content="ACCEDER" style={{ backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25}} />
                                </Segment>
                                
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Fragment>
        )
    }
}

export default HomePublicScreen