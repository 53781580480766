import React, { Component, Fragment } from 'react';
import { Segment, Header, Form, Label, Icon, Button, Table, Modal, Popup} from 'semantic-ui-react';
import { InputFile } from 'semantic-ui-react-input-file';
import { uploadFile, upDateOrder } from '../../../../services/orders/orderService';
import { domain } from '../../../../constants';
import { connect } from 'react-redux';
import { MessageToUser } from '../../../../actions';
import { toast } from 'react-semantic-toasts'; 

const attachType = [
    { key:'otros', text:'Otros', value:'other' },
    { key:'form', text:'Formulario', value:'form' },
    { key:'vehicleOrder', text:'Pedido del vehículo', value:'vehicleOrder' },
    { key:'packageBill', text:'Comprobante Pago Plan', value:'packageBill' },
    { key:'vehicleOrderBill', text:'Comprobante Pago Separación vehículo', value:'vehicleOrderBill' },
    { key:'documentId', text:'Documento identidad Cliente', value:'documentId' },
    { key:'agreement', text:'Acuerdo Mantenimiento Prepagado', value:'agreement'}
]

class AttachComponent extends Component {
    constructor(props){
        super(props)
        this.state = { 
            order:{},
            fileSelect:null,
            fileName:'',
            title:'',
            comments:'',
            seeFormAttach:false,
            openModalComment:false,
            itemComment:'',
            itemIndex:0,
            validator:''
         }
    }
    componentDidMount(){
        this.setState({order:this.props.order})
    }
    handledChange = (e, { name, value})=>{
        this.setState({[name]:value})
    }
    handleUpload = (e)=>{
        this.setState({ fileSelect:e.target.files[0], fileName:e.target.files[0].name })
    }

    uploadFile =()=>{
        
        const { order, title, comments, dealer, fileSelect } = this.state;
        if(!fileSelect){
            return this.props.messageToUser('Falta el archivo', 'No se elijió ningun archivo, elija el documento a cargar', true)
        }
        let temporalOrder = order;
        const formData = new FormData()
        formData.append('file', this.state.fileSelect);
        let param = {
            dealer:this.props.order.dealer,
            title: this.state.title
        }
        uploadFile(formData, param)
        .then( data =>{
            temporalOrder.attach.push({
                title:title,
                comments:comments,
                url:`${domain}${order.dealer}/${data.filename}`,
                name:data.originalname,
                checked:false,
                checkedImport:false,
                create_at:new Date(Date.now()),
                response:{ status:'Pendiente', date:new Date(Date.now()), comments:'' },
                responseImport:{ status:'Pendiente', date:new Date(Date.now()), comments:'' }
            })
            this.setState({order:temporalOrder}, ()=>{ this.updateOrder()})
        }).catch(error =>{
            console.log(error)
        })
    }

    updateOrder=()=>{
        const { order } = this.state;
        upDateOrder( order )
        .then( data =>{
            this.MessageToast('info', 'Genial', 'Se actualizado la orden con éxito', 'database')
            this.setState({
                seeFormAttach:false,
                fileSelect:null,
                fileName:'',
                title:'',
                comments:'',
            })
        }).catch(error =>{
            this.props.messageToUser('Los sentimos', 'Ocurrió un error al actualizar la orden', true)
            console.log(error)
        })
    }

    // download = (url) =>{
    //     setTimeout(()=>{
    //         const response = {
    //             file:url
    //         }
    //         window.open(response.file)
    //     },)
    // }


    renderAttach = () => {
        const { attach } = this.state.order;
        if(attach && attach.length>0){
            return attach.map( (i, index)=>{
                return <Table.Row key={index+i.url}>
                    <Table.Cell>
                        {attachType.map((j)=>{
                            if(i.title === j.value){
                                return j.text;
                            }
                        })}
                    </Table.Cell>
                    <Table.Cell>
                        <Button 
                            icon="eye"
                            size="tiny" 
                            as="a" 
                            download 
                            crossOrigin="anonymous" 
                            target="_blank" 
                            href={i.url}/></Table.Cell>
                    <Table.Cell>{i.name}</Table.Cell>
                    <Table.Cell>{i.comments}</Table.Cell>
                    <Table.Cell>
                        <Label 
                            circular
                            size="tiny" 
                            color={i.checked?'blue':'orange'}> CON <Label.Detail>{i.checked?'REV':'PEN'}</Label.Detail></Label>
                    </Table.Cell>
                    <Table.Cell>
                        <Label size="tiny" color={i.checkedImport?'blue':'orange'}> IMP <Label.Detail>{i.checkedImport?'REV':'PEN'}</Label.Detail></Label>
                    </Table.Cell>
                    <Table.Cell>
                        {i.response.status==='Pendiente'?
                            <Label size="tiny"color="orange">Pendiente</Label>:
                            <Label size="tiny" color={i.response.status==='Verificado'?'green':'red'}>{i.response.status}</Label>}
                    </Table.Cell>
                    <Table.Cell>
                        <Popup header="Comentario" content={i.response.comments} trigger={<Icon color="blue" name="comment"/>}></Popup>

                    </Table.Cell>

                    <Table.Cell>
                        
                        {i.responseImport.status==='Pendiente'?
                            <Label size="tiny" color='orange'>{i.responseImport.status}</Label>:
                            <Label size="tiny" color={i.responseImport.status==='Verificado'?'green':'red'}>{i.responseImport.status}</Label>}
                    </Table.Cell>
                    <Table.Cell>
                        {/* {i.responseImport.comments} */}
                        <Popup header="Comentario" content={i.responseImport.comments} trigger={
                            // <Icon color="blue" name="comment"/>
                            <Icon.Group size='normal'>
                                <Icon name='comment' color="blue" />
                                {i.responseImport.comments.length>0?<Icon corner='bottom right' name='bell' color="yellow" />:null}
                            </Icon.Group>
                            }></Popup>
                    </Table.Cell>


                    {this.props.user.role==='dealers_manager'?<Table.Cell>
                        <Button.Group size="tiny">
                            <Button color={i.response.status==='Rechazado'?'red':'grey'} onClick={()=>this.setResponseAttach(index, 'Rechazado')} icon="times"></Button>
                            <Button color={i.response.status==='Verificado'?'teal':'grey'} onClick={()=>this.setResponseAttach(index, 'Verificado')} icon="check"></Button>
                        </Button.Group>
                    </Table.Cell>:null}
                    {this.props.user.role==='dealers_manager'?<Table.Cell>
                        <Button size="tiny" icon="comment" onClick={()=>this.setState({itemIndex:index, itemComment:i.response.comments, openModalComment:true, validator:'response'})} />
                    </Table.Cell>:null}

                    {/* Acciones Importadora */}

                    {this.props.user.role==='manager'?<Table.Cell>
                        <Button.Group size="tiny">
                            <Button color={i.responseImport.status==='Rechazado'?'red':'grey'} onClick={()=>this.setImportResponseAttach(index, 'Rechazado')} icon="times"></Button>
                            <Button color={i.responseImport.status==='Verificado'?'teal':'grey'} onClick={()=>this.setImportResponseAttach(index, 'Verificado')} icon="check"></Button>
                        </Button.Group>
                    </Table.Cell>:null}
                    {this.props.user.role==='manager'?<Table.Cell>
                        <Button size="tiny" icon="comment" onClick={()=>this.setState({itemIndex:index, itemComment:i.responseImport.comments, openModalComment:true, validator:'responseImport'})} />
                    </Table.Cell>:null}
                </Table.Row>
            } )
        }
    }

    setResponseAttach=(index, status)=>{
        const { order } = this.state;
        let temporalOrder = order;

        temporalOrder.attach[index].response.status = status;
        temporalOrder.attach[index].checked = true;
        this.setState({order:temporalOrder}, ()=>{
            this.updateOrder();
        })
    }

    setImportResponseAttach=(index, status)=>{
        const { order } = this.state;
        let temporalOrder = order;

        temporalOrder.attach[index].responseImport.status = status;
        temporalOrder.attach[index].checkedImport = true;
        this.setState({order:temporalOrder}, ()=>{
            this.updateOrder();
        })
    }

    setResponseComment=()=>{
        
        const { order, itemComment, itemIndex, validator } = this.state;
        let temporalOrder = order;
        if(validator === 'response'){
            temporalOrder.attach[itemIndex].response.comments = itemComment;
        } else if(validator === 'responseImport'){
            temporalOrder.attach[itemIndex].responseImport.comments = itemComment;
        }

        this.setState({order:temporalOrder}, ()=>{
            this.updateOrder();
        })
    }

    renderModalComment=()=>{
        const { openModalComment, itemComment } = this.state;

        return (
        <Modal open={openModalComment}>
            <Modal.Header><Icon name="comment"/> Comentario</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.TextArea onChange={(e,{value})=>this.setState({itemComment:value})} value={itemComment} label="Comentaios" placeholder="Observaciones al revisar los soportes adjuntos"></Form.TextArea>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={()=>this.setState({openModalComment:false})}><Icon name="times"/> Cerrar </Button>
                <Button primary onClick={()=>this.setResponseComment()}><Icon name="save"/>Guardar Comentarios</Button>
            </Modal.Actions>
        </Modal>)
    }

    MessageToast = (type, title, message, icon) =>{
        toast({
            type: type,
            icon: icon,
            title: title,
            description: message,
            animation: 'bounce',
            time: 5000,
        })
    }

    modalFormAttach = () => {
        const { seeFormAttach, fileName } = this.state;

        return <Modal open={seeFormAttach}>
            <Modal.Header>
                CARGAR SOPORTES
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Select onChange={this.handledChange} name="title" placeholder='Por favor elija el tipo de soporte' label='Soporte' options={attachType}/>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input fluid onChange={this.handledChange} name="comments" label="Comentarios" placeholder="Commentarios" ></Form.Input>
                    </Form.Group>
                    <InputFile
                        button={{ label: fileName? fileName:'Elige un archivo'}}
                        input={{
                            id:'input-upload-id',
                            onChange:this.handleUpload
                        }}
                    />
                    
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    size="tiny"
                    content="CANCELAR"
                    icon="times"
                    style={{ borderRadius:25 }}
                    onClick={()=> this.setState({seeFormAttach:false})} 
                />
                <Button
                    size="tiny"
                    content="CARGAR"
                    icon="upload"
                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                    onClick={ ()=>this.uploadFile()} 
                />
            </Modal.Actions>
        </Modal>
    }

    render(){
        const { order:{attach}, seeFormAttach, itemComment } = this.state;
        
        return( 
            <Fragment>
                <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                    SOPORTE
                </Header>
                
                <Button 
                    content="CARGAR SOPORTE"
                    icon="attach"
                    size="tiny"
                    style={{ borderRadius:25, backgroundColor:"#BB162B", color:"#f6f6f6"}}
                    onClick={()=> this.setState({seeFormAttach:!seeFormAttach})}/>

                {attach && attach.length>0?<Table size='small' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Soporte</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">Archivo</Table.HeaderCell>
                            <Table.HeaderCell>Comentarios</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">Revisado</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">Respuesta Concesionario</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">Respuesta Importadora</Table.HeaderCell>
                            {this.props.user.role==='dealers_manager'?<Table.HeaderCell colSpan="2">Concesionario</Table.HeaderCell>:null}
                            {this.props.user.role==='manager'?<Table.HeaderCell colSpan="2">Importadora</Table.HeaderCell>:null}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderAttach()}
                    </Table.Body>
                </Table>:
                <Segment placeholder>
                    <Header icon>
                        <Icon name='attach' />
                        Aún no hay soportes adjuntos
                        <Header.Subheader>
                            Puede adjuntar soportes haciendo click en siguiente botón
                        </Header.Subheader>
                    </Header>
                    <Button primary onClick={()=>this.setState({seeFormAttach:true})}>Agregar soporte</Button>
                </Segment>}
                {this.renderModalComment()}
                {this.modalFormAttach()}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, message, open) => dispatch(MessageToUser(title, message, open))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachComponent)