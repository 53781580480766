import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { GetUserByEmail } from '../../actions';

import NavBarComponent from '../../components/NavBarComponent';
import ModelsScreen from '../LoggedIn/manager/ModelsScreen';
import ReferecensScreen from './manager/references/ReferencesScreen';
import VariableScreen from './manager/variables/VariableScreen';
import PrepaidScreen from '../../screens/LoggedIn/manager/prepaid/PrepaidScreen';
import SimulatorScreen from './adviser/SimulatorScreen';
import CarShopScreen from './adviser/CarShopScreen';
import OrdersScreen from './adviser/OrdersScreen';
import WorkOnOrderScreen from './adviser/WorkOnOrderScreen';
import LandingScreen from './LandingScreen';
import PreloaderComponent from '../../components/PreloaderComponent';
import FooterComponent from '../../components/FooterComponent';
import DeliveryScreen from './adviser/order/DeliveryScreen';
import RedentionScreen from './adviser/RedentionScreen';

import { Route, Redirect, HashRouter, Switch } from 'react-router-dom';
import OverviewPlanScreen from './adviser/redeem/OverviewPlanScreen';
import AllVersionsScreen from './manager/offert/AllVersions';
import UsersScreen from './manager/users/UsersScreen';
import ComplementaryServices from './manager/complementary/ComplementaryServices';
import ReportsScreem from './manager/reports/reportsScreen';
import CommercialActionsScreen from './manager/commercialActions/CActionsScreen';
import UploadReportScreen from './manager/uploadreport/uploadReportScreen';
import Tmog from './manager/tmog/tmog';
import SalesCommision from './manager/salesCommision/SalesCommision';
import VinsScreen from './manager/vins/vinScreen';
import DealersScreen from './manager/dealers/Dealers';



class HomeScreen extends Component {

    async componentWillMount(){
        
        const email = await localStorage.getItem('userEmail')
        const token = await localStorage.getItem('token')
        this.props.getUserByEmail({email:email, token:token})
    }

    Home=()=>{
        return <LandingScreen/>
    }
    Delivery=()=>{
        return <DeliveryScreen/>
    }
    Dealers = () => {
        return <DealersScreen/>
    }
    Orders=()=>{
        return <OrdersScreen/>
    }
    Quotation=()=>{
        return <SimulatorScreen/>
    }
    Form=()=> {
        return <CarShopScreen/>
    }
    Order=({match})=>{
        return <WorkOnOrderScreen match={match}/>
    }
    Redention=()=>{
        return <RedentionScreen />
    }
    OverviewPlan=({match})=>{
        return <OverviewPlanScreen match={match}/>
    }
    // Activation=()=>{
    //     return <DeliveryScreen/>
    // }

    // Admin Section
    Models=()=>{
        return <ModelsScreen></ModelsScreen>
    }
    Parts=()=>{
        return <ReferecensScreen></ReferecensScreen>
    }
    Variables=()=>{
        return <VariableScreen></VariableScreen>
    }
    Tempario=()=>{
        return <PrepaidScreen></PrepaidScreen>
    }
    Offert = ()=> {
        return <AllVersionsScreen/>
    }

    Users = () => {
        return <UsersScreen />
    }

    Complementary = ()=>{
        return <ComplementaryServices/>
    }

    Reports = () => {
        return <ReportsScreem />
    }

    CommercilActions = () => {
        return <CommercialActionsScreen/>
    }

    UploadReport = () => {
        return <UploadReportScreen />
    }

    TMOG = () => {
        return <Tmog />
    }

    SalesCo = () => {
        return <SalesCommision />
    }

    Vins = () => {
        return <VinsScreen />
    }


    render(){
        if(this.props.loading){
            return <PreloaderComponent/>
        }
        return (
            <Grid>
                <Grid.Column verticalAlign="top" style={{ justifyContent:'center', paddingTop:"10vh"}}>
                    
                    <HashRouter basename='/kiausers'>
                        <NavBarComponent></NavBarComponent>
                        <Switch>
                            <Route exact path="/Home" component={this.Home} /> 
                            <Route path='/quotation' component={this.Quotation}/>
                            <Route exact path="/delivery" component={this.Delivery} />
                            <Route exact path="/orders" component={this.Orders} />
                            <Route exact path="/form" component={this.Form} />
                            <Route exact path="/activation" component={this.Delivery} />
                            <Route exact path="/redention" component={this.Redention} />
                            <Route exact path="/redention/plan/:id" component={this.OverviewPlan}/>

                            <Route exact path="/models" component={this.Models} />
                            <Route exact path="/parts" component={this.Parts} />
                            <Route exact path="/variables" component={this.Variables} />
                            <Route exact path="/tempario" component={this.Tempario} />
                            <Route exact path="/offert" component={this.Offert} />
                            <Route exact path="/users" component={this.Users} />
                            <Route exact path="/complementary" component={this.Complementary} />
                            <Route exact path="/reports" component={this.Reports} />
                            <Route exact path="/commercialActions" component={this.CommercilActions} />
                            <Route exact path="/uploadReport" component={this.UploadReport} />
                            <Route exact path="/tmog" component={this.TMOG} />
                            <Route exact path="/salesCommisions" component={this.SalesCo} />
                            <Route exact path="/vins" component={this.Vins} />
                            <Route exact path="/dealers" component={this.Dealers} />
                            
                            
                            <Route exact path='/order/:id' component={this.Order}/>
                            <Redirect exact from='/' to='/Home'></Redirect>

                            <Route component={this.Home} />
                        </Switch>
                        
                    </HashRouter>
                    
                    
                    <FooterComponent/>
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.user.isFetching,
        email: state.token.data.userEmail? state.token.data.userEmail:localStorage.getItem('userEmail'),
        token: state.token.data.token,
        showComponent: state.showComponent
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserByEmail: (body)=> dispatch(GetUserByEmail(body))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen)