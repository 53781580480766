import React, { Component, Fragment } from 'react';
import { Grid, Container, Header, Table, Modal, Icon, Button, Progress } from 'semantic-ui-react';
import { offert } from '../../../../utils/Constants';
import { getProjection } from '../../../../services/simulator/simulatorService';
const CurrencyFormat = require('react-currency-format')

const versions = offert
class AllVersionsScreen extends Component {

    state = {
        loading:true,
        refSelect:'',
        openModalRef:false,
        count:0,
        total:versions.length,
        data:[]
    }

    componentDidMount(){
        this.setState({ count: versions.length}, ()=>{
            this.getData()
        })
        
    }

    modalRef = () => {
        const { refSelect, openModalRef } = this.state;
        
        if(!refSelect && !(refSelect.length>0)) return null;
        
        return <Modal open={openModalRef} >
            <Modal.Header>
                <Icon name="box" /> REPUESTOS
            </Modal.Header>
            <Modal.Content>
                <Table selectable size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>RUTINA</Table.HeaderCell>
                            <Table.HeaderCell>REF</Table.HeaderCell>
                            <Table.HeaderCell>DESCRIPCIÓN</Table.HeaderCell>
                            <Table.HeaderCell>CANTIDAD</Table.HeaderCell>
                            <Table.HeaderCell>PVP</Table.HeaderCell>
                            <Table.HeaderCell>SUBTOTAL</Table.HeaderCell>
                            <Table.HeaderCell>MOTOR</Table.HeaderCell>
                            <Table.HeaderCell>CILINDRADA</Table.HeaderCell>
                            <Table.HeaderCell>TRANSMISION</Table.HeaderCell>
                            <Table.HeaderCell>AIRE</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {refSelect.map( (i, index)=> {
                            return i.map( (j)=>{
                                return <Table.Row>
                                    <Table.Cell>{(index + 1) * 5}</Table.Cell>
                                    <Table.Cell>{j.ref}</Table.Cell>
                                    <Table.Cell>{j.description}</Table.Cell>
                                    <Table.Cell>{j.amount}</Table.Cell>
                                    <Table.Cell>{j.pvp}</Table.Cell>
                                    <Table.Cell>{j.subTotal}</Table.Cell>
                                    <Table.Cell>{j.motor}</Table.Cell>
                                    <Table.Cell>{j.displacement}</Table.Cell>
                                    <Table.Cell>{j.transmission}</Table.Cell>
                                    <Table.Cell></Table.Cell>
                                </Table.Row>
                            } )
                        } )}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content='CERRAR'
                    icon='times'
                    onClick={()=>this.setState({openModalRef:false, refSelect:''})} 
                />
            </Modal.Actions>
        </Modal>
    }
    

    getData = () => {
        const { count } = this.state;
        let countIndex = count;
        
        getProjection({cl:'CL001', version: versions[countIndex-1]}).then( result =>{
            if(!result.message && result.length>0){
                let tempData = this.state.data;
                tempData.push(result)
                
                this.setState({count: this.state.count - 1, data:tempData}, ()=>{
                    if(this.state.count > 0){
                        this.getData()
                    } else {
                        console.log('hemos terminado')
                    }
                })
            } else {
                this.setState({count: this.state.count - 1 }, ()=>{
                    if(this.state.count > 0){
                        this.getData()
                    } else {
                        console.log('hemos terminado')
                    }
                })
            }
            
            
        } )

    }

    renderResult =()=> {
        const { data } = this.state;
        if(data && data.length>0){
            return data.map( (i, index)=>{
                return <Table unstackable selectable size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell colspan={12} >VALORES POR RUTINA</Table.HeaderCell>
                                    <Table.HeaderCell color="red" colspan={5}>VALORES POR PAQUETE</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>VERSION</Table.HeaderCell>
                                    
                                    <Table.HeaderCell>RUTINA</Table.HeaderCell>
                                    <Table.HeaderCell>HORAS</Table.HeaderCell>
                                    <Table.HeaderCell colspan={2}>REPUESTOS</Table.HeaderCell>
                                    
                                    <Table.HeaderCell>M. OBRA</Table.HeaderCell>
                                    
                                    <Table.HeaderCell>INSUMOS</Table.HeaderCell>
                                    <Table.HeaderCell>SUBTOTAL</Table.HeaderCell>
                                    <Table.HeaderCell>FACTOR FUTURO</Table.HeaderCell>
                                    <Table.HeaderCell>SUBTOTAL V.FUTURO</Table.HeaderCell>
                                    <Table.HeaderCell>COMISION</Table.HeaderCell>
                                    <Table.HeaderCell>BONO</Table.HeaderCell>
                                    <Table.HeaderCell>IVA</Table.HeaderCell>
                                    {/* <Table.HeaderCell>TOTAL <br/> <small>SubTotal V. Futuro + Comisión + IVA</small></Table.HeaderCell> */}
                                    <Table.HeaderCell>VALOR A REDIMIR <br/> <small>Incluye IVA</small> </Table.HeaderCell>
                                    <Table.HeaderCell>TOTAL PAQUETE</Table.HeaderCell>
                                    <Table.HeaderCell>COMISION PAQUETE</Table.HeaderCell>
                                    <Table.HeaderCell>AJUSTE</Table.HeaderCell>
                                    <Table.HeaderCell>ADMON <br/> <small>Incluye IVA</small></Table.HeaderCell>
                                    <Table.HeaderCell>PRECIO VENTA</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    i.map( (j, jIndex)=>{
                                        return <Table.Row>
                                            <Table.Cell>{j.article}</Table.Cell>
                                            
                                            <Table.Cell>{j.routine}</Table.Cell>
                                            <Table.Cell>
                                                {Math.round( j.pHours*100 )/100}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.pParts} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    size='tiny'
                                                    icon="box"
                                                    style={{borderRadius:25}}
                                                    onClick={()=>this.setState({refSelect:j.partsDetails, openModalRef:true})}
                                                 />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.pMo} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            
                                            <Table.Cell>
                                                <CurrencyFormat value={j.pSupply} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.pSubTotal} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.futureFactor} thousandSeparator={true} prefix="" displayType={'text'} decimalScale={3}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.pFutureValue} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {j.commision.value}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                
                                                <CurrencyFormat value={j.commision.bono} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.iva} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            {/* <Table.Cell>
                                                <CurrencyFormat value={j.pTotal} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell> */}
                                            <Table.Cell>
                                                <CurrencyFormat value={j.valueToRedeem} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.pAccumulated} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.commissionValue} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.fix} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.admonCost} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <CurrencyFormat value={j.salesPrice} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                                            </Table.Cell>
                                        </Table.Row>
                                    })
                                }
                            </Table.Body>
                        </Table>
            } )
        }
    }


    render(){
        const { count, total } = this.state;
        return (
            <Fragment>
                <Grid style={{marginTop:30, marginLeft:50, marginRight:50, marginBottom:30, minHeight:"70vh"}}>
                    <Container fluid>
                            <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                                PAQUETES DISPONIBLES
                            </Header>

                            <Progress active={false} size='tiny' percent={((total-count)/total)*100} indicating />
                            {this.renderResult()}
                            {this.modalRef()}
                            
                    </Container>
                </Grid>
            </Fragment>
        )
    }
}

export default AllVersionsScreen