// export const API = 'https://localhost:3001/api/';
// export const domain = 'https://localhost:3001/mantpre/';

export const API = 'https://mantenimientokplus.kia.com.co:3001/api/';
export const domain = 'https://mantenimientokplus.kia.com.co:3001/mantpre/';


// export const API = 'http://3.17.11.96:3001/api/';
// export const domain = 'http://3.17.11.96:3001/api/mantpre/';


export const FETCHING_DATA = 'FETCHING_DATA';
export const FETCHING_DATA_SUCCESS = 'FETCHING_DATA_SUCCESS';
export const FETCHING_DATA_FAILURE = 'FETCHING_DATA_FAILURE';
