import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Segment, Header, Button, Container, Grid, Divider, Label, Icon, Input, Modal } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import PreloaderComponent from '../PreloaderComponent';
import { dealerByDealer } from '../../services/dealers/dealersService';
import { validationVersion } from '../../services/vins/vinServices';
import { create } from '../../services/orders/orderService';
import { SetOrderForm, MessageToUser } from '../../actions';
import SimulatorScreen from '../../screens/LoggedIn/adviser/SimulatorScreen';
import "react-datepicker/dist/react-datepicker.css";

const typeOptions = [
    { value:'CC', text:'Cédula de Ciudadania', key:1},
    { value:'CE', text:'Cédula de Extranjería', key:2},
    { value:'PASS', text:'Pasaporte', key:3},
]

class FormCustomerComponent extends Component {
    constructor(){
        super();
        this.state = { 
            address:'', 
            adviserId:'',
            adviserName:'',
            adviserTypeId:'',
            bill:'',
            city:'', 
            cityDealer:'',
            cl:'', 
            country:"COLOMBIA", 
            countryDealer:"COLOMBIA",
            dealerData:{},
            dealerName:'', 
            delivery: new Date(),
            email:'',
            lastName:'', 
            loading:true,
            modalSimulatorOpen:false,
            modelCod:'',
            modelColor:'',
            modelVersion:'',
            modelYear:'',
            name:'', 
            ni:'', 
            packageName:'',
            packageValue:'',
            plate:'',
            telephone:'',
            typeId:'', 
            versionValidated:false,
            vin:''
        };
    }

    handleChange = (e, { name, value})=>{
        if(name==='vin' && name.length>0){
            this.setState({[name]: value.toUpperCase()})
        } else {
            this.setState({[name]: value})
        }
        
    }
    handleChangeDate = (date)=>{
        this.setState({delivery:date});
    }

    componentDidMount(){
        this.setData()
    }

    setData = () => {
        document.title = `Formulario ${this.props.order.article} | Mantenimiento K Plus`
        this.renderSelectDealer()
        if(this.props.order.article){
            this.dataVehicle()
        }  
    }

    renderSelectDealer = ()=> {
        const { dealer, name, last_name } = this.props.user;
        let selectOptions = []
        if(dealer && dealer.length>0){
            dealer.sort();
            dealer.map((i, index)=>{
                return selectOptions.push({
                    key:index,
                    text:i,
                    value:i
                })
            })
            this.setState({
                dealerOptions:selectOptions, 
                cl:selectOptions[0].value,
                adviserName: `${name} ${last_name}`
            }, ()=>{ this.dealerByDealer()});
        }
    }

    dataVehicle=()=>{
        this.setState({ 
            modelVersion:this.props.order.article, 
            modelCod:this.props.order.article.slice(0,2),
            packageValue:this.props.order.plan.salesPrice,
            packageName: this.props.order.codePackage
        })
    }

    dealerByDealer=()=>{
        const { cl } = this.state;
        dealerByDealer({dealer:[cl]})
        .then( (data)=>{
            this.setState({dealerData:data[0], cityDealer:data[0].city, dealerName:data[0].d_name, loading:false})
        }).catch( error => {
            this.props.messageToUser('Lo sentimos', error.message, true)
        })
    }

    saveOrder=()=>{
        this.props.setForm(this.state)
        setTimeout( ()=>{
            create(this.props.order)
            .then( (data)=>{
                this.setState({loading:false})
                this.props.messageToUser('Formulario', data.message, true)
            } ).catch( (error)=>{
                this.setState({loading:false})
                this.props.messageToUser('Lo sentimos', error.message, true)
            })
        },2000 )
        
    }

    validatedVersion=()=>{
        const { vin, modelVersion } = this.state
        let body = { vin:vin, article:modelVersion }
        validationVersion(body).then( (data)=>{
            this.props.messageToUser('Versión', data.message, true)
            this.setState({versionValidated:data.val})
        } ).catch( (error)=>{
            this.props.messageToUser('Lo sentimos', error.message, true)
            this.setState({versionValidated:error.val})
            console.log(error)
        })
    }

    modalSimulator = () => {
        const { modalSimulatorOpen } = this.state;
        return <Modal open={modalSimulatorOpen} onClose={()=>this.setState({modalSimulatorOpen:false})}>
            <Modal.Content scrolling>
                <SimulatorScreen />
            </Modal.Content>
            <Modal.Actions>
                <Button style={{borderRadius:25 }} size='tiny' content='CANCELAR' icon='times' onClick={()=>this.setState({modalSimulatorOpen:false})} />
                <Button style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}} size='tiny' content='CERRAR Y GUARDAR' icon="save" onClick={()=>{this.setData();this.setState({modalSimulatorOpen:false})} } />
            </Modal.Actions>
        </Modal>
    }

    render(){
        const {address,adviserTypeId, adviserId, vin, plate, city, country, bill, cl, versionValidated, dealerName, email, telephone, cityDealer, countryDealer, loading, dealerOptions, lastName, modelVersion, modelCod, name, ni, packageValue, packageName, typeId, adviserName, modelColor, modelYear } = this.state;
        if(loading){
            return <PreloaderComponent/>
        }
        return (
            <Segment style={{marginBottom:30}}>
                
                <Header style={{textAlign:'right'}}>
                    MANTENIMIENTO K PLUS
                </Header>
                <Header>
                    <Header.Subheader>Información del distribuidor</Header.Subheader>
                </Header>

                <Form>
                    <Form.Group>
                        <Form.Select onChange={(e, {name, value})=>this.setState({[name]:value}, ()=>this.dealerByDealer())} width="4" label="CL" name="cl" value={cl} options={dealerOptions}></Form.Select>
                        <Form.Input width="4" label="Nombre Distribuidor" value={dealerName}></Form.Input>
                        <Form.Input width="4" label="Ciudad"  value={cityDealer} readOnly></Form.Input>
                        <Form.Input width="4" label="País"  value={countryDealer} readOnly></Form.Input>
                    </Form.Group>
                    <Header>
                        <Header.Subheader>Información del Cliente</Header.Subheader>
                    </Header>
                    <Form.Group  widths={2}>
                        <Form.Input onChange={this.handleChange} value={name} label="Nombre" name="name" placeholder="Nombre" ></Form.Input>
                        <Form.Input onChange={this.handleChange} value={lastName} label="Apellido" name="lastName" placeholder="Apellido"></Form.Input>
                    </Form.Group>
                    <Form.Group widths={2}>
                        <Form.Select onChange={this.handleChange} value={typeId} options={typeOptions} placeholder='Tipo ID' name="typeId" label="Tipo de Identificación"></Form.Select>
                        <Form.Input onChange={this.handleChange} value={ni} name="ni" label="Número de Identificación" placeholder="Número de Identificación"></Form.Input>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input width="8" label="Dirección" value={address} name="address" onChange={this.handleChange} placeholder="Dirección"></Form.Input>
                        <Form.Input width="4" label="Ciudad" value={city} name="city" onChange={this.handleChange} placeholder="Ciudad"></Form.Input>
                        <Form.Input width="4" label="País" value={country} name="country" onChange={this.handleChange} placeholder="País"></Form.Input>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input width="8" onChange={this.handleChange} value={email} name="email" placeholder="Email" label="Email"></Form.Input>
                        <Form.Input width="8" onChange={this.handleChange} value={telephone} name="telephone" placeholder="Celular" label="Celular"></Form.Input>
                    </Form.Group>
                </Form>

                <Header as="h2">
                    <Header.Subheader>Información del vehículo </Header.Subheader>
                </Header>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>VIN</label>
                            <Input icon={<Icon name="search" link onClick={()=>this.validatedVersion()} />} style={{textTransform:'uppercase'}} onChange={this.handleChange} value={vin} name="vin" placeholder="VIN: No Identificación del vehículo"></Input>
                        </Form.Field>
                        <Form.Input onChange={this.handleChange} value={plate} name="plate" label="Placa" placeholder="Placa"></Form.Input>
                    </Form.Group>
                    <Form.Group >
                        <Form.Input width="4" onChange={this.handleChange} value={modelCod} name="modelCod" label="Modelo" placeholder="Modelo"></Form.Input>
                        <Form.Field>
                            <label>Versión</label>
                            <Input width="4" value={modelVersion} name="modelVersion" placeholder="Versión del vehículo" labelPosition='right' label={<Button icon="box" onClick={()=>this.setState({modalSimulatorOpen:true})} />} />
                        </Form.Field>
                        <Form.Input width="4" onChange={this.handleChange} value={modelColor} name="modelColor" label="Color" placeholder="Color"></Form.Input>
                        <Form.Input width="4" onChange={this.handleChange} value={modelYear} name="modelYear" label="Año modelo" placeholder="Año modelo"></Form.Input>
                    </Form.Group>
                </Form>

                <Header as="h2">
                    <Header.Subheader>Información del Paquete </Header.Subheader>
                </Header>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Input onChange={this.handleChange} value={packageName} name="packageName" label="Paquete Mant. Prepagado" placeholder="Paquete Mant. Prepagado"></Form.Input>
                        <Form.Input value={packageValue} name="packageValue" label="Valor del Paquete" placeholder="Valor del Paquete"></Form.Input>
                        <Form.Field>
                            <label>Fecha entrega <small>(vehículo)</small></label>
                            <DatePicker
                                selected={this.state.delivery}
                                onChange={this.handleChangeDate}>
                            </DatePicker>
                        </Form.Field>
                        <Form.Input onChange={this.handleChange} value={bill} name="bill" label="Factura No" placeholder="No de factura"></Form.Input>
                    </Form.Group>
                </Form>


                <Header as="h2">
                    <Header.Subheader>Asesor de Servicio</Header.Subheader>
                </Header>
                <Form>
                    <Form.Group>
                        <Form.Input width={8} onChange={this.handleChange} value={adviserName} name="adviserName" label="Asesor de servicio" placeholder="Nombre y apellido asesor de servicio"></Form.Input>
                        <Form.Select width={4} options={typeOptions} onChange={this.handleChange} value={adviserTypeId} name="adviserTypeId" label="Tipo de Identificación" placeholder="Tipo de identificación"></Form.Select>
                        <Form.Input width={4} onChange={this.handleChange} value={adviserId} name="adviserId" label="Asesor de servicio" placeholder="Número de identificación"></Form.Input>
                    </Form.Group>
                </Form>

                <Container>
                    <p style={{textAlign:'justify', fontSize:10}}>
                        El cliente reconoce que los servicios requeridos en este pedido únicamente serán realizados en la red 
                        de concesionarios autorizados de Kia Motors en Colombia. El cliente y el concesionario enunciados reconocen 
                        y aceptan el contenido de este pedido para que la importadora, Metrokia S.A., realice la activación de 
                        Mantenimiento Prepagado para el vehículo descrito anteriormente:
                    </p>
                    <p style={{textAlign:'justify', fontSize:10}}>
                        Poíítica de Uso de datos personales
    
                        Con la firma del presente documento, dando cumplimiento a lo establecido en la Ley 1581 de 2012 y el Decreto 1377 
                        de 2013 el CLIENTE  AUTORIZA al CONCESIONARIO y al DISTRIBUIDOR DE LA MARCA EN EL PAÍS, la recolección y manejo de sus 
                        datos personales, los cuales tendrán como fin el almacenamiento, uso, circulación, transmisión, directamente o a través 
                        de sus terceros aliados, con finalidad comercial y para la correcta prestación de los servicios. No obstante, lo anterior, 
                        EL CLIENTE podrá solicitar al  CONCESIONARIO y al DISTRIBUIDOR DE LA MARCA EN EL PAÍS la actualización, rectificación o supresión 
                        de sus datos personales del registro en bases de datos del CONCESIONARIO  y al DISTRIBUIDOR DE LA MARCA EN EL PAÍS enviando un
                         correo electrónico a <a href="mailto:callcentercrm@kia.com.co">callcentercrm@kia.com.co</a> 
  
                        PARÁGRAFO: Los datos de carácter personal que sean proporcionados al CONCESIONARIO y al DISTRIBUIDOR DE LA MARCA EN EL PAÍS, serán 
                        recogidos y conservados en una base de datos y podrán ser objeto de tratamiento por parte del CONCESIONARIO y el DISTRIBUIDOR DE LA 
                        MARCA EN EL PAÍS, terceros aliados o designados por EL CONCESIONARIO. Dicha base de datos se conserva y administra bajo responsabilidad del 
                        CONCESIONARIO y el DISTRIBUIDOR DE LA MARCA EN EL PAÍS, terceros aliados o designados por EL CONCESIONARIO y el DISTRIBUIDOR DE LA MARCA 
                        EN EL PAÍS. La base de datos cuenta con las medidas de seguridad necesarias para la conservación adecuada de los datos. Para fines 
                        estadísticos y de medición de satisfacción del cliente se autoriza expresamente la transferencia y/o transmisión Nacional e internacional 
                        de los datos personales bajo niveles adecuados de protección, seguridad y confidencialidad.

                        Para más información consulte en <a href="https://www.kia.com/co/util/privacy.html ">POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</a>
                    </p>
                </Container>
                <Container>
                    <Segment placeholder>
                        <Grid columns={2} stackable textAlign='center' verticalAlign='top'>
                            <Divider vertical></Divider>
                            <Grid.Row verticalAlign='bottom' style={{ height:'25vh'}}>
                                
                                <Grid.Column verticalAlign='bottom'>

                                    <p style={{fontWeight:'bold'}}>{this.state.name +' '+this.state.lastName} </p>
                                    <Label>{this.state.typeId}</Label> {this.state.ni}
                                    
                                    <p>FIRMA CLIENTE</p>
                                </Grid.Column>
                                <Grid.Column verticalAlign='bottom'> 
                                    <p>FIRMA AUTORIZADA CONCESIONARIO</p>
                                </Grid.Column>
                            </Grid.Row>
                            
                        </Grid>
                    </Segment>
                </Container>
                <Container textAlign='right' style={{marginTop:15}}>
                    <Button style={{borderRadius:25}} onClick={()=>window.print()}>
                        <Icon name="print"/> Imprimir</Button>
                    <Button style={{borderRadius:25}} disabled={!versionValidated} color='red' loading={loading} onClick={()=>{ this.setState({loading:true});this.saveOrder()}}>Guardar Pedido</Button>
                </Container>
                {this.modalSimulator()}
            </Segment>
        )
    }
}
const mapStateToProps = state => {
    return{
        order: state.order,
        user: state.user.data
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        setForm: (form)=> dispatch(SetOrderForm(form)),
        messageToUser: (title, message, open)=>dispatch(MessageToUser(title, message, open))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormCustomerComponent)