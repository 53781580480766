import {FETCHING_DATA, FETCHING_DATA_SUCCESS, FETCHING_DATA_FAILURE} from '../constants';

const PATH = 'GETGROUPMODELS'

const initialState = {
    data:[],
    isFetching:false,
    error:false
}

const GetGroupModels = ( state = initialState, action ) => {
    switch(action.type){
        case FETCHING_DATA+PATH:
            return {
                ...state,
                data:[],
                isFetching:true,
            }
        
        case FETCHING_DATA_SUCCESS+PATH:
            return {
                ...state,
                data:action.data,
                isFetching:false,
            }

        case FETCHING_DATA_FAILURE+PATH:
            return {
                ...state,
                data:[],
                isFetching:false,
                error:true
            }
        default: 
            return state;
    }
}

export default GetGroupModels;