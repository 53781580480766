import React, { Component, Fragment } from 'react'
import { Grid, Container, Header, Button, Segment, Table, Progress } from 'semantic-ui-react';
import { InputFile } from 'semantic-ui-react-input-file';
import XLSX from 'xlsx';
import { MessageToUser } from '../../../../actions';
import { connect } from 'react-redux';
import { createPackage } from '../../../../services/commercialActions';
import moment from 'moment';

 class UploadReportScreen extends Component {
     state = {
         fileName:'',
         fileSelect:null,
         total:0,
         data:[],
         log:[]
     }

     handleUpload = (e)=>{
        if(e.target.files && e.target.files[0]){
            this.setState({ fileSelect:e.target.files[0], fileName:e.target.files[0].name})
            this.readerXlsx(e)
        }
    }
    

    readerXlsx = (e) => {
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        const scope = this
        reader.onload = function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
            
            /* DO SOMETHING WITH workbook HERE */

            var wsNames = workbook.SheetNames[0]; //Indexar una hoja
            var ws = workbook.Sheets[wsNames]; // Obtener toda la data de la hoja por celda

            let data_json = XLSX.utils.sheet_to_json(ws, {header:0}) //Utilidad de XLSX para convertir ws en un JSON
            
            scope.setState({data:data_json, total: data_json.length})
            
        };
        reader.readAsArrayBuffer(f);
        
    }

    renderDataPreview = () => {

        const { data } = this.state;
        
        if(!(data && data.length>0)) return null
        console.log(Object.keys(data[0]))
        return <Table size='small' selectable compact style={{ scrolly:true }}>
            <Table.Header>
                <Table.Row>
                    {Object.keys(data[0]).map( (i)=> <Table.HeaderCell>{i}</Table.HeaderCell> )}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map( (i, index)=>{
                    if(index<10) return <Table.Row>
                                            {Object.keys(data[0]).map( (j, index)=>{
                                            return <Table.Cell collapsing>{ i[j] }</Table.Cell>
                                            } )}
                                        </Table.Row>
                    return null
                    
                })}
            </Table.Body>
        </Table>
        
    }

    uploadReport = () => {
        const { data, total } = this.state;
        if(total > -1) {
            createPackage(data[total-1])
            .then( result => {
                
                this.setState({ 
                    total: total-1, log:[...this.state.log, {
                        msg: result.msg,
                        vin: result.vin,
                        fecha: result.fecha
                    }] }, ()=>{
                    this.uploadReport()
                }) 
            } )
        } else {
            setTimeout( ()=>{
                this.props.messageToUser('Proceso Finalizado', 'Se han revisado todos los registros, ahora puede descargar un reporte', true)
            }, 1500 )
            
        }
        
    }
    
    renderProgress = () => {
        const { total, data } = this.state;
        
        if(data && data.length>0) return <Progress active={false} size='tiny' percent={ ( (data.length - total) / data.length)*100} indicating label={Math.round(((data.length - total) / data.length)*100)+'%'} />
        return null;
    }
   
    downLoadReport = () => {
        const { log } = this.state;
        console.log(log)
        let data = [['MENSAJE', 'VIN', 'FECHA']]
        for(let i of log){
            data.push([
                i.msg,
                i.vin,
                i.fecha
            ])
        }
        let csvContent = 'data:text/csv;charset=utf-8,'
        data.map( (i)=>{
            let row = i.join(",");
            return csvContent += row +'\n\r';
        } )
        const encodedUri = encodeURI(csvContent);
         
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${moment().format('YYYYMMDD')} REPORTE DE CARGA.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();
    }



     render(){
         
         const { fileName, data, log } = this.state;
         
         return <Fragment>
             <Grid style={{minHeight:'70vh', marginTop:'8vh', marginBottom:50, marginLeft:50, marginRight:50 }}>
                <Container fluid>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        CARGAR REPORTE RETAIL
                    </Header>
                    <Segment>
                        <Header>
                            1. Construcción del Archivo a cargar
                        </Header>
                        <p>
                            Asegurese que el archivo que va a cargar tenga la siguiente estructura
                        </p>

                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>vin</Table.HeaderCell>
                                    <Table.HeaderCell>version</Table.HeaderCell>
                                    <Table.HeaderCell>fecha</Table.HeaderCell>
                                    <Table.HeaderCell>cl</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Chasis, o Número de identicifación vehícular, debe contener 17 caracteres</Table.Cell>
                                    <Table.Cell>Versión de equipamiento Ejemplo: JA1M10__25G1002</Table.Cell>
                                    <Table.Cell>Fecha de la transacción: DD/MM/AÑO</Table.Cell>
                                    <Table.Cell>Código del consionario: CL001</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                                                
                        <Header>
                            2. Seleccione un archivo
                        </Header>
                        <InputFile
                            button={{label:fileName? fileName:'Elige un archivo', size:'tiny' }}
                            input={{
                                id:'input-upload-id',
                                onChange:this.handleUpload
                            }}
                        />
                        {data && data.length>0?<Button
                            content="CARGAR REGISTROS"
                            size='tiny'
                            icon="upload"
                            onClick={ ()=> this.uploadReport() }
                         />:null}
                         
                    </Segment>
                    {this.renderProgress()}
                    {this.renderDataPreview()}
                    {log.length>0? <Segment>
                        <Header>
                            3. Descargue un reporte de la carga
                            <Header.Subheader>
                                Consulte los resultados de la operación
                            </Header.Subheader>
                        </Header>
                        <Button
                            style={{ borderRadius:25, backgroundColor:"#BB162B", color:"#f6f6f6"}}
                            content="REPORTE DE CARGA"
                            icon="download"
                            size='tiny'
                            onClick={()=>this.downLoadReport()}/>
                        <br/>
                        <br/>
                        <br/>
                        <p>
                            <strong>IMPORTANTE</strong>
                        </p>
                        <p>
                                Asegúrese que todos los Vin incluidos en el reporte tengan una respuesta del proceso: <br/>
                                <ul>
                                    <li>Se ha creado el pedido con exito</li>
                                    <li>No hay Acciones Comerciales</li>
                                    <li>Ocurrio un error al crear el pedido</li>
                                </ul>
                            </p>
                    </Segment>:null}
                    
                </Container>
            </Grid>
         </Fragment>
     }
 }

 const mapDispatchToProps = dispatch => {
     return {
         messageToUser: (title, msg, open) => dispatch( MessageToUser(title, msg, open))
     }
 }
 export default connect(null, mapDispatchToProps)(UploadReportScreen)
