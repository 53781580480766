// 'use strict'

// const domainUrl = 'https://localhost:3001/';
// const api = 'https://localhost:3001/api/';
// const urlFrontend = 'http://localhost:3000/';

const domainUrl = 'https://mantenimientokplus.kia.com.co:3001/';
const api = 'https://mantenimientokplus.kia.com.co:3001/api/';
const urlFrontend = 'https://mantenimientokplus.kia.com.co/';

// Api to IP
// const domainUrl = 'http://3.17.11.96:3001/';
// const api = 'http://3.17.11.96:3001/api/';
// const urlFrontend = 'http://3.17.11.96/';

const token = JSON.stringify(localStorage.getItem('token'));

const motorOption = [
    { key: 'G', text: 'Gasolina', value: 'G' },
    { key: 'D', text: 'Diesel', value: 'D' },
    { key: 'H', text: 'Hibrido', value: 'H' },
    { key: 'E', text: 'Eléctrico', value: 'E' },
    { key: 'A', text: 'Todos', value: null },
]
const aireOption = [
    { key: 'A', text: 'Aire Acondicionado', value: 'A' },
    { key: 'B', text: 'Sin A. Acondicionado', value: 'S' },
    { key: 'C', text: 'Todos', value: null },
]
const transmissionOption = [
    { key: 'A', text: 'Automática', value: 'A' },
    { key: 'B', text: 'Mecánica', value: 'M' },
    { key: 'C', text: 'Todos', value: null },

]
const entryCar = [
    { model:'JA', version:'JA1M10__25G1001'},
    { model:'AB', version:'AB--1P1P24AG14A'},
    { model:'SC', version:'SC1M21__24G1400'},
    { model:'ST', version:'ST1M40__25G1600'},
    { model:'BD', version:'BD1A52__24G1600'},
    { model:'DE', version:'DE1A31__25H1600'},
    { model:'QL', version:'QL2M17__25G2001'},
    { model:'RP', version:'RP2M41__25G2000'},
    { model:'UM', version:'UM2A65__45G3301'},
    { model:'JF', version:'JF--1Z1P24AH20A'},

    
]

const typeOptions = [
    { value:'CC', text:'Cédula de Ciudadania', key:1},
    { value:'CE', text:'Cédula de Extranjería', key:2},
    { value:'PASS', text:'Pasaporte', key:3},
]

const rolOptions = [
    { value:'Elige un Rol', text:'Elige un Rol', key:0},
    { value:'manager', text:'Super Administrador', key:1},
    { value:'dealers_manager', text:'Administrador Concesionario', key:2},
    { value:'adviser_service', text:'Asesor de Servicio', key:3},
    { value:'adviser_commercial', text:'Asesor Comercial', key:4},
    { value:'query_user', text:'Usuario de Consultas', key:5},
]

function packOptions(){
    let data = [{
        value:'Seleccione un Paquete',
        text:'Seleccione un Paquete',
        key:99
    }];
    for(let i=0; i<10; i++){
        data.push({ 
            value:(i+1)*5, 
            text:`${(i+1)*5}`, 
            key:(i+1)*5 })
    }
    return data;
}

const offert = [
    "JA1M10__25G1002", 
    "JA1M11__25G1002", 
    "JA1M23__25G1201", 
    "JA1A23__25G1201", 
    "JA1M43__25G1200", 
    "JA1A43__25G1200", 
    "JA1M61__25G1200", 
    "JA1A61__25G1200", 
    "JA1M63__25G1200", 
    "JA1A63__25G1200", 
    "JA1M70__25G1200", 
    "JA1A70__25G1200", 
    "JA1M72__25G1200", 
    "JA1A72__25G1200", 
    "JA1M01T_25G1000", 
    "JA1M02T_25G1000", 
    "JA1M01T_25G1202", 
    "JA1M01T_25G1203", 
    "JA1M02T_25G1202", 
    "JA1M32T_25G1200", 
    "AB--1D1P24AG14A", 
    "AB--1D1P24MG14A", 
    "AB--1L1T24MG14S", 
    "AB--1L1T24MG14A", 
    "SC1M21__24G1401", 
    "SC1A21__24G1401", 
    "SC1M41__24G1401", 
    "SC1A41__24G1401", 
    "SC1M52__24G1401", 
    "SC1A52__24G1401", 
    "SC1M21__25G1401", 
    "SC1A21__25G1401", 
    "SC1M41__25G1401", 
    "SC1A41__25G1401", 
    "SC1M52__25G1401", 
    "SC1A52__25G1401", 
    "ST1M40__25G1601", 
    "ST1A40__25G1601", 
    "BD1M52__24G1600", 
    "BD1A52__24G1600", 
    "BD1M56__24G1600", 
    "BD1A56__24G1600", 
    "BD1A60__24G1600", 
    "JF--1Z1P24AH20A", 
    "RP2A41__25G2000", 
    "PS2A20__25G1600", 
    "PS2A55__25G1600", 
    "SK3-1L1T25MG16A", 
    "QL2M17__25G2001", 
    "QL2M17__25G2002", 
    "QL2A17__25G2001", 
    "QL2A17__25G2002", 
    "QL2M20__25G2001", 
    "QL2A20__25G2001", 
    "QL2A44__25G2000", 
    "QL2A44__25G2001", 
    "QL2A44__25G2002", 
    "QL2A46__25G2000", 
    "QL2A50P_25H1600", 
    "QL2A61__45G2000", 
    "QL2A62__25G2000", 
    "QL2A62__45G2000", 
    "QL2A62__45D2000", 
    "QL2A70__25G2000", 
    "QL2A70__45G2000", 
    "DE2A31__25H1600", 
    "DE1A31__25H1600", 
    "DE2A51__25H1600", 
    "UM2A45__45G3301", 
    "UM2A65__45G3301", 
    "YP1A60__25G3300",
    "QL2A30__25H1600",
    "QL2A46__45H1600",
    "QL2A46__25H1600"
]

function getDomain(){
    return domainUrl
}

function getApi(){
    return api;
}

function getMotorOption(){
    return motorOption;
}

function getAireOption(){
    return aireOption;
}

function getTransmissionOption(){
    return transmissionOption
}
function getUrlFrontend(){
    return urlFrontend;
}
async function ModelsGetGroupModels(signal){
    return await fetch( getApi()+'/models/getGroupModels', {
        signal:signal,
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        }
    }).then( (models)=> models.json() )
    .then( (modelsJson)=>{
        return modelsJson;
    } ).catch( (error)=>{
        return error
    } )
}

function routines (){
    let routines = [];

    for(let i=0; i<30; i++){
        routines.push({
            value: (i+1) * 5,
            text:`${(i+1) * 5000} K`,
            key:(i+1) * 5
        })
    }

    return routines
}


const frequencyOption = [
    { value:'5', text:'Cada 5.000 Km', key:5},
    { value:'10', text:'Cada 10.000 Km', key:10},
    { value:'20', text:'Cada 20.000 Km', key:20},
    { value:'50', text:'Cada 30.000 Km', key:30},
    { value:'40', text:'Cada 40.000 Km', key:40},
    { value:'50', text:'Cada 50.000 Km', key:50},
]

export {
    getDomain,
    getApi,
    getMotorOption,
    getAireOption,
    getTransmissionOption,
    ModelsGetGroupModels,
    entryCar,
    getUrlFrontend,
    typeOptions,
    offert,
    rolOptions,
    routines,
    frequencyOption,
    packOptions
}

