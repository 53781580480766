import React, { Component, Fragment } from 'react';
import { Menu, Container, Image, Responsive, Icon, Button, Dropdown } from 'semantic-ui-react';
import { BrowserRouter as Link } from "react-router-dom"
import { logOutApp, showComponent } from '../../actions/';
import { connect } from 'react-redux';
import { getUrlFrontend } from '../../utils/Constants';

class NavbarPublic extends Component {

    state={
        active:'home'
    }
    handleItemClick = (e, { name }) => this.setState({ active: name })
    logOut = () => {
        this.props.showComponent('home')
        // Limpia la sesión de usuario del cliente
        localStorage.clear();
        // Limpia la variables de la Store
        this.props.logOutApp();
        
    }
    render(){
        const { active } = this.state;
        return (
            <Fragment>
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Menu  
                        stackable 
                        fixed="top" 
                        style={{ 
                            marginTop:30, 
                            minHeight:'30px !important', 
                            borderBottom:'4px solid #BB162B', 
                            height:60, fontFamily:'KiaBold', 
                            fontSize:16, color:'#B9072F', 
                            textTransform:'uppercase'
                    }} >
                        <Container>
                            {/* Logo en PC */}
                                <Menu.Item style={{paddingTop:2}}>
                                    <Image centered style={{maxWidht:'100%', height:'auto', margin:'auto'}} src={ require('../../assets/lg_kia.png')}  />
                                </Menu.Item>
                                
                                <Menu.Item link href='https://www.kia.com/co/main.html'>
                                    KIA.COM
                                </Menu.Item>

                                <Dropdown item text='COMPRAR UN KIA' closeOnBlur>
                                    <Dropdown.Menu>
                                    <Dropdown.Item link href='https://www.kia.com/co/shopping-tools/build-your-own.html'>ARMA TU KIA</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/shopping-tools/find-a-dealer.html' >ENCUENTRA TU KIA</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/shopping-tools/download-a-brochure.html'>FICHA TÉCNICA</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/shopping-tools/request-a-quote.html'>COTIZAR</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/shopping-tools/request-a-test-drive.html'>SOLICITA PRUEBA DE MANEJO KIA</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown item text='SERVICIO'>
                                    <Dropdown.Menu>
                                    <Dropdown.Item link href='https://www.kia.com/co/service/recall.html'>CAMPAÑAS DE SEGURIDAD</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/service/service-care/maintenance.html'>SERVICIO POSVENTA</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/service/warranty.html'>GARANTÍA</Dropdown.Item>
                                    <Dropdown.Item link href='http://www.clubk.com.co/agenda/site'>CITAS AL TALLER</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/service/manual.html'>MANUAL EN LÍNEA</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown item text='DESCUBRE'>
                                    <Dropdown.Menu>
                                    <Dropdown.Item link href='https://www.kia.com/co/discover-kia/our-story/brand.html'>NUESTRA HISTORIA</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/discover-kia/innovation/futures-tech.html'>INNOVACIÓN</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/discover-kia/sponsorship/australian-open.html'>PATROCINIO</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/discover-kia/kia-on-beat/k-pop-collaboration.html'>KIA ON BEAT</Dropdown.Item>
                                    <Dropdown.Item link href='https://www.kia.com/co/discover-kia/ask.html'>PREGUNTALE A KIA</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Menu.Menu position='right'>
                                    <Menu.Item style={{ padding:'20 0 100 0', fontSize:'.8em'}}>
                                        <Button
                                            as='a'
                                            href='https://www.kia.com/co/shopping-tools/request-a-quote.html'
                                            size='tiny'
                                            style={{ borderRadius: 25, backgroundColor:'#bb162b', color:'#f6f6f6'}}
                                            content='COTIZAR TU KIA'
                                            icon={{ name:'file outline', color:'orange'}}
                                        />
                                    </Menu.Item>
                                </Menu.Menu>
                        </Container>
                    </Menu>
                    <Menu fixed="top" style={{height:30, backgroundColor:'#F6F6F6'}}>
                        <Container>
                            <Menu.Menu stackable position='right' style={{fontSize:11, fontFamily:'KiaBold', color:'#999999'}}>
                                
                                <a href={getUrlFrontend()+"#/HomePublic"}>
                                    <Menu.Item style={{textTransform:'uppercase'}}>ACCESO A CLIENTES</Menu.Item>
                                </a>
                                <a href={getUrlFrontend()+"#/kiausers/"}>
                                    <Menu.Item style={{textTransform:'uppercase'}}> <Icon name="cog" /> </Menu.Item>
                                </a>
                            </Menu.Menu>
                        </Container>
                    </Menu>
                </Responsive>

                <Responsive {...Responsive.onlyMobile}>
                    <Menu fixed="top" style={{ borderBottom:1, borderBottom:'4px solid #BB162B' }}>
                        <Menu.Item>
                            <Image style={{maxWidht:70}} src={ require('../../assets/lg_kia.png')}  />
                        </Menu.Item>
                        <Menu.Item inverted position="right">
                            <Icon name="bars" size="large" />
                        </Menu.Item>
                    </Menu>
                </Responsive>
                
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logOutApp: ()=> dispatch(logOutApp()),
        showComponent: (component)=> dispatch(showComponent(component))
    }
}

export default connect(null, mapDispatchToProps)(NavbarPublic)