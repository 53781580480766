import React, { Component, Fragment } from 'react';
import { Menu, Container, Image, Icon, Dropdown, Label, Responsive, Dimmer, Button, List, Header,Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import logoKPlus from '../../src/assets/logo-color.png';
import { logOutApp, showComponent } from '../actions/';


class NavBarComponent extends Component {
    state = {
        menuActive:false
    }
    handleOpen = ()=>{
        this.setState({menuActive: !this.state.menuActive })
    }
    componentWillMount(){
        this.props.showComponent('home')
    }
    logOut = () => {
        this.props.showComponent('home')
        // Limpia la sesión de usuario del cliente
        localStorage.clear();
        // Limpia la variables de la Store
        this.props.logOutApp();
    }
    

    render(){ 
        const { key } = this.props.componentActive;
        const { menuActive } = this.state;
        return(
            <Fragment>
                <Responsive minWidth={Responsive.onlyTablet.minWidth} >
                    <Menu fixed="top" style={{marginTop:30, minHeight:'30px !important', borderBottom:'4px solid #BB162B', height:60, fontFamily:'KiaBold', fontSize:16, color:'#B9072F', textTransform:'uppercase'}} >
                        <Container >
                            <Menu.Item as="header"  >
                                <Image style={{maxWidht:70}} src={ require('../assets/lg_kia.png')}  />
                            </Menu.Item>
                            
                            <Menu.Item as={Link} to={{pathname:'/Home'}} active={key==='home'} onClick={()=>this.props.showComponent('home')}>
                                {/* <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={{pathname:'/Home'}}>PRINCIPAL</Link> */} PRINCIPAL
                            </Menu.Item>
                            <Menu.Item  as={Link} to={{pathname:'/quotation'}} style={{textTransform:'uppercase'}}  active={key==='simulator'} onClick={()=>this.props.showComponent('simulator')}>
                                {/* <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={{pathname:'/quotation'}}>Cotizador</Link> */} Cotizador
                            </Menu.Item>
                            <Menu.Item as={Link} to={'/orders'} style={{textTransform:'uppercase'}} active={key==='orders'} onClick={()=>this.props.showComponent('orders')}>
                                {/* <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/orders'}>Paquetes</Link> */} Paquetes
                            </Menu.Item>
                            <Menu.Item as={Link} to={'/form'} style={{textTransform:'uppercase'}} onClick={()=> this.props.showComponent('carshop')}>
                                {/* <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/form'}>Formulario</Link> */} Formulario
                                {this.props.order.plan.routine?<Label size="mini" circular color="red">{this.props.order.plan.routine} K</Label>:null}
                            </Menu.Item>
                            <Menu.Item as={Link} to={'/redention'} style={{textTransform:'uppercase'}} onClick={()=> this.props.showComponent('redention')}>
                                {/* <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/redention'}>Redimir</Link> */} Redimir
                            </Menu.Item>
                        </Container>
                    </Menu>


                    <Menu fixed="top" style={{height:30, backgroundColor:'#F6F6F6'}}>
                        <Container>
                            <Menu.Menu  position='right' style={{fontSize:11, fontFamily:'KiaBold', color:'#999999'}}>
                                <Menu.Item style={{textTransform:'uppercase'}}>{this.props.name + ' '+ this.props.last_name}</Menu.Item>
                                {this.props.role ==="manager" || this.props.role ==="dealers_manager"?<Dropdown item simple text="ADMINISTRACIÓN">
                                    <Dropdown.Menu>
                                        {this.props.role ==="manager" || this.props.role ==="dealers_manager"?<Dropdown.Item active={true} onClick={()=> this.props.showComponent('models')}> 
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/models'}><Icon name='car'/> MODELOS</Link>
                                        </Dropdown.Item>:null}
                                        {this.props.role ==="manager" || this.props.role ==="dealers_manager"?<Dropdown.Item active={true} onClick={()=> this.props.showComponent('models')}> 
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/reports'}><Icon name='chart line'/> REPORTES</Link>
                                        </Dropdown.Item>:null}
                                        {this.props.role ==="manager"?<Dropdown.Item onClick={()=> this.props.showComponent('parts')}>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/parts'}><Icon name='cart'/> REPUESTOS</Link>
                                        </Dropdown.Item>:null}
                                        <Dropdown.Divider />
                                        <Dropdown.Header></Dropdown.Header>
                                        {this.props.role ==="manager" || this.props.role ==="dealers_manager"?<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/users'}><Icon name="users"/> USUARIOS</Link>
                                        </Dropdown.Item>:null}
                                        {this.props.role ==="manager" || this.props.role ==="dealers_manager"?<Dropdown.Item>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/complementary'}><Icon name="check"/>BENEFICIOS <br></br> COMPLEMENTARIOS</Link>
                                        </Dropdown.Item>:null}
                                        <Dropdown.Item>
                                        <i className='dropdown icon' />
                                        <span className='text'>PREPAGADO</span>
                                        {this.props.role ==="manager"?<Dropdown.Menu>
                                            {this.props.role ==="manager"?<Dropdown.Item onClick={()=> this.props.showComponent('dealers')}>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/dealers'}><Icon name='building'/> DISTRIBUIDORES</Link>
                                            </Dropdown.Item>:null}
                                            {this.props.role ==="manager"?<Dropdown.Item onClick={()=> this.props.showComponent('tempario')}>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/tempario'}><Icon name='clock outline'/> TEMPARIO</Link>
                                            </Dropdown.Item>:null}
                                            {this.props.role ==="manager"?<Dropdown.Item onClick={()=> this.props.showComponent('tmog')}>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/tmog'}><Icon name='dollar'/> TMOG</Link>
                                            </Dropdown.Item>:null}
                                            {this.props.role ==="manager"?<Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/offert'}><Icon name='road'/> OFERTA</Link>
                                            </Dropdown.Item>:null}
                                            {this.props.role ==="manager"?<Dropdown.Item>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/salesCommisions'}><Icon name='user'/> COMISIONES</Link>
                                            </Dropdown.Item>:null}
                                            
                                            {this.props.role ==="manager"?<Dropdown.Item onClick={()=> this.props.showComponent('commercialActions')}>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/commercialActions'}><Icon name='currency'/> ACCIONES COM</Link>
                                            </Dropdown.Item>:null}
                                            
                                            {this.props.role ==="manager"?<Dropdown.Item onClick={()=> this.props.showComponent('uploadReport')}>
                                                <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/uploadReport'}><Icon name='table'/> CARGAR REPORTE RETAIL</Link>
                                            </Dropdown.Item>:null}
                                        </Dropdown.Menu>:null}
                                        </Dropdown.Item>
                                        {this.props.role ==="manager"?<Dropdown.Item onClick={()=> this.props.showComponent('variables')}>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/variables'}><Icon name='database'/> VARIABLES</Link>
                                        </Dropdown.Item>:null}
                                        {this.props.role ==="manager"?<Dropdown.Item onClick={()=> this.props.showComponent('vins')}>
                                            <Link style={{textDecoration:'none', color:'#333333', padding:10}} to={'/vins'}><Icon name='car'/> VINS</Link>
                                        </Dropdown.Item>:null}
                                        
                                    </Dropdown.Menu>
                                </Dropdown>:null}
                                <Menu.Item as='a' onClick={()=> this.logOut()}  icon="log out"></Menu.Item>
                            </Menu.Menu>
                        </Container>
                    </Menu>
                </Responsive>
                <Responsive {...Responsive.onlyMobile}>
                    <Menu fixed="top" style={{ borderBottom:1, borderBottom:'4px solid #BB162B' }}>
                        <Menu.Item>
                            <Image style={{maxWidht:70}} src={ require('../assets/lg_kia.png')}  />
                        </Menu.Item>
                        <Menu.Item style={{ backgroundColor:"#454545", color:"#f6f6f6"}} position="right" onClick={this.handleOpen}>
                            <Icon name="bars" size="large" />
                        </Menu.Item>
                    </Menu>
                </Responsive>
                <Dimmer  active={menuActive} style={{}} onClickOutside={this.handleClose} page inverted>
                        <Image centered style={{ width:"80vw"}} src={logoKPlus} />
                        
                        <Header style={{color:"#454545"}} as='h2' image>
                            <Header.Content style={{color:"#AEAEAE"}}>
                                Mantenimiento <span style={{color:'#bb162b'}}> K </span> Plus
                            </Header.Content>
                        </Header>
                        
                        <List style={{textTransform:'uppercase', textDecoration:'none', width:"80vw", color:"#666666", fontFamily:"KiaBold", textAlign:"left"}} size="massive" divided verticalAlign="middle">
                            <List.Item to="/Home" as={Link} onClick={this.handleOpen}>
                                <List.Content floated="right">
                                    <Icon name="chevron circle right" />
                                </List.Content>
                                <List.Icon name="home" />
                                <List.Content style={{color:"#666666"}}>Principal</List.Content>
                            </List.Item>
                            <List.Item as={Link} to={"/quotation"} onClick={this.handleOpen}>
                                <List.Content floated="right">
                                    <Icon name="chevron circle right" />
                                </List.Content>
                                <List.Icon name="calculator" />
                                <List.Content style={{color:"#666666"}}>Cotizador</List.Content>
                            </List.Item>
                            <List.Item as={Link} to={'/orders'} onClick={this.handleOpen}>
                                <List.Content floated="right">
                                    <Icon name="chevron circle right" />
                                </List.Content>
                                <List.Icon name="box" />
                                <List.Content style={{color:"#666666"}}>Paquetes</List.Content>
                            </List.Item>
                            <List.Item as={Link} to={'/form'} onClick={this.handleOpen}>
                                <List.Content floated="right">
                                    <Icon name="chevron circle right" />
                                </List.Content>
                                <List.Icon name="wpforms"/>
                                <List.Content style={{color:"#666666"}}>Formulario</List.Content>
                            </List.Item>
                            <List.Item as={Link} to={'/redention'} onClick={this.handleOpen}>
                                <List.Content floated="right">
                                    <Icon name="chevron circle right" />
                                </List.Content>
                                <List.Icon name="redo" />
                                <List.Content style={{color:"#666666"}}>Redimir</List.Content>
                                
                            </List.Item>
                        </List>
                        <List style={{textTransform:'uppercase', width:"80vw", color:"#666666", fontFamily:"KiaBold", textAlign:"left"}} size="massive" divided verticalAlign="middle">
                            
                            
                            <List.Item onClick={()=> this.logOut()}>
                                <List.Content floated="right">
                                    <Icon name="chevron circle right" />
                                </List.Content>
                                <List.Icon name="sign-out" />
                                <List.Content>Cerrar Sesión</List.Content>
                            </List.Item>
                        </List>
                        <Button icon="times" onClick={this.handleOpen} style={{borderRadius:25, backgroundColor:"#bb162b", color:"#f6f6f6"}} size="large" />
                    
                </Dimmer>
            </Fragment>
            // <Menu fixed="top" inverted={true}>
            //     <Container>
                    // <Menu.Item as="header" >
                    //     <Image size='mini' src={ require('../assets/logo_color.png')} style={{ marginRight: '1.5em' }} />
                    //     SERVICE PACK
                    // </Menu.Item>
                    // <Menu.Item as='a' active={key==='home'} onClick={()=>this.props.showComponent('home')}>
                    //     <Icon name='user' />
                    //     <p style={{textTransform:'capitalize'}}>{this.props.name + ' '+ this.props.last_name}</p>
                    // </Menu.Item>
                    // <Menu.Item as='a' active={key==='simulator'} onClick={()=>this.props.showComponent('simulator')}>
                    //     <Icon name="tags"/>
                    //     Cotizador
                    // </Menu.Item>
                    // <Menu.Item as="a" active={key==='orders'} onClick={()=>this.props.showComponent('orders')}>
                    //     <Icon name="file alternate outline"/>
                    //     Pedidos
                    // </Menu.Item>
                    

            //         <Menu.Menu position='right'>
            //             <Menu.Item as="a" onClick={()=> this.props.showComponent('carshop')}>
            //                 <Icon.Group>
            //                     <Icon name="shopping cart"/>
            //                     {this.props.order.plan.routine?<Label size="mini" circular color="red">{this.props.order.plan.routine} K</Label>:null}
            //                 </Icon.Group>
                            
            //             </Menu.Item>
            //             {this.props.role ==="manager"?<Dropdown item simple text={this.props.role} icon="setting">
            //                 <Dropdown.Menu>
            //                     <Dropdown.Item active={true} onClick={()=> this.props.showComponent('models')}> <Icon name='car'/> Modelos</Dropdown.Item>
            //                     <Dropdown.Item onClick={()=> this.props.showComponent('parts')}> <Icon name='cart'/>Repuestos</Dropdown.Item>
            //                     <Dropdown.Divider />
            //                     <Dropdown.Header>Variables</Dropdown.Header>
            //                     <Dropdown.Item>
            //                     <i className='dropdown icon' />
            //                     <span className='text'>Prepagado</span>
            //                     <Dropdown.Menu>
            //                         <Dropdown.Item onClick={()=> this.props.showComponent('tempario')}><Icon name="clock outline"/> Tempario</Dropdown.Item>
            //                     </Dropdown.Menu>
            //                     </Dropdown.Item>
            //                     <Dropdown.Item onClick={()=> this.props.showComponent('variables')}><Icon name="database"/> Variables</Dropdown.Item>
            //                 </Dropdown.Menu>
            //             </Dropdown>:null}
            //             <Menu.Item onClick={()=> this.logOut()}>
            //                 <Icon name="log out"></Icon>
            //             </Menu.Item>
            //         </Menu.Menu>
            //     </Container>
            // </Menu>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token:state.token,
        name: state.user.data.name,
        last_name: state.user.data.last_name,
        role: state.user.data.role,
        componentActive: state.showComponent,
        order: state.order
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logOutApp: ()=> dispatch(logOutApp()),
        showComponent: (component)=> dispatch(showComponent(component))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarComponent)