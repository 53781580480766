import React, { Component } from 'react';
import { Grid, Container, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';

import FormCustomer from '../../../components/Forms/FormCustomerComponent';

class CarShopScreen extends Component {
    render(){
        return (
        <Grid style={{marginTop:30 }}>
            <Container>
                <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                    ORDEN DE PEDIDO
                </Header>
                <FormCustomer></FormCustomer>
            </Container>
        </Grid>)
    }
}

const mapStateToProps = state => {
    return {
        order: state.order
    }
}

export default connect(mapStateToProps)(CarShopScreen)
