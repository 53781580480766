import { getApi } from '../../utils/Constants';

const api = getApi();
const apiModule = 'dealer/dealerByDealer';
const token = localStorage.getItem('token');

async function dealerByDealer( body ){
    return  await fetch(api+apiModule, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body:JSON.stringify({
            dealer:body.dealer
        })
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function dealerByGroup(){
    return await fetch(api+'dealer/dealerByGroup', {
        method:'GET',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        }
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

const getDealers = async () => {
    return await fetch(api+'/dealers', {
        method:'GET',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        }
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

const newDealers = async ( body ) => {
    return await fetch(api+'/dealers', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify( body )
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

const editDealer = async ( body ) => {
    return await fetch(api+`/dealers/${body._id}`, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify( body )
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

export {
    dealerByDealer,
    dealerByGroup,
    getDealers,
    newDealers,
    editDealer
}