import React, { Component, Fragment } from 'react';
import { InputFile } from 'semantic-ui-react-input-file';
import { Header, Button, Icon, List, Segment } from 'semantic-ui-react';
import { domain } from '../../../../constants';
import { uploadFile, upDateOrder } from '../../../../services/orders/orderService';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import { MessageToUser } from '../../../../actions';

class AttachAgreement extends Component {
    constructor(props){
        super(props)
        this.state = { fileSelect:null, fileName:'' }
    }
    handleUpload = (e)=>{
        if(e.target.files && e.target.files[0]){
            this.setState({ fileSelect:e.target.files[0], fileName:e.target.files[0].name})
        }
    }

    uploadFile =()=>{
        if(!this.state.fileSelect){
            return this.props.messageToUser(
                'Aún no se ha seleccionado un archivo', 
                'Por favor selecciona el acuerdo, puedes seleccionarlo haciendo click sobre el boton',
                true)
        }
        const { order } = this.props;
        let temporalOrder = order;
        const formData = new FormData()
        formData.append('file', this.state.fileSelect);
        let param = {
            dealer:this.props.order.dealer,
            title: 'ACUERDO'
        }
        uploadFile(formData, param)
        .then( data =>{
            temporalOrder.attach.push({
                title:`agreement`,
                comments:`Acuerdo de mantenimiento prepagado: ${order.form.vin}`,
                url:`${domain}${order.dealer}/${data.filename}`,
                name:data.originalname,
                checked:false,
                checkedImport:false,
                create_at:new Date(Date.now()),
                response:{ status:'Pendiente', date:new Date(Date.now()), comments:'' },
                responseImport:{ status:'Pendiente', date:new Date(Date.now()), comments:'' }
            })
            this.setState({order:temporalOrder}, ()=>{ this.updateOrder()})
        }).catch(error =>{
            console.log(error)
        })
    }

    updateOrder=()=>{
        const { order } = this.state;
        upDateOrder( order )
        .then( data =>{
            this.MessageToast('info', 'Genial', 'Se actualizado la orden con éxito', 'database')
        }).catch(error =>{
            this.props.messageToUser('Los sentimos', 'Ocurrió un error al actualizar la orden', true)
            console.log(error)
        })
    }

    MessageToast = (type, title, message, icon) =>{
        toast({
            type: type,
            icon: icon,
            title: title,
            description: message,
            animation: 'bounce',
            time: 5000,
        })
    }

    renderAttach =()=> {
        const { attach } = this.props.order;
        const { fileName } = this.state;
        if(attach && attach.length>0){
            return <List>
                    {attach.map( (i)=>{
                        if(i.title === 'agreement'){
                            return <List.Item>
                                <List.Icon color='#BB162B' name='file pdf' size='large' verticalAlign='middle' />
                                <List.Content>
                                    <List.Header>{i.name}</List.Header>
                                    <List.Description>{i.comments}</List.Description>
                                </List.Content>
                            </List.Item>
                        } else {
                            return null
                        }
                        
                    })}
            </List>
        } else {
            return <Segment placeholder>
            <Header icon>
              <Icon name='pdf file outline' />
              Aún no se ha cargado el acuerdo
            </Header>
            <InputFile
                button={{label:fileName? fileName:'Elige un archivo' }}
                input={{
                    id:'input-upload-id',
                    onChange:this.handleUpload
                }}
            />
          </Segment>
        }
        
    }

    renderButtonFinish = () => {
        const {attach} = this.props.order;
        let data = attach.filter( (i)=>{
            if(i.title === 'agreement'){
                return i
            } else {
                return null
            }
        })
        
        if(data && data.length>0){
            return <Button onClick={()=>window.location.reload()} style={{backgroundColor:'#BB162B', borderRadius:25, color:'#F6F6F6'}}>Terminar</Button>
        }
    }

    render(){
        const { fileName } = this.state;
        return (
            <Fragment>
                <Header style={{color:'#333333'}}>
                    Carga el acuerdo
                    <Header.Subheader>
                        Luego de imprimir y recolectar la firma del cliente y representante del concesionario, escanee el documento y cárguelo en el siguiente campo
                    </Header.Subheader>
                </Header>

                <InputFile
                    button={{label:fileName? fileName:'Elige un archivo' }}
                    input={{
                        id:'input-upload-id',
                        onChange:this.handleUpload
                    }}
                />
                {fileName?<Button onClick={()=> this.uploadFile()} style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}}>
                    <Icon name="save" color='#F6F6F6'/> Guardar
                </Button>:null}
                <Header style={{marginTop:40, color:'#333333'}}>
                    Documentos cargados
                </Header>
                {this.renderAttach()}

                {this.renderButtonFinish()}
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser:(title, message, open)=>dispatch(MessageToUser(title, message, open))
    }
}

export default connect(null, mapDispatchToProps)(AttachAgreement);