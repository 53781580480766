import React, { Component, Fragment } from 'react';
import { Grid, Container, Header, Message, Button, Icon, Modal, Form, Table, Checkbox } from 'semantic-ui-react';
import { MessageToUser } from '../../../../actions'
import { connect } from 'react-redux';
import { frequencyOption } from '../../../../utils/Constants';
import { create, read, update } from '../../../../services/complementary/complementaryServices';
import moment from 'moment';

class ComplementaryServices extends Component {
    state = {
        openModalAdd:false,
        actionModal:'',

        // New complementary service
        newService:'',
        newFrequency:'',
        newAmount:'',
        newTerms:'',
        group:'',

        services:[]
    }

    componentDidMount(){
        this.getServices()
    }

    handleChange = (e, {name, value})=>{
        this.setState({[name]: value})
    }

    createService = ()=>{

        const { newService, newAmount, newFrequency, newTerms } = this.state;
        console.log(this.props.user)
        let body = {
            service : newService,
            amount : newAmount,
            frequency : newFrequency,
            terms : newTerms,
            group : this.props.user.group
        }
        create( body )
        .then( (data)=>{
            this.props.messageToUser('Resultado de la operación', data.message, true);
            this.getServices()
        }, error =>{
            console.log(error)
        } )
    }

    getServices = () => {
        const { group } = this.props.user
        read({group:group})
        .then( (data)=>{
            console.log(data)
            this.setState({services:data})
        })
    }

    updateServices = ( service) => {
        service.active = !service.active;
        update( service )
        .then( data => {
            this.props.messageToUser('Resultado de la operación', data.message, true);
            this.getServices()
        } )
    }

    ModalService = ()=>{
        const { openModalAdd, newService, newAmount, newFrequency, newTerms } = this.state;
        return <Modal open={openModalAdd} >
            <Modal.Header>
                <Header as="h3" icon="add" content="SERVICIO COMPLEMENTARIO"></Header>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input name="newService" label="Nombre del Servicio" value={newService} onChange={this.handleChange} />
                        <Form.Select options={frequencyOption} label="Frecuencia" value={newFrequency} name="newFrequency" onChange={this.handleChange}/>
                        <Form.Input type='number' min={0} name="newAmount" label="Cantidad" value={newAmount} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.TextArea name="newTerms" value={newTerms} onChange={this.handleChange} label="Términos y Condiciones" />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={()=>this.setState({ openModalAdd:false })} size="small" content="CERRAR" icon="times" style={{borderRadius:25}} />
                <Button onClick={()=>this.createService()} size="small" content="GUARDAR" icon="save" style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}} />
            </Modal.Actions>
        </Modal>
    }

    renderServices = () => {
        const { services } = this.state;
        let seeMore = []
        if(services && services.length>0){
            return services.map( (i, index)=>{
                seeMore.push({ active: false })
                return <Table.Row key={i.id}>
                    <Table.Cell style={{textTransform:'capitalize'}}>{i.service}</Table.Cell>
                    <Table.Cell>{i.frequency}</Table.Cell>
                    <Table.Cell>{i.group}</Table.Cell>
                    <Table.Cell>{moment(i.create).format('DD-MM-YYYY')}</Table.Cell>
                    <Table.Cell>{i.amount}</Table.Cell>
                    <Table.Cell style={{ fontSize:12, textAlign:'justify' }}>
                        {i.terms}
                    </Table.Cell>
                    <Table.Cell>{i.active}
                        <Button.Group size="tiny">
                            <Checkbox onClick={()=> this.updateServices(i)  } toggle checked={i.active} />
                        </Button.Group>
                    </Table.Cell>
                </Table.Row>
            } )
        }
    }
    render(){
        return(
            <Fragment>
                <Grid style={{ minHeight:'80vh', marginTop:'7vh', marginBottom:50, marginLeft:50, marginRight:50 }}>
                    <Container fluid>
                        <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                            SERVICIOS COMPLEMENTARIOS
                            <span>
                                {/* <Button onClick={()=>this.setState({openModalFilter:true })} size="mini" style={{borderRadius:25}} floated='right' icon="search" content="BUSCAR" /> */}
                                <Button onClick={()=> this.setState({openModalAdd:true, actionModal:'crear', clOptions:[]}) } size="mini" style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} floated='right'><Icon name="add"/> SERVICIO </Button>
                            </span>
                        </Header>

                        <Table selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Servicio</Table.HeaderCell>
                                    <Table.HeaderCell>Frecuencia</Table.HeaderCell>
                                    <Table.HeaderCell>Concesión</Table.HeaderCell>
                                    <Table.HeaderCell>Fecha creación</Table.HeaderCell>
                                    <Table.HeaderCell>Cantidad</Table.HeaderCell>
                                    <Table.HeaderCell>Términos y Condiciones</Table.HeaderCell>
                                    <Table.HeaderCell>Acciones</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderServices()}
                            </Table.Body>
                        </Table>
                    </Container>
                </Grid>
                {this.ModalService()}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data
    }
}
const mapDispatchToProps = dispatch =>{
    return {
        messageToUser: (title, message, open)=>dispatch(MessageToUser(title, message, open))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ComplementaryServices)