import React, { Component, Fragment } from 'react';
import { Header, Grid, Icon, Container, Segment, Table, Button, Placeholder, Modal, Form, Label, Pagination} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { GetModelTemplate, craeateModelTemplate, GetCountModelTemplate } from '../../../actions/index';
import { getModelsTemplate, deletModelTemplate } from '../../../services/modelTemplate/modelTemplateService';
import { update } from '../../../api/modelTemplate/create';
import ItemModelTemplate from '../../../components/ItemModelTemplateComponent';

const initialModelTemplate = {
    _id: "",
    cl: "",
    cod: "",
    name: "",
    supply: "",
    mto5: "",
    mo: "",
    mto10: "",
    mto15: "",
    mto20: "",
    mto25: "",
    mto30: "",
    mto35: "",
    mto40: "",
    mto45: "",
    mto50: "",
    key: "",
    createUp: "",
    update: "",
    __v: 0,
    edit: false,
    active: true,
    vin: []
}

class ModelsScreen extends Component {
   state = { 
        active:true, 
        totalPages:10,
        skip:0, 
        itemsPerpage:5,
        dealers:[], loading:true, 
        openForm:false,
        action:'',
        modelOption:[],
        dealerOption:[],
        itemToEdit:initialModelTemplate,
        modelTemplates:[]
    }

    componentDidMount(){
        if(this.props.dealers){
            this.getModels();
        }
        if(this.props.models.length>0){
            let temporalModelOption = []
            this.props.models.map( (i, index)=>{
                return temporalModelOption.push({
                   key:index,
                   text:i.model,
                   value:i.model,
                   vins:i.vin
               })
           } ) 
           this.setState({modelOption: temporalModelOption})
        }
        if(this.props.dealers){
            this.props.dealers.sort()
            let temportalDealerOption = []
            if(this.props.dealers && this.props.dealers.length>0){
                this.props.dealers.map( (i, index)=>{
                    return temportalDealerOption.push({
                        key:index,
                        text:i,
                        value:i
                    })
                } )
            }
            this.setState({dealerOption:temportalDealerOption})
        }
        
    }

    handledChange =( e, {name, value})=>{
        this.setState({
            itemToEdit:{ ...this.state.itemToEdit, [name]: value}
        }, ()=>{
            if(this.state.cod){
                this.selectModel()
            }
        })
    }

    pageChange=($event)=>{
        const { itemsPerpage } = this.state;
        let activePage = $event.activePage || 1
        this.setState({ skip: (activePage-1) * itemsPerpage }, ()=>{
            this.getModels()
        });
    }

    getModels = () => {
        // this.setState({loading:true})
        // const { active, skip, itemsPerpage } = this.state;
        const { dealers } = this.props;
        // let body = {
        //     active:active,
        //     skip:skip,
        //     dealers:dealers,
        //     itemsPerpage:itemsPerpage
        // }
        // this.setState({dealers:dealers})
        // this.props.getModelTemplate(body);
        // this.props.getCountModelTemplate(body);
        
        // if(this.props.count.count){
        //     let difference = this.props.count.count % itemsPerpage;
        //     let totalPages = 0;
        //     if(difference>0){
        //         totalPages = parseInt(this.props.count.count / itemsPerpage) + 1;
        //     } else {
        //         totalPages = parseInt(this.props.count.count / itemsPerpage);
        //     }
        //     this.setState({totalPages: totalPages, totalTemplates:this.props.count.count})
        // }
        // setTimeout( ()=>{
        //     this.setState({loading:false})
        // }, 200 )
        let body = {
            active:true,
            skip:0,
            dealers:dealers,
            itemsPerpage:10
        }
        getModelsTemplate( body )
        .then( data => {
            this.setState({
                modelTemplates: data,
                loading:false
            })
        } )
    }

    updateModels = () => {
        const { itemToEdit } = this.state;
        
        update(itemToEdit)
        .then( data  => {
            this.getModels()
            console.log(data)
        })
    }

    deletModels = (itemToEdit) => {
        
        deletModelTemplate( itemToEdit )
        .then( result => {
            console.log(result)
            if( result.status === 200) return this.getModels()
        } )
    }

    selectModel=()=>{
        const { cod } = this.state; 
        
        for(let i of this.state.modelOption){
            if(i.value === cod){
                
                this.setState({
                    vin:i.vins,
                    selectModel:true
                }, ()=>{ this.renderVinTags()})
            }
        }
    }

    renderVinTags=()=>{
        const { vin } = this.state;
        return vin.map( (i, index)=>{
            return (<Label key={index}>
                {i.vin}
                <Icon name='delete' />
            </Label>)
        } )
    }

    renderVinTagsEdit=()=>{
        const { vin } = this.state.itemToEdit;
        if(vin){
            return vin.map( (i, index)=>{
                return (<Label key={index}>
                    {i.vin}
                    <Icon name='delete' />
                </Label>)
            } )
        }
        
    }

    newModelForm = () => {
        const { openForm, action, dealerOption, selectModel  } = this.state;
        const {
            cl, 
            cod, 
            name, 
            vin, 
            supply, 
            mo, 
            mto5, 
            mto10, 
            mto15, 
            mto20, 
            mto25, 
            mto30, 
            mto35, 
            mto40, 
            mto45, 
            mto50,
            onClickEdit,
            onDelet
        } = this.state.itemToEdit;

        return <Modal size='large' open={openForm} onClose={()=>this.closeModal('openNewModelForm')}>
                <Modal.Header>
                    {action === 'new'
                        ? <Fragment><Icon name='plus' /> NUEVO TEMPARIO </Fragment>
                        : <Fragment><Icon name='edit' /> EDITAR TEMPARIO </Fragment>
                    }
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Select onChange={ this.handledChange} options={dealerOption} value={cl} label="Distribuidor" name="cl" placeholder="Código distribuidor"></Form.Select>
                            <Form.Input onChange={ this.handledChange} label="Código modelo" value={cod} name="cod" placeholder="Código modelo"/>
                            <Form.Input onChange={this.handledChange} label="Modelo" value={name} name="name" placeholder="Nombre del modelo"></Form.Input>
                            <Form.Input onChange={this.handledChange} label="Insumos" type="number" value={supply} name="supply" placeholder="Insumos"></Form.Input>
                            <Form.Input onChange={this.handledChange} label="Valor Mano de Obra" value={mo} name="mo" placeholder="Valor Mano de Obra"></Form.Input>
                        </Form.Group>
                    </Form>
                    {selectModel?<Segment>
                        {this.renderVinTags()}
                    </Segment>:null}

                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Kilomentraje (KM)</Table.HeaderCell>
                                <Table.HeaderCell>Rutina</Table.HeaderCell>
                                <Table.HeaderCell>Horas (Hr)</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>5.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto5} name="mto5"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>10.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto10} name="mto10"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>15.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto15} name="mto15"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>20.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto20} name="mto20"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>25.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto25} name="mto25"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>30.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto30} name="mto30"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>35.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto35} name="mto35"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>40.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto40} name="mto40"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>45.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto45} name="mto45"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>50.000</Table.Cell>
                                <Table.Cell>MTO - Mantenimiento</Table.Cell>
                                <Table.Cell>
                                    <Form.Input onChange={this.handledChange} type="number" value={mto50} name="mto50"></Form.Input>
                                </Table.Cell>
                            </Table.Row>

                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        size='tiny'
                        onClick={()=>this.setState({ openForm:false, action:''})}
                        style={{borderRadius:25}}
                        icon='times' 
                        content="CANCELAR" />
                    {action === 'new'
                        ?<Button
                            size='tiny'
                            icon='save'
                            onClick={()=>{this.props.craeateModelTemplate(this.state.itemToEdit); setTimeout( ()=>{ this.setState({openForm:false, action:''}); this.getModels() }, 1000 )}}
                            content='GUARDAR'
                            style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}} />
                        :<Button
                            size='tiny'
                            icon='save'
                            onClick={()=>{this.updateModels(); setTimeout( ()=>{ this.setState({openForm:false,  action:''}) }, 1000 )}}
                            content='GUARDAR CAMBIOS'
                            style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}} />
                    }
                </Modal.Actions>
            </Modal>
    }

    renderItem = () => {
        const { modelTemplates } = this.state;
        if(modelTemplates.length>0){
            return modelTemplates.map( (item, i)=> {
                
                return <ItemModelTemplate
                    cl={item.cl}
                    cod={item.cod}
                    mo={item.mo}
                    mto5={item.mto5}
                    mto10={item.mto10}
                    mto15={item.mto15}
                    mto20={item.mto20}
                    mto25={item.mto25}
                    mto30={item.mto30}
                    mto35={item.mto35}
                    mto40={item.mto40}
                    mto45={item.mto45}
                    mto50={item.mto50}
                    supply={item.supply}
                    name={item.name}
                    onClickEdit={()=>this.setState({action:'edit', openForm:true, itemToEdit:item})}
                    onDelet={ ()=> this.deletModels(item) }
                    key={item._id}
                    vin={item.vin}
                />
            } )
        } else {
            return null
        }
    }

    noItemToRender = () => {
        const { modelTemplates } = this.state;
        if(modelTemplates.length>0) return null
        return <Segment placeholder>
                    <Header icon>
                        <Icon name='road' />
                        Aún no hay temparios creados
                        <Header.Subheader>
                            Puede crear uno nuevo en el siguiente botón
                        </Header.Subheader>
                    </Header>
                    <Button 
                        content='AGREGAR TEMPARIO'
                        icon='plus'
                        style={{backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25}}
                        size="medium" 
                        onClick={()=> this.setState({openNewModelForm:true})}/>
                </Segment>
    }

    placeHolder = () => {
        return <Placeholder fluid>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    }



    render(){
        const { loading, totalPages, active } = this.state;
        
        return(
            <Grid style={{marginTop:'8vh', marginBottom:50, marginLeft:50, marginRight:50}}>
                <Container fluid>
                    <Grid.Column>
                        <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                            TEMPARIOS POR MODELO
                            <Button 
                                onClick={()=> this.setState({openForm:true, action:'new'})} 
                                floated='right' 
                                content="AGREGAR TEMPARIO"
                                icon="add" 
                                style={{backgroundColor:'#BB162B', color:'#f6f6f6', borderRadius:25}} 
                                size='tiny' />
                                <Button 
                                    size="tiny"
                                    content={active?'Activos':"Inactivos"}
                                    floated='right'
                                    icon={active? 'circle outline':'minus'} 
                                    onClick={()=>{this.setState({active: !this.state.active, skip:0}, ()=>{ this.getModels() })}}
                                    style={{borderRadius:25}}/>
                        </Header>
                        
                        {!loading?<Table selectable size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Cód Modelo</Table.HeaderCell>
                                    <Table.HeaderCell>Modelo</Table.HeaderCell>
                                    <Table.HeaderCell>Distribuidor</Table.HeaderCell>
                                    <Table.HeaderCell>Vin's</Table.HeaderCell>
                                    <Table.HeaderCell>Insummos</Table.HeaderCell>
                                    <Table.HeaderCell>Valor Mano de Obra</Table.HeaderCell>
                                    
                                    <Table.HeaderCell>MTO 5</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 10</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 15</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 20</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 25</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 30</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 35</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 40</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 45</Table.HeaderCell>
                                    <Table.HeaderCell>MTO 50</Table.HeaderCell>
                                    <Table.HeaderCell>Acciones</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderItem()}
                            </Table.Body>
                            
                        </Table>:this.placeHolder()}
                        {/* <Pagination onPageChange={(event, data)=>this.pageChange(data)}
                            defaultActivePage={1}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={totalPages}
                            siblingRange={1}
                            boundaryRange={1}
                        /> */}
                        {this.noItemToRender()}
                    </Grid.Column>
                </Container>
                {this.newModelForm()}
            </Grid>
        )
    }
}

const mapStateToprops = state => {
    return {
        modelTemplate: state.modelTemplate.data,
        count: state.modelTemplateCount.data,
        dealers: state.user.data.dealer,
        models: state.models.data,
        message: state.message
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getModelTemplate: (body)=> dispatch(GetModelTemplate(body)),
        craeateModelTemplate: (body)=> dispatch(craeateModelTemplate(body)),
        getCountModelTemplate: (body)=> dispatch(GetCountModelTemplate(body))
    }
}



export default connect(mapStateToprops, mapDispatchToProps)(ModelsScreen)