import { API } from '../../constants';
const path = '/models/getGroupModels';
const token = localStorage.getItem('token')

export default async ()=> {
    return await fetch(API+path, {
        method:'POST',
        headers:{
            'Accept':'applciation/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        }
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        return responseJSON
    } ).catch( (error)=> {
        return error
    } )
}

async function update() {
    return await fetch(API+path, {
        method:'POST',
        headers:{
            'Accept':'applciation/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        }
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        return responseJSON
    } ).catch( (error)=> {
        return error
    } )

}

export {
    update
}