const initialState = {
    id:''
}

const OrderSelectIdReducer = (state=initialState, action)=>{
    switch(action.type){
        case 'SelectOrder':
            return{
                ...state,
                id:action.id
            }
        default: 
            return state
    }
}

export default OrderSelectIdReducer