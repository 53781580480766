import { getApi } from '../../utils/Constants';

const api = getApi();
const path = '/prepaidMant/modelTemplate/getModelTemplate';
const token = localStorage.getItem('token')

async function getModelsTemplate (body){
    return await fetch(api + path, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify({
            active:body.active,
            itemsPerpage:body.itemsPerpage,
            skip:body.skip,
            dealers:body.dealers
        })
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        return responseJSON
    } ).catch( (error)=> {
        return error
    } )
}

async function deletModelTemplate (body){
    return await fetch(api + '/prepaidMant/modelTemplate/deletModelTemplate', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify(body)
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        return responseJSON
    } ).catch( (error)=> {
        return error
    } )
}



export  {
    getModelsTemplate,
    deletModelTemplate
}