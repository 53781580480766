import { getApi } from '../../utils/Constants';

const api = getApi();
const apiModule = 'prepaidMant/variables/';
const token = localStorage.getItem('token');

async function getVariables(signal){
    
    return await fetch(api+apiModule+'getVariable', {
        signal:signal,
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify({
            active:true
        })
    }).then( (variables) => variables.json() )
    .then( (response)=>{
        return response;
    }).catch( (error)=>{
        return error;
    } )
}

export {
    getVariables
}