import React, { Component, Fragment } from 'react'
import { Grid, Container, Header, Button, Modal, Form, Table, Segment } from 'semantic-ui-react'
import { MessageToUser} from '../../../../actions'
import { connect } from 'react-redux'
import { routines } from '../../../../utils/Constants'
import { create, read, update} from '../../../../services/commision'

const initialCommision = {
    routine:5,
    value:0,
    bono:0,
    _id:''
}

class SalesCommision extends Component {
    
    state = {
        commisionModalOpen:false,
        use:'',
        commision: initialCommision,
        commisions: []
    }

    componentDidMount(){
        this.read()
    }

    handleChange = (e, { name, value }) => {
        this.setState({
            commision:{
                ...this.state.commision,
                [name]: value
            }
        })
    }

    create = () => {
        const { commision } = this.state
        create( commision )
        .then( result => {
            this.props.messageToUser( result.status===200?'OPERACIÓN EXITOSA':'ERROR', result.msg, true)
            this.setState({
                commision: initialCommision,
                commisionModalOpen: false
            })
            this.read()
        })
    }

    read = () => {
        read()
        .then( result => {
            console.log(result)
            this.setState({
                commisions: result
            })
        } )
    }
    update = () => {
        const { commision } = this.state
        update( commision )
        .then( result => {
            this.props.messageToUser( result.status===200?'OPERACIÓN EXITOSA':'ERROR', result.msg, true)
            if(result.status === 200){
                this.setState({
                    commision: initialCommision,
                    commisionModalOpen: false
                })
                this.read()
            }
            
        })
    }

    editOpen = (commision) => {
        this.setState({
            commision: commision,
            use: 'edit',
            commisionModalOpen: true
        })
    }



    renderCommision = () => {
        const { commisions } = this.state;

        if(!(commisions && commisions.length>0)) return null
        return <Table size='small' selectable >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>RUTINA</Table.HeaderCell>
                    <Table.HeaderCell>PORCENTAJE</Table.HeaderCell>
                    <Table.HeaderCell>BONO</Table.HeaderCell>
                    <Table.HeaderCell>ACCIONES</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {commisions.map( (i)=>{
                    return <Table.Row key={i._id}>
                        <Table.Cell> {i.routine} </Table.Cell>
                        <Table.Cell> {i.value} </Table.Cell>
                        <Table.Cell> {i.bono} </Table.Cell>
                        <Table.Cell> 
                            <Button
                                size='tiny'
                                icon="edit" 
                                style={{ backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25 }}
                                onClick={()=> this.editOpen(i)}
                            />
                        </Table.Cell>
                    </Table.Row>
                } )}
            </Table.Body>
        </Table>
    }



    comisionModal = () => {
        const { commisionModalOpen, use, commision } = this.state
        return <Modal open={commisionModalOpen} >
            <Modal.Header>
                COMISION
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Select onChange={this.handleChange} options={routines()} label='Rutina' name='routine' value={commision.routine} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} label='% Porcentaje' type='number' max={100} name='value' value={commision.value} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.handleChange} label='Bono' type='number' name='bono' value={commision.bono} />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    size='tiny'
                    content='CANCELAR'
                    icon="times"
                    style={{ borderRadius:25 }}
                    onClick={()=> this.setState({ commisionModalOpen: false })}
                />
                {use==='create'?<Button
                    size='tiny'
                    content='GUARDAR'
                    icon='save'
                    style={{ backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25 }}
                    onClick={()=> this.create()}
                />: null}
                {use==='edit'?<Button
                    size='tiny'
                    content='ACTUALIZAR'
                    icon='save'
                    style={{ backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25 }}
                    onClick={()=> this.update()}
                />: null}
            </Modal.Actions>
        </Modal>
    }
    render(){
        return <Fragment>
            <Grid style={{ marginTop:'8vh', minHeight:'80vh' }}>
                <Container>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        COMISIONES
                    </Header>
                    <Segment basic textAlign='right'>
                        <Button
                        
                            size='tiny'
                            content='AGREGAR'
                            icon='add'
                            onClick={()=>this.setState({ commisionModalOpen:true, use:'create'})} 
                            style={{ backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25 }}
                        />
                    </Segment>
                    <p> </p>
                    {this.renderCommision()}
                </Container>
            </Grid>
            {this.comisionModal()}
        </Fragment>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, msg, open) => dispatch(MessageToUser(title, msg, open))
    }
}
export default connect(null, mapDispatchToProps)(SalesCommision)