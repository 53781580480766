import { API } from '../constants';
const path = '/prepaidMant/modelTemplate/getModelTemplate';
const token = localStorage.getItem('token')

export default async (body)=>{
    return await fetch(API + path, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify({
            active:body.active,
            itemsPerpage:body.itemsPerpage,
            skip:body.skip,
            dealers:body.dealers
        })
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        return responseJSON
    } ).catch( (error)=> {
        return error
    } )
}