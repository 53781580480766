const initialState = {
    key:'home'
}

const showComponentReducer = (state = initialState, action) => {
    switch(action.type){
        case 'home':
            return {
                ...state,
                key:action.type
            }
        case 'parts':
            return {
                ...state,
                key:action.type
            }
        case 'models':
            return {
                ...state,
                key:action.type
            }
        case 'variables':
            return {
                ...state,
                key: action.type
            }
        case 'tempario':
            return {
                ...state,
                key:action.type
            }
        case 'simulator':
            return {
                ...state,
                key:action.type
            }
        case 'carshop':
            return {
                ...state,
                key:action.type
            }
        case 'orders':
            return {
                ...state,
                key:action.type
            }
        case 'orderSelect':
            return {
                ...state,
                key:action.type
            }
        case 'activation':{
            return {
                ...state,
                key: action.type
            }
        }
        case 'home':
            return {
                ...state,
                key:action.type
            }
        default:
            return state
    }
}

export default showComponentReducer
