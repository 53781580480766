import { API } from '../../constants';
const path = 'prepaidMant/Simulator/getProjection'
const token = localStorage.getItem('token')

 async function getProjection ( body ){
     return fetch(API+path, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            version: body.version,
            cl: body.cl

        })
     }).then( (variables) => variables.json() )
     .then( (response)=>{
         return response;
     }).catch( (error)=>{
         return error;
     } )
 }


export {
    getProjection
}