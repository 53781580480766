import React, { Component } from 'react';
import { Header, Form, Button, Segment, Icon, Modal } from 'semantic-ui-react';

import { getApi, getMotorOption, getAireOption, getTransmissionOption } from '../../utils/Constants';

import PreloaderComponent from '../PreloaderComponent';

const motorOption = getMotorOption();
const aireOption = getAireOption();
const transmissionOption = getTransmissionOption();
 
const token = JSON.stringify(localStorage.getItem('token'));


export default class EditReferenceComponent extends Component {
    constructor(props){
        super(props)
        this.state = { 
            id:'',
            model:'', 
            ref:'',
            description:'', 
            amount:'',
            routine:'',
            cost:'', 
            pvc:'',
            pvp:'',
            mg_pvc:'',
            mg_pvp:'',
            active:'',
            motor:'',
            aa:'',
            transmission:'', 
            displacement:'',
            saving:false,
            openModal:false,
            errorMessage:'',
            loadingComponent:false
        }
    }

    componentDidMount(){
        const { 
            id,
            model, 
            reference, 
            description, 
            amount,
            routine,
            cost, 
            pvc, 
            pvp, 
            mg_pvc, 
            mg_pvp,
            motor, 
            aa, 
            transmission, 
            displacement,
            } = this.props;
        this.setState({
            id:id,
            model:model, 
            ref:reference, 
            description:description, 
            amount:amount,
            routine:routine,
            cost:cost, 
            pvc:pvc, 
            pvp:pvp, 
            mg_pvc:mg_pvc, 
            mg_pvp:mg_pvp,
            motor:motor, 
            aa:aa, 
            transmission: transmission, 
            displacement:displacement,
            loadingComponent:true
        })
    }

    handleChange = (e, { name, value })=>{
        if(value === null){
            this.setState({[name]: value})
        } else {
            this.setState({[name]: value.toUpperCase()})
        }
    }

    updateReference = ()=>{
        const { id, model, 
            ref, 
            description, 
            cost, 
            amount,
            routine,
            pvc, 
            pvp, 
            mg_pvc, 
            mg_pvp,
            motor, 
            aa, 
            transmission, 
            displacement } = this.state;
        fetch(getApi()+'prepaidMant/reference/updateReference', {
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'authorization':'Bearer '+token
            },
            body:JSON.stringify({
                _id:id,
                model:model,
                ref:ref,
                description:description,
                cost:cost,
                amount:amount,
                routine:routine,
                pvc:pvc,
                pvp:pvp, 
                mg_pvc:mg_pvc, 
                mg_pvp:mg_pvp,
                motor:motor, 
                aa:aa, 
                transmission:transmission, 
                displacement:displacement
            })
        }).then( (response)=> response.json() )
        .then( (responseJson)=>{
            console.log(responseJson)
            this.props.updateReferences();
        } ).catch( (error)=>{
            console.log(error)
        })
    }

    renderModal=(open, message)=>{
        return(
            <Modal open={open} basic size='small' closeOnDimmerClick={true} closeOnEscape={true} onClose={ ()=>{this.setState({openModal:false})}}>
                <Header icon='database' content='Algo salió mal' />
                <Modal.Content>
                <p>
                    {message}
                </p>
                </Modal.Content>
                <Modal.Actions>
                <Button onClick={()=> {this.setState({openModal:false}); this.updateReference()}} primary color='green' inverted>
                    <Icon name='redo' /> Intentar nuevamente
                </Button>
                <Button onClick={()=> {this.setState({openModal:false})}} inverted>
                    <Icon name='remove' /> Cancelar
                </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    render(){
        const {  onClickCancel } = this.props;
        const {  model, 
            ref, 
            description,
            cost, 
            amount,
            routine,
            pvc, 
            pvp, 
            mg_pvc, 
            mg_pvp,
            motor, 
            aa, 
            transmission, 
            displacement,
            loadingComponent, openModal, errorMessage } = this.state;
        
        if(!loadingComponent){
            return <PreloaderComponent></PreloaderComponent>
        }
        return(
            
            <div>
                {/* <Header as="h5">Crear referencia</Header> */}
                <Segment>
                    <Form>
                        <Form.Group widths="four">
                            <Form.Input onChange={this.handleChange} label="Modelo" value={model?model:''} name="model" placeholder="Cód modelo"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="Referencia" value={ref?ref:''} name="ref" placeholder="Referencia parte"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="Descripción" value={description?description:''} name="description" placeholder="Descripción"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="Costo" value={cost?cost:''} name="cost" type="number" placeholder="Costo"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="PVC" value={pvc?pvc:''} name="pvc" type="number" placeholder="PVC"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="PVP" value={pvp?pvp:''} name="pvp" type="number" placeholder="PVP"></Form.Input>
                        </Form.Group>

                        <Form.Group>
                            <Form.Input onChange={this.handleChange} label="Cantidad" value={amount?amount:''} name="amount" placeholder="Cantidad"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="Rutina" value={routine?routine:''} name="routine" placeholder="Rutina de MTO"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="MG PVC" value={mg_pvc?mg_pvc:''} name="mg_pvc" placeholder="MG PVC"></Form.Input>
                            <Form.Input onChange={this.handleChange} label="MG PVP" value={mg_pvp?mg_pvc:''} name="mg_pvp" placeholder="MG PVP"></Form.Input>
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Select onChange={this.handleChange} label="Motor" name="motor" value={motor?motor:''} options={motorOption} placeholder="Motor"></Form.Select>
                            <Form.Select onChange={this.handleChange} label="Aire Acondicionado" name="aa" value={aa?aa:''} options={aireOption} placeholder="Aire Acondicionado"></Form.Select>
                            <Form.Select onChange={this.handleChange} label="Caja" name="transmission" value={transmission?transmission:''} options={transmissionOption} placeholder="Tipo de caja"></Form.Select>
                            <Form.Input onChange={this.handleChange} type="number" label="Cilindrada (L)" name="displacement" value={displacement?displacement:''} placeholder="Cilindraje"></Form.Input>
                        </Form.Group>
                    </Form>
                    <Button 
                        onClick={ ()=>this.updateReference()}
                        content="GUARDAR" icon="save" 
                        size="tiny"
                        style={{backgroundColor:"#BB132B", color:"#f6f6f6", borderRadius:25}} />
                    <Button 
                        onClick={onClickCancel}
                        content="CANCELAR" icon="save" 
                        size="tiny"
                        style={{ borderRadius:25}}
                    /> 


                    
                </Segment>
                {this.renderModal(openModal, errorMessage)}
            </div>
        )
    }
}