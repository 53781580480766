import React, { Component, Fragment } from 'react';
import { InputFile } from 'semantic-ui-react-input-file';
import { Header, Button, Icon, List, Segment, Form, Input } from 'semantic-ui-react';
import { domain } from '../../../../constants';
import { uploadFile, upDateOrder } from '../../../../services/orders/orderService';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import { MessageToUser } from '../../../../actions';

class AttachSupport extends Component {
    constructor(props){
        super(props)
        this.state = { fileSelect:null, fileName:'', kiaOnTimeLink:'', indexRoutine:null }
    }

    componentDidMount=()=>{
        this.setState({ 
            kiaOnTimeLink:this.props.routineSelect.kiaOnTimeLink,
            indexRoutine: this.props.order.projection.indexOf(this.props.routineSelect)
         }) 
    }

    handleUpload = (e)=>{
        if(e.target.files && e.target.files[0]){
            this.setState({ fileSelect:e.target.files[0], fileName:e.target.files[0].name})
        }
    }

    handledChange = (e, {name, value})=>{
        this.setState({[name]:value}, ()=>{
            console.log(this.state)
        })
    }

    uploadFile =()=>{
        if(!this.state.fileSelect){
            return this.props.messageToUser(
                'Aún no se ha seleccionado un archivo', 
                'Por favor selecciona el acuerdo, puedes seleccionarlo haciendo click sobre el boton',
                true)
        }
        const { routineSelect } = this.props;
        const { order } = this.props;
        
        let temporalOrder = order;
        let indexRoutine = order.projection.indexOf(routineSelect);
        
        const formData = new FormData()
        formData.append('file', this.state.fileSelect);
        let param = {
            dealer:this.props.order.dealer,
            title: `Factura Redención ${this.props.routineSelect.routine}`
        }
        uploadFile(formData, param)
        .then( data =>{
            temporalOrder.projection[indexRoutine].attach.push({
                title:`Soporte Redención ${routineSelect.routine}-${order.form.plate}`,
                comments:`Soporte adjunto rendención de mantenimiento VIN: ${order.form.vin}`,
                url:`${domain}${order.dealer}/${data.filename}`,
                name:data.originalname,
                create_at:new Date(Date.now())
            })
            temporalOrder.projection[indexRoutine].kiaOnTimeLink = this.state.kiaOnTimeLink;
            this.setState({order:temporalOrder}, ()=>{ this.updateOrder()})
        }).catch(error =>{
            console.log(error)
        })
    }

    updateOrder=()=>{
        const { order } = this.state;
        upDateOrder( order )
        .then( data =>{
            this.MessageToast('info', 'Genial', 'Se actualizado la orden con éxito', 'database')
            // this.props.getDataOrder()
        }).catch(error =>{
            this.props.messageToUser('Los sentimos', 'Ocurrió un error al actualizar la orden', true)
            console.log(error)
        })
    }

    MessageToast = (type, title, message, icon) =>{
        toast({
            type: type,
            icon: icon,
            title: title,
            description: message,
            animation: 'bounce',
            time: 5000,
        })
    }

    renderAttach =()=> {
        
        const { attach } = this.props.routineSelect;
        const { fileName } = this.state;
        if(attach && attach.length>0){
            return <List>
                    {attach.map( (i)=>{
                        return <List.Item>
                            <List.Icon color='#BB162B' name='file pdf' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>{i.name}</List.Header>
                                <List.Description>{i.comments}</List.Description>
                            </List.Content>
                        </List.Item>
                    })}
            </List>
        } else {
            return <List>
                <List.Item>
                    <List.Icon color='#BB162B' name='attach' size='large' verticalAlign='middle' />
                    <List.Content>
                        <List.Header>No se ha cargado ningún soporte</List.Header>
                        <List.Description>
                            Puede cargar un soporte haciendo clic sobre <strong>Elige un archivo</strong>. Para finalizar la redención recuerde que debe incluir el link del 
                            <strong> Kia On Time.</strong>
                        </List.Description>
                    </List.Content>
                </List.Item>
            </List>
        }
        
    }

    saveKiaOnTimeLink = () => {
        const { routineSelect } = this.props;
        const { order } = this.props;
        const { kiaOnTimeLink, indexRoutine } = this.state;
        console.log("order")
        console.log(order)
        console.log("indexRoutine")
        console.log(indexRoutine)
        let temporalOrder = order;

        temporalOrder.projection[indexRoutine].kiaOnTimeLink = kiaOnTimeLink;
        this.setState({ order : temporalOrder }, ()=> this.updateOrder());
    }




    render(){
        const { fileName } = this.state;
        
        const { kiaOnTimeLink } = this.state;
        return (
            <Fragment>
                <Header style={{color:'#333333'}}>
                    Kia On Time Link
                    <Header.Subheader>
                        Copie el Link generado por Kia On time y péguelo en el siguiente campo, (El mismo link que se envía al cliente)
                    </Header.Subheader>
                </Header>
                    <Input onChange={this.handledChange} 
                        name="kiaOnTimeLink"
                        value={kiaOnTimeLink}
                        placeholder="https://"
                        action={{ 
                            onClick:()=>this.saveKiaOnTimeLink(), 
                            color:"red",
                            icon:'linkify' }} />
                <Header style={{color:'#333333'}}>
                    Factura
                    <Header.Subheader>
                        Cargue la factura generada del mantenimiento
                    </Header.Subheader>
                </Header>

                <InputFile
                    button={{label:fileName? fileName:'Elige un archivo' }}
                    input={{
                        id:'input-upload-id',
                        onChange:this.handleUpload
                    }}
                />
                {fileName?<Button onClick={()=> this.uploadFile()} style={{borderRadius:25, backgroundColor:'#BB162B', color:'#F6F6F6'}}>
                    <Icon name="save" color='#F6F6F6'/> Guardar
                </Button>:null}
                <Header style={{marginTop:40, color:'#333333'}}>
                    Documentos cargados
                </Header>
                {this.renderAttach()}
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser:(title, message, open)=>dispatch(MessageToUser(title, message, open))
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachSupport);