import { getApi } from '../../utils/Constants';

const api = getApi();
const apiModule = 'prepaidMant/redention/';
const token = JSON.stringify(localStorage.getItem('token'));

async function getActives(){
    return await fetch(api+apiModule+'getActives', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify({
            active:true
        })
    }).then( (variables) => variables.json() )
    .then( (response)=>{
        return response;
    }).catch( (error)=>{
        return error;
    } )
}

async function getToRedeem(search){
    return await fetch(api+apiModule+'getToRedeem', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify({
            search:search
        })
    }).then( (variables) => variables.json() )
    .then( (response)=>{
        return response;
    }).catch( (error)=>{
        return error;
    } )
}

export {
    getActives,
    getToRedeem
}