import { combineReducers } from 'redux';

import loginReducer from './loginReducer';
import getUserByEmailReducer from './getUserByEmailReducer';
import showComponentReducer from './showComponentReducer';
import modelTemplateReducer from './modelTemplateReducer';
import getGroupModelsReducer from './getGroupModelsReducer';
import messageToUserReducer from './messageToUserReducer';
import countModelTemplate from './getCountModelTemplateReducer';
import OrderReducer from './orderReducer';
import orderSelectId from './orderSelectId';

export default combineReducers({
    token: loginReducer,
    user: getUserByEmailReducer,

    showComponent: showComponentReducer,

    modelTemplate: modelTemplateReducer,
    modelTemplateCount: countModelTemplate,

    models: getGroupModelsReducer,
    
    message: messageToUserReducer,
    order: OrderReducer,
    orderSelectId: orderSelectId
})
