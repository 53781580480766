
import { API } from '../../constants';
const path = 'prepaidMant/modelTemplate/create'
const token = localStorage.getItem('token')

export default async ( body ) => {
    console.log('Desde API Create')
    console.log(body)
    return await fetch(API + path, {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            cl: body.cl, 
            cod: body.cod, 
            name: body.name, 
            vin: body.vin, 
            supply: body.supply, 
            mo: body.mo, 
            mto5: body.mto5, 
            mto10: body.mto10, 
            mto15: body.mto15, 
            mto20: body.mto20, 
            mto25: body.mto25, 
            mto30: body.mto30, 
            mto35: body.mto35, 
            mto40: body.mto40, 
            mto45: body.mto45, 
            mto50: body.mto50, 
            key: body.cl + body.cod,

        })
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        return responseJSON
    } ).catch( (error)=> {
        return error
    } )
}

async function update( body ) {
    console.log(body)
    return await fetch(API+'prepaidMant/modelTemplate/update', {
        method:'POST',
        headers:{
            'Accept':'applciation/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify( body )
    }).then( (response)=> response.json() )
    .then( (responseJSON)=> {
        return responseJSON
    } ).catch( (error)=> {
        return error
    } )

}

export {
    update
}

