import React, { Component, Fragment } from 'react';
import { Grid, Container, Image, List, Responsive } from 'semantic-ui-react';

class FooterComponent extends Component {
    render(){
        return (
            <Fragment>
                <Grid style={{backgroundColor:"#E4E5E6", marginTop:0, position:'relative'}}>
                    <Container>
                        <Grid.Column style={{padding:15}}>
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <List bulleted horizontal>
                                    <List.Item as='a' href="https://www.kia.com/co/main.html" style={styles.linkFooter}>KIA.COM.CO</List.Item>
                                    <List.Item as='a' href="https://www.kia.com/co/util/privacy.html" style={styles.linkFooter}>POLÍTICA DE PRIVACIDAD</List.Item>
                                    <List.Item as='a' href="https://www.kia.com/co/util/sitemap.html" style={styles.linkFooter}>MAPA DEL SITIO</List.Item>
                                    <List.Item as='a' href="https://www.kia.com/co/util/customer-center/contact-us.html" style={styles.linkFooter}>CONTÁCTENOS</List.Item>
                                </List>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <List bulleted>
                                    <List.Item as='a' href="https://www.kia.com/co/main.html" style={styles.linkFooter}>KIA.COM.CO</List.Item>
                                    <List.Item as='a' href="https://www.kia.com/co/util/privacy.html" style={styles.linkFooter}>POLÍTICA DE PRIVACIDAD</List.Item>
                                    <List.Item as='a' href="https://www.kia.com/co/util/sitemap.html" style={styles.linkFooter}>MAPA DEL SITIO</List.Item>
                                    
                                    <List.Item as='a' href="https://www.kia.com/co/util/customer-center/contact-us.html" style={styles.linkFooter}>CONTÁCTENOS</List.Item>
                                </List>
                            </Responsive>
                            <br></br>
                            <p style={{fontSize:10}}>TODOS LOS DERECHOS RESERVADOS &copy; METROKIA IMPORTADORA</p>
                        </Grid.Column>
                    </Container>
                </Grid>
                <Grid style={{backgroundColor:"#E4E5E6", marginTop:0}}>
                    <Grid.Column style={{backgroundColor:'#E4E5E6', height:56}}>
                        <Container fluid>
                            <Image style={{position:'absolute', right:0, top:5, widht:150}} src={require('../assets/lg_footer_kia.png')}></Image>
                        </Container>
                    </Grid.Column>
                </Grid>
            </Fragment>
        )
    }
}

const styles = {
    linkFooter:{
        color:'#666666', 
        fontWeight:'bold', 
        fontFamily:'kiaBold'
    }
}

export default FooterComponent