import React, { Component, Fragment } from 'react'
import { Accordion, Icon, Header, Form, Segment, Button, Container } from 'semantic-ui-react';

const initialQuestions = [
    {
        title:'¿Dónde puedo redimir mi Mantenimiento Prepagado?',
        content: <p>
                    Puede redimir las rutinas de mantenimiento prepagado en cualquier concesionario de la Red Kia autorizado por
                    Kia Motors Colombia.
                <br/>
                    Consulte cual es su concesionario más cercano <a style={{ color:'#bb162b'}} href='https://www.kia.com/co/service/service-care/location.html' target='_blank'>
                    <strong>RED CONCESIONARIOS</strong> 
                    </a>
                </p>
    },
    {
        title:'¿Que hago si tengo una novedad?',
        content: <p>
                    Puede reportar novedades al concesionario donde adquirió el Plan de Mantenimiento Prepagado,
                    en caso de no recibir o no ser atendida la solicitud puede acudir a nuestro departamento de 
                    Servicio Posventa Importadora escribiendo un correo a <a style={{color:'#bb162b'}} href = "mailto: servicioalclienteimportadora@kia.com.co">
                    servicioalclienteimportadora@kia.com.co
                    </a>
                </p>
    },
    {
        title:'¿Cuando puedo redimir mis mantenimientos?',
        content: <p>
                    Las rutinas de Mantenimiento deben realizarse dentro de los parámetros establecidos en el 
                    <strong> Manual de Garantías</strong> esto es de cada 6 meses o 5.000 km. Lo primero que 
                    ocurra. <br/>
                    Tenga en cuenta que los límites de kilometraje para realizar una rutina están entre +/- 500
                    km. Por ejemplo la rutina de 10.000 km debe hacerse entre 9.500 y 10.500 kilómetros. 
                </p>
    },
    {
        title:'¿Puedo ceder mi paquete?',
        content:<p>
            El paquete de Mantenimiento Prepagado está asociado al VIN del vehículo, Si se presenta cambio de propietario del vehículo
            usted podrá ceder el saldo vigente a la fecha en favor del tercero. 
        </p>
    }
]
class FrequenQuestionsComponent extends Component {
    state = { 
        activeIndex: null, 
        text:'',
        questions: initialQuestions
    }

    handleChange = (e, { name, value })=>{
        this.setState({[name]: value})
    }
    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    renderAccordion = () => {
        const { questions, activeIndex } = this.state

        return <Accordion>
            {questions.map( (i, index) => {
                return <Fragment>
                            <Accordion.Title
                                active={activeIndex === index}
                                index={index}
                                onClick={this.handleClick}>

                                <Icon name='dropdown' />
                                {i.title}

                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                                {i.content}
                            </Accordion.Content>
                        </Fragment>
            } )}
        </Accordion>
    }

    render(){
        const { activeIndex, text } = this.state;
        console.log(this.props.data)
        return(
            <Container style={{ marginTop:'8vh', minHeight:'55vh' }}>
                <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                    PREGUNTAS FRECUENTES
                </Header>
                <br/>
                {this.renderAccordion()}
                {/* <Accordion style={{ fonSize:'1.5em'}}>
                    <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleClick}
                    style={{ fonSize:'1.5em'}}
                    >
                    <Icon name='dropdown' />
                    ¿Dónde puedo redimir mi Mantenimiento Prepagado?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <Container>
                            <p>
                                Puede redimir las rutinas de mantenimiento prepagado en cualquier concesionario de la Red Kia autorizado por
                                Kia Motors Colombia.
                            </p>
                            <p>
                                Consulte cual es su concesionario más cercano <a style={{ color:'#bb162b'}} href='https://www.kia.com/co/service/service-care/location.html' target='_blank'>
                                   <strong>RED CONCESIONARIOS</strong> 
                                </a>
                            </p>
                        </Container>
                    </Accordion.Content>

                    <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={this.handleClick}
                    >
                    <Icon name='dropdown' />
                    ¿Que hago si tengo una novedad?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                    <p>
                        Puede reportar novedades al concesionario donde adquirió el Plan de Mantenimiento Prepagado,
                        en caso de no recibir o no ser atendida la solicitud puede acudir a nuestro departamento de 
                        Servicio Posventa Importadora escribiendo un correo a <a style={{color:'#bb162b'}} href = "mailto: servicioalclienteimportadora@kia.com.co">
                        servicioalclienteimportadora@kia.com.co
                        </a>
                    </p>
                    </Accordion.Content>

                    <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={this.handleClick}
                    >
                    <Icon name='dropdown' />
                    ¿Cuando puedo redimir mis mantenimientos?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                    <p>
                        Las rutinas de Mantenimiento deben realizarse dentro de los parámetros establecidos en el 
                        <strong>Manual de Garantías</strong> esto es de cada 6 meses o 5.000 km. Lo primero que 
                        ocurra. <br/>
                        Tenga en cuenta que los límites de kilometraje para realizar una rutina están entre +/- 500
                        km. Por ejemplo la rutina de 10.000 km debe hacerse entre 9.500 y 10.500 kilómetros. 
                    </p>
                    </Accordion.Content>
                </Accordion> */}
                
                {/* <Header as="h2">
                    <Icon name="pencil alternate" />
                    Algo no va bien
                </Header>
                <Segment>
                    
                    <Form>
                        <Form.TextArea onChange={this.handleChange} name="text" value={text} label="Algo no va bien" placeholder="Escribemos tus dudas, o cuando algo no vaya bien" />
                    </Form>
                    <br></br>
                    <Button content="ENVIAR" icon="send" style={{ backgroundColor:'#BB162B', color:"#f6f6f6", borderRadius:25 }} size='tiny' />
                </Segment> */}
            </Container>
        )
    }
}

export default FrequenQuestionsComponent