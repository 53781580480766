import { getApi } from '../../utils/Constants';

const api = getApi();
const apiModule = 'prepaidMant/order/';
const token = JSON.stringify(localStorage.getItem('token'));

async function create( body ){
    return await fetch(api+apiModule+'create',{
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+ token
        },
        body: JSON.stringify(body)
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function getOrders( body ){
    return await fetch(api+apiModule+'getOrders', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            dealer:body.dealer,
            dateTo:body.dateTo, 
            dateFrom:body.dateFrom, 
            vin: body.vin, 
            ni: body.ni
        })
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function getOrderById( body ){
    
    return await fetch(api+apiModule+'getOrderById', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            id: body.id
        })
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function uploadFile( body, params ){
    
    return await fetch(api+apiModule+`uploadFile?dealer=${params.dealer}&title=${params.title}`, {
        method:'POST',
        body:body
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function upDateOrder( order ){
    return await fetch(api+apiModule+'upDateOrder', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body:JSON.stringify(order)
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

async function searchOrderByVin( body ){
    return await fetch(api+apiModule+'searchOrderByVin', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            vin:body.vin
        })
    }).then( (res)=> res.json() )
    .then( (response)=>{
        return response;
    } ).catch( (error)=>{
        return error;
    } )
}

export {
    create,
    getOrders,
    getOrderById,
    uploadFile,
    upDateOrder,
    searchOrderByVin
}