import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import EditReferenceComponent from './EditReferenceComponent';

export default class ItemReferenceComponent extends Component {
    render(){
        const { 
            model, 
            reference, 
            description, 
            amount,
            routine,
            cost, 
            pvc, 
            pvp, 
            mg_pvc, 
            mg_pvp, 
            createup, 
            active, 
            motor, 
            aa, 
            transmission, 
            displacement,
            onClickTrash,
            onClickEdit,
            edit,
            onClickSave,
            onClickCancel,
            id
            } = this.props;
            
        if(edit){
            return (
                <Table.Row>
                    <Table.Cell colSpan="17">
                        <EditReferenceComponent
                            id={id}
                            model={model} 
                            reference={reference}
                            description={description}
                            amount={amount}
                            routine={routine}
                            cost={cost}
                            pvc={pvc}
                            pvp={pvp}
                            mg_pvc={mg_pvc}
                            mg_pvp={mg_pvp}
                            motor={motor}
                            aa={aa}
                            transmission={transmission}
                            displacement={displacement}
                            onClickEdit={onClickEdit}
                            onClickSave={onClickSave}
                            onClickCancel={onClickCancel}
                            updateReferences={()=>this.props.updateReferences()}></EditReferenceComponent>
                    </Table.Cell>
                </Table.Row>
                
            )
        }
        return(
            
            <Table.Row>
                <Table.Cell>{model}</Table.Cell>
                <Table.Cell>{reference}</Table.Cell>
                <Table.Cell>{description}</Table.Cell>
                <Table.Cell>{amount}</Table.Cell>
                <Table.Cell>{routine}</Table.Cell>
                <Table.Cell>{cost}</Table.Cell>
                <Table.Cell>{pvc}</Table.Cell>
                <Table.Cell>{pvp}</Table.Cell>
                <Table.Cell>{mg_pvc}</Table.Cell>
                <Table.Cell>{mg_pvp}</Table.Cell>
                <Table.Cell>{createup}</Table.Cell>
                <Table.Cell>{active?'activo':'Inactivo'}</Table.Cell>
                <Table.Cell>{motor}</Table.Cell>
                <Table.Cell>{aa}</Table.Cell>
                <Table.Cell>{transmission}</Table.Cell>
                <Table.Cell>{displacement}</Table.Cell>
                <Table.Cell>
                    <Button onClick={onClickEdit} size='tiny' icon="edit" />
                    <Button onClick={onClickTrash} size='tiny' icon="trash" />
                </Table.Cell>
            </Table.Row>
            
        )
    }
}