import React, { Component, Fragment } from 'react';
import { Grid, Container, Header, Button, Icon, Modal, Form, Table, Progress, Message, Checkbox } from 'semantic-ui-react';
import { packOptions } from '../../../../utils/Constants';

import { create, read, update } from '../../../../services/commercialActions/';
import moment from 'moment';
import { connect } from 'react-redux';
import { MessageToUser } from '../../../../actions';

class CommercialActionsScreen extends Component {

    state = {
        actionModalOpen:false,
        loading:true,
        actions:[],

        actionModalUse:'',

        _id:'',
        initial:'',
        end:'',
        article:'',
        pack:'Seleccione un Paquete',
        line:'',
        descriptions:'',
        active:''
    }

    componentDidMount(){
        this.readActions()
    }
    
    hanledChange = ( e, {name, value}) => {
        this.setState({[name]: value})
    }

    createAction = () => {
        const { initial, end, article, pack, line, descriptions } = this.state;
        let body = {
            
            initial: initial,
            end: end,
            article: article,
            package: pack,
            line: line,
            descriptions: descriptions
        }
        create( body )
        .then( response => {
            this.props.messageToUser('Mensaje', response.msg, true);
            this.setState({actionModalOpen:false})
            this.readActions()
        } )
    }

    readActions = () => {
        read()
        .then( actions =>{
            this.setState({
                actions:actions,
                loading:false
            })
            
        } )
    }

    updateAction = () => {
        const { initial, end, article, pack, line, descriptions, _id, active } = this.state;
        let body = {
            initial: initial,
            end: end,
            article: article,
            package: pack,
            line: line,
            descriptions: descriptions,
            id: _id,
            active: active
        }
        console.log(body)
        update(body)
        .then( result =>{
            this.props.messageToUser('Mensaje', result.msg, true);
            this.setState({actionModalOpen:false})
            this.readActions();
        } )
    }

    createItem = () => {
        this.setState({
            initial:'',
            end:'',
            article:'',
            pack:'Seleccione un Paquete',
            line:'',
            descriptions:'',
            active:'',
            actionModalOpen:true, 
            actionModalUse:'create'
        })
    }

    selectItem = (item) =>{
        
        this.setState({
            initial: moment(item.initial).format('YYYY-MM-DD'),
            end: moment(item.end).format('YYYY-MM-DD'),
            article: item.article,
            pack: item.package,
            line: item.line,
            descriptions: item.descriptions,
            _id: item._id,
            active: item.active,
            actionModalOpen:true,
            actionModalUse:'edit',
        }) 
    }

    renderActions = () => {
        const { actions } = this.state;
        if(!(actions && actions.length>0)) return null
        return actions.map( (i, index)=> {
            return <Table.Row>
                <Table.Cell>{moment(i.initial).format('DD-MM-YYYY')}</Table.Cell>
                <Table.Cell>{moment(i.end).format('DD-MM-YYYY')}</Table.Cell>
                <Table.Cell>
                    {/* <span>T Actual: {moment(i.end).diff(moment().format(), 'days')}</span>
                    <span>T Total{moment(i.end).diff(i.initial, 'days')}</span> */}
                    
                    <Progress 
                        color='red'
                        percent={moment(i.end).diff(moment().format(), 'days')} 
                        total={moment(i.end).diff(i.initial, 'days')} 
                        size='small'
                        progress />
                </Table.Cell>
                <Table.Cell>{i.line}</Table.Cell>
                <Table.Cell>{i.article}</Table.Cell>
                <Table.Cell>{i.package}</Table.Cell>
                <Table.Cell>{i.descriptions}</Table.Cell>
                <Table.Cell>
                    <Button
                        style={{ borderRadius:25 }}
                        icon="cog"
                        onClick={()=> this.selectItem(i)} 
                    />
                </Table.Cell>
            </Table.Row>
        } )
    }

    actionModal = () => {
        const { actionModalOpen, actionModalUse, initial, end, article, pack, line, descriptions, active } = this.state
        return <Modal open={actionModalOpen} >
            <Modal.Header>
                <Icon name="currency" /> ACCIÓN COMERCIAL
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.hanledChange} type="date" name="initial" value={initial} label='Fecha Inicio' />
                        <Form.Input onChange={this.hanledChange} type="date" name="end" value={end} label='Fecha Final' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input onChange={this.hanledChange} type="text" name="article" value={article} label="Artículo / Versión" />
                        <Form.Input onChange={this.hanledChange} type="text" name="line" value={line} label="Linea / Modelo" />
                        <Form.Select onChange={this.hanledChange} options={packOptions()} type="text" name="pack" value={pack} label="Paquete" />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.TextArea onChange={this.hanledChange} name="descriptions" value={descriptions} label="Descripción" />
                    </Form.Group>
                    {/* {actionModalUse=='edit'?<Form.Group>
                        <Button 
                            onClick={()=>this.setState({active: !this.state.active})} 
                            content="ACTIVAR" 
                            icon="power off" 
                            label={active?'ACCION ACTIVA':'ACCION INACTIVA'} />
                    </Form.Group>:null} */}
                    <Message>
                        <Message.Header>Importante</Message.Header>
                        <Message.Content>
                            Esta acción comercial de <strong>Mantenimiento <span style={{color:'#BB162B', fontWeight:'bold'}}>K</span> Plus</strong> de {pack}k estará activa desde el {initial} hasta el {end}, sólo aplicará para los vehículos que
                            esten asociados a la versión de equipamiento: {article} de la línea {line}. <br/><br/>

                            Si desea desactivar esta Acción comercial puede hacerlo, al desactivarla no se aplicará los beneficios al momento de crear el paquete. <br/>
                            <p>
                                <Checkbox 
                                    onChange={()=>this.setState({active: !this.state.active})} 
                                    toggle 
                                    checked={active}
                                    label={active?'ACCION ACTIVA':'ACCION INACTIVA'} />
                            </p>
                            
                        </Message.Content>
                    </Message>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="CERRAR"
                    icon="times"
                    style={{ borderRadius:25}} 
                    size="mini"
                    onClick={()=>this.setState({actionModalOpen:false})}
                />
                {actionModalUse === 'create'? 
                <Button 
                    content="GUARDAR"
                    icon="save"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                    size="mini"
                    onClick={()=> this.createAction()}
                />:null}
                {actionModalUse === 'edit'?
                <Button 
                    content="ACTUALIZAR"
                    icon="save"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                    size="mini"
                    onClick={()=> this.updateAction()}
                />:null}
            </Modal.Actions>
        </Modal>
    }



    render(){
        return <Fragment>
            <Grid style={{ minHeight:'75vh', marginTop:'8vh', marginBottom:30 }}>
                <Container>
                    <Header as="h1" className="con_tit" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20}}>
                        ACCIONES COMERCIALES
                        <span>
                            {/* <Button onClick={()=>this.setState({openModalFilter:true })} size="mini" style={{borderRadius:25}} floated='right' icon="search" content="BUSCAR" /> */}
                            <Button 
                                content="AGREGAR"
                                icon="add"
                                onClick={()=> this.createItem() } 
                                size="mini" 
                                style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}} 
                                floated='right'/>
                        </span>
                    </Header>

                    <Table selectable size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Inicio</Table.HeaderCell>
                                <Table.HeaderCell>Final</Table.HeaderCell>
                                <Table.HeaderCell>Días restantes</Table.HeaderCell>
                                <Table.HeaderCell>Linea</Table.HeaderCell>
                                <Table.HeaderCell>Versión</Table.HeaderCell>
                                <Table.HeaderCell>Paquete</Table.HeaderCell>
                                <Table.HeaderCell>Descripción</Table.HeaderCell>
                                <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderActions()}
                        </Table.Body>
                    </Table>
                    
                </Container>

            </Grid>
            {this.actionModal()}
        </Fragment>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, message, open)=> dispatch(MessageToUser(title, message, open))
    }
}

export default connect(null, mapDispatchToProps)(CommercialActionsScreen)