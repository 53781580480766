import React, { Component } from 'react';
import { Grid, Container, Segment, Icon, Table, Label, Button, Form, Header } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { getOrders } from '../../../services/orders/orderService';
import moment from 'moment';
import Preloader from '../../../components/PreloaderComponent';
import "react-datepicker/dist/react-datepicker.css";
import { MessageToUser, showComponent, SelectOrder } from '../../../actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const CurrencyFormat = require('react-currency-format')

class OrderScreen extends Component {
    constructor(props){
        super(props)
        this.state = { 
            orders:[], 
            loading:true,
            dateTo: new Date(),
            dateFrom: new Date(moment().subtract(1, 'month').format()),
            vin:'',
            ni:'',
            dealer:[]
        }
    }
    componentDidMount(){
        this.setState({dealer:this.props.user.dealer})
        const { dateTo, dateFrom, vin, ni } = this.state;
        let dealer = this.props.user.dealer
        let body = {dateTo, dateFrom, vin, ni, dealer}
        getOrders(body)
        .then( (data)=>{
            this.setState({orders:data, loading:false})
        } ).catch( (error)=>{
            this.props.messageToUser('Lo sentimos', error.message, true)
        })
    }

    getOrders = () => {
        const { dateTo, dateFrom, vin, ni, dealer } = this.state;
        let body = { dateTo, dateFrom, vin, ni, dealer };
        getOrders(body)
        .then( (data)=>{
            this.setState({orders:data, loading:false})
        } ).catch( (error)=>{
            this.props.messageToUser('Lo sentimos', error.message, true)
        })
    }

    selectDealer=()=>{
        const { dealer } = this.props.user
        this.setState({dealer:dealer})
    }

    handleChangeState = (e, { name, value})=>{
        this.setState({[name]: value})
    }
    handleChangeDateTo = (date)=>{
        this.setState({dateTo:date});
    }
    handleChangeDateFrom = (date)=>{
        this.setState({dateFrom:date});
    }

    renderOrders=()=>{
        const { orders } = this.state;
        
        if((orders && orders.length>0)){
            return orders.map( (i)=>{
                let status = Object.assign([], i.status)
                return (
                    <Table.Row key={i._id}>
                        <Table.Cell>
                            {moment(i.create).format('DD-MM-YYYY')}
                        </Table.Cell>
                        <Table.Cell>{i.number}</Table.Cell>
                        <Table.Cell>{i.dealer}</Table.Cell>
                        <Table.Cell>{i.vin}</Table.Cell>
                        <Table.Cell>{i.plan}</Table.Cell>
                        <Table.Cell>
                            <CurrencyFormat value={i.planValue} thousandSeparator={true} prefix="$" displayType={'text'} decimalScale={0}></CurrencyFormat>
                        </Table.Cell>
                        <Table.Cell>{i.model}</Table.Cell>
                        <Table.Cell>{i.ni}</Table.Cell>
                        <Table.Cell>
                            <Label>
                                {
                                    status.reverse().find( (item)=>{
                                        return item.active === true
                                    } ).status
                                }
                            </Label>
                        </Table.Cell>
                        <Table.Cell>
                            <Button 
                                to={`order/${i._id}`} 
                                as={Link} 
                                content="GESTION" 
                                icon="cog" 
                                size="tiny" 
                                style={{color:'#f6f6f6', backgroundColor:'#BB162B', borderRadius:25}}/>
                        </Table.Cell>
                    </Table.Row>
                )
            } )
        }
    }

    noPackageAvailable = () => {
        const { orders, dateTo, dateFrom } = this.state;
        if((orders && orders.length>0)) return null
        return <Segment placeholder>
                    <Header icon>
                        <Icon name='box' />
                        No se encontraron paquetes disponibles
                    </Header>
                    <Segment.Inline  >
                        Al parecer no hay paquetes disponibles, puede probar cambiando los filtros.
                        La búsqueda de paquetes entre {moment(dateTo).format('DD-MM-YYYY')} hasta {moment(dateFrom).format('DD-MM-YYYY')} no arrojó resultados
                    </Segment.Inline>
                </Segment>
    }
    render(){
        const {loading } = this.state;

        if(loading){
            return <Preloader/>
        }
        return (
        <Grid style={{marginTop:30, marginBottom:30, minHeight:'70vh'}}>
           <Container>
                <Segment>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Desde</label>
                                <DatePicker
                                    name="dateFrom"
                                    selected={this.state.dateFrom}
                                    onChange={this.handleChangeDateFrom}>
                                </DatePicker>
                            </Form.Field>
                            <Form.Field>
                                <label>Hasta</label>
                                <DatePicker
                                    name="dateTo"
                                    selected={this.state.dateTo}
                                    onChange={this.handleChangeDateTo}>
                                </DatePicker>
                            </Form.Field>
                            <Form.Input onChange={this.handleChangeState} name="ni" label="Id Cliente" placeholder="Número Identidad cliente"></Form.Input>
                            <Form.Input onChange={this.handleChangeState} name="vin" label="VIN" placeholder="Número Identificación Vehículo"></Form.Input>
                            <Form.Button 
                                label="Buscar Pedidos" 
                                onClick={()=> this.getOrders()} 
                                icon="search" 
                                size='tiny'
                                style={{ backgroundColor:'#bb162b', color:'#f6f6f6', borderRadius:25 }}
                                content='FILTRAR' />
                        </Form.Group>
                    </Form>
                    
                </Segment>
                <Table selectable size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Fecha</Table.HeaderCell>
                            <Table.HeaderCell>Cód</Table.HeaderCell>
                            <Table.HeaderCell>Distribuidor</Table.HeaderCell>
                            <Table.HeaderCell>VIN</Table.HeaderCell>
                            <Table.HeaderCell>Plan K</Table.HeaderCell>
                            <Table.HeaderCell>Valor</Table.HeaderCell>
                            <Table.HeaderCell>Modelo</Table.HeaderCell>
                            <Table.HeaderCell>Id Cliente</Table.HeaderCell>
                            <Table.HeaderCell>Estatus</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderOrders()}
                        
                    </Table.Body>
                </Table>
                {this.noPackageAvailable()}
           </Container>
        </Grid>)
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        messageToUser: (title, message, open)=>dispatch(MessageToUser(title, message, open)),
        showComponent: (component)=>dispatch(showComponent(component)),
        selectOrder: (id)=>dispatch(SelectOrder(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderScreen)