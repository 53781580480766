import { getApi } from '../../utils/Constants';
const api = getApi();
const path = '/prepaidMant/complementaryServices/'

const token = localStorage.getItem('token');

async function create( body ){
    return await fetch( api+path+'create', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer '+token
        },
        body: JSON.stringify({
            service: body.service,
            frequency: body.frequency,
            amount: body.amount,
            terms: body.terms,
            active: true,
            group: body.group
        })
    }).then( (result)=> result.json() )
    .then( (success)=>{
        return success
    } ).catch( (error)=>{
        return error
    })
}

async function read( body ){
    return await fetch( api+path+'read', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            group: body.group
        })
    } ).then( (result)=> result.json() )
    .then( (success)=>{
        return success
    } ).catch( (error)=>{
        return error
    })
}

async function update ( body ){
    return await fetch( api+path+'update', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            id: body.id,
            service: body.service,
            frequency: body.frequency,
            amount: body.amount,
            terms: body.terms,
            active: body.active,

        })
    }).then( (result)=> result.json() )
    .then( (success)=>{
        return success
    } ).catch( (error)=>{
        return error
    })
}

export {
    create,
    read,
    update
}

 // create: new Date( Date.now() ),
    // service: req.body.service,
    // frequency: req.body.frequency,
    // amount: req.body.amount,
    // terms: req.body.terms,
    // active: req.body.active,
    // dealer: req.body.dealer