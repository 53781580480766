import React from 'react';
import './App.css';

import { Provider } from 'react-redux';
import configureStore from './configureStore';

import MainScreen from './screens/MainScreen';

let store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <MainScreen></MainScreen>
    </Provider>
  );
}

export default App;
