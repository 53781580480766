import { getApi } from '../../utils/Constants';
const api = getApi();
const path = '/prepaidMant/commercialactions/'

const token = localStorage.getItem('token');

async function create( body ){
    return await fetch(api+path+'create', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            
            initial: body.initial,
            end: body.end,
            article: body.article,
            package: body.package,
            line: body.line,
            descriptions: body.descriptions
        })
    }).then( response => response.json() )
    .then( result =>{
        return result;
    } ).catch( error =>{
        return error
    } )
}

async function read(){
    return await fetch(api+path+'read', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        }
    }).then( response => response.json() )
    .then( result =>{
        return result;
    } ).catch( error =>{
        return error
    } )
}

async function update( body ){
    return await fetch(api+path+'update', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify({
            active: body.active,
            initial: body.initial,
            end: body.end,
            article: body.article,
            package: body.package,
            line: body.line,
            descriptions: body.descriptions,
            id: body.id
        })
    }).then( response => response.json() )
    .then( result =>{
        return result;
    } ).catch( error =>{
        return error
    } )
}



async function createPackage( body ){
    return await fetch(api+path+'createPackage', {
        method:'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization':'Bearer ' + token
        },
        body: JSON.stringify(body)
    }).then( response => response.json() )
    .then( result =>{
        return result;
    } ).catch( error =>{
        return error
    } )
}

export {
    create,
    createPackage,
    read,
    update
}

// created: { type:Date, default:new Date( Date.now() ) },
// init:{ type:Date, default:new Date( Date.now() ) },
// end:{ type:Date, default:new Date( Date.now() ) },
// article:{ type:String },
// package:{ type:Number },
// line:{ type:String, maxlength:2, minlength:2 },
// descriptions:{ type:String }